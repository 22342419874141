import initMemorialSearchByUrl from "../../components/MemorialSearch";
import initGoogleAutocomplete from "../../components/GoogleAutocomplete";

export default function initRelationshipForms(props) {
  const addPredeceasedLovedOneButton = document.querySelector(
    ".add-predeceased-button",
  );
  const addPredeceasedLovedOneForm = document.querySelector(".add-predeceased-form");
  const addFollowingLovedOneButton = document.querySelector(
    ".add-following-in-death-button",
  );
  const addFollowingLovedOneForm = document.querySelector(
    ".add-following-in-death-form",
  );
  const addSurvivingLovedOneButton = document.querySelector(".add-survivor-button");
  const addSurvivingLovedOneForm = document.querySelector(".add-survivor-form");

  if (addPredeceasedLovedOneButton && addPredeceasedLovedOneForm) {
    addPredeceasedLovedOneButton.addEventListener("click", () => {
      addPredeceasedLovedOneButton.classList.add("d-none");
      addPredeceasedLovedOneForm.classList.remove("d-none");

      if (addFollowingLovedOneForm) {
        addFollowingLovedOneForm.classList.add("d-none");
      }

      if (addSurvivingLovedOneForm) {
        addSurvivingLovedOneForm.classList.add("d-none");
      }

      addPredeceasedLovedOneForm.querySelectorAll("input,select").forEach((field) => {
        field.disabled = false;
      });

      if (addFollowingLovedOneForm) {
        addFollowingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      }

      if (addSurvivingLovedOneForm) {
        addSurvivingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      }
    });

    addPredeceasedLovedOneForm
      .querySelector(".close-button")
      .addEventListener("click", () => {
        addPredeceasedLovedOneButton.classList.remove("d-none");
        addPredeceasedLovedOneForm.classList.add("d-none");
        addPredeceasedLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      });

    const predeceasedLocationContainer = document.querySelector(
      ".predeceased-location-container",
    );
    if (predeceasedLocationContainer) {
      initGoogleAutocomplete({
        element: predeceasedLocationContainer.querySelector("input"),
        options: {
          types: ["(cities)"],
        },
      });
    }
  }

  if (addFollowingLovedOneButton && addFollowingLovedOneForm) {
    addFollowingLovedOneButton.addEventListener("click", () => {
      addFollowingLovedOneButton.classList.add("d-none");
      addFollowingLovedOneForm.classList.remove("d-none");

      if (addPredeceasedLovedOneForm) {
        addPredeceasedLovedOneForm.classList.add("d-none");
      }

      if (addSurvivingLovedOneForm) {
        addSurvivingLovedOneForm.classList.add("d-none");
      }

      addFollowingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
        field.disabled = false;
      });

      if (addPredeceasedLovedOneForm) {
        addPredeceasedLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      }

      if (addSurvivingLovedOneForm) {
        addSurvivingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      }
    });

    addFollowingLovedOneForm
      .querySelector(".close-button")
      .addEventListener("click", () => {
        addFollowingLovedOneButton.classList.remove("d-none");
        addFollowingLovedOneForm.classList.add("d-none");
        addFollowingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      });
  }

  if (addSurvivingLovedOneButton && addSurvivingLovedOneForm) {
    addSurvivingLovedOneButton.addEventListener("click", () => {
      addSurvivingLovedOneButton.classList.add("d-none");
      addSurvivingLovedOneForm.classList.remove("d-none");

      if (addFollowingLovedOneForm) {
        addFollowingLovedOneForm.classList.add("d-none");
      }

      if (addPredeceasedLovedOneForm) {
        addPredeceasedLovedOneForm.classList.add("d-none");
      }

      addSurvivingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
        field.disabled = false;
      });

      if (addFollowingLovedOneForm) {
        addFollowingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      }

      if (addPredeceasedLovedOneForm) {
        addPredeceasedLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      }
    });

    addSurvivingLovedOneForm
      .querySelector(".close-button")
      .addEventListener("click", () => {
        addSurvivingLovedOneButton.classList.remove("d-none");
        addSurvivingLovedOneForm.classList.add("d-none");
        addSurvivingLovedOneForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = "disabled";
        });
      });
  }

  const survivorRelationshipTypeSelect = document.getElementById(
    props.survivorRelationshipTypeFieldId,
  );
  const survivorRelationshipTypeStr = document.querySelector(
    ".survivor-relationship-other",
  );

  if (survivorRelationshipTypeSelect && survivorRelationshipTypeStr) {
    survivorRelationshipTypeSelect.addEventListener("change", (evt) => {
      const index = evt.target.selectedIndex;
      const selected = evt.target.options[index];
      if (selected.value === "other") {
        survivorRelationshipTypeStr.classList.remove("d-none");
      } else {
        survivorRelationshipTypeStr.querySelector("input").value = "";
        survivorRelationshipTypeStr.classList.add("d-none");
      }
    });
  }

  const inviteManager = document.querySelector(
    '.survivor-invite-manager input[type="checkbox"]',
  );
  if (inviteManager) {
    inviteManager.addEventListener("change", () => {
      const disclaimer = document.querySelector(".survivor-invite-manager-disclaimer");
      disclaimer.classList.toggle("d-none", !inviteManager.checked);
      const optionalSpan = document.querySelector(".survivor-email-optional");
      optionalSpan.classList.toggle("d-none", inviteManager.checked);
    });
  }

  const predeceasedRelationshipTypeSelect = document.getElementById(
    props.predeceasedRelationshipTypeFieldId,
  );
  const predeceasedRelationshipTypeStr = document.querySelector(
    ".predeceased-relationship-other",
  );

  if (predeceasedRelationshipTypeSelect && predeceasedRelationshipTypeStr) {
    predeceasedRelationshipTypeSelect.addEventListener("change", (evt) => {
      const index = evt.target.selectedIndex;
      const selected = evt.target.options[index];
      if (selected.value === "other") {
        predeceasedRelationshipTypeStr.classList.remove("d-none");
      } else {
        predeceasedRelationshipTypeStr.querySelector("input").value = "";
        predeceasedRelationshipTypeStr.classList.add("d-none");
      }
    });
  }

  if (props.followerFormProps) {
    const followingRelationshipTypeSelect = document.getElementById(
      props.followerFormProps.deceasedRelationshipTypeFieldId,
    );
    const followingRelationshipTypeStr = document.querySelector(
      ".following-relationship-other",
    );

    if (followingRelationshipTypeSelect && followingRelationshipTypeStr) {
      followingRelationshipTypeSelect.addEventListener("change", (evt) => {
        const index = evt.target.selectedIndex;
        const selected = evt.target.options[index];
        if (selected.value === "other") {
          followingRelationshipTypeStr.classList.remove("d-none");
        } else {
          followingRelationshipTypeStr.querySelector("input").value = "";
          followingRelationshipTypeStr.classList.add("d-none");
        }
      });
    }
  }

  if (!props.isOnboarding) {
    initMemorialSearchByUrl({
      baseSearchUrl: props.memorialSearchUrl,
      container: addPredeceasedLovedOneForm,
      excludeSlugs: props.relatedMemorialSlugs,
      slugFieldId: props.predeceasedMemorialSlugFieldId,
      firstNameFieldId: props.predeceasedMemorialDeceasedFirstNameFieldId,
      lastNameFieldId: props.predeceasedMemorialDeceasedLastNameFieldId,
    });
  }

  // Initialize relationship edit forms.

  const personEditForms = document.querySelectorAll(".person-edit-form");
  if (personEditForms) {
    personEditForms.forEach((personEditFormContainer) => {
      const relationshipTypeSelect = personEditFormContainer.querySelector("select");
      const relationshipTypeStr = personEditFormContainer.querySelector(
        ".edit-relationship-other",
      );

      relationshipTypeSelect.addEventListener("change", (evt) => {
        const index = evt.target.selectedIndex;
        const selected = evt.target.options[index];
        if (selected.value === "other") {
          relationshipTypeStr.classList.remove("d-none");
          relationshipTypeStr
            .querySelector("input")
            .setAttribute("required", "required");
        } else {
          relationshipTypeStr.querySelector("input").value = "";
          relationshipTypeStr.classList.add("d-none");
          relationshipTypeStr.querySelector("input").removeAttribute("required");
        }
      });

      const listGroupItem = personEditFormContainer.closest(".list-group-item");
      const editForm = listGroupItem.querySelector(".person-edit-form");
      const listItemContent = listGroupItem.querySelector(".list-item-content");
      personEditFormContainer
        .querySelector(".cancel-edit-person")
        .addEventListener("click", () => {
          editForm.classList.add("d-none");
          listItemContent.classList.remove("d-none");

          editForm.querySelectorAll("input,select").forEach((field) => {
            field.disabled = "disabled";
          });
        });

      editForm.querySelectorAll("input").forEach((input) => {
        input.addEventListener("keydown", (evt) => {
          if (evt.keyCode === 13) {
            evt.preventDefault();
            editForm.querySelector("button").click();
          }
        });
      });
    });
  }

  const personEditLinks = document.querySelectorAll(".edit-person-link");
  if (personEditLinks) {
    personEditLinks.forEach((editLink) => {
      const listGroupItem = editLink.closest(".list-group-item");
      const editForm = listGroupItem.querySelector(".person-edit-form");
      const listItemContent = listGroupItem.querySelector(".list-item-content");
      editLink.addEventListener("click", () => {
        editForm.classList.remove("d-none");
        listItemContent.classList.add("d-none");

        editForm.querySelectorAll("input,select").forEach((field) => {
          field.disabled = null;
        });
      });
    });
  }
}

import initFundraiserForm from "../../components/FundraiserForm";
import initRelationshipForms from "./relationshipForms";

export function initFundraiserOnboarding(props) {
  initFundraiserForm(props);
}

export function initMemorialOnboardingInviteManager(props) {
  initRelationshipForms(props);
  const formContainer = document.querySelector(".add-survivor-form");
  formContainer.querySelectorAll("input,select").forEach((field) => {
    field.disabled = false;
  });
}

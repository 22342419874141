import requests, { ResponseError } from "../core/requests";

/**
 * Initialize memorial search-by-URL behavior.
 *
 * @param {object} props - the props generated for memorial search.
 */
export default function initMemorialSearchByUrl(props) {
  const {
    baseSearchUrl,
    container,
    slugFieldId,
    firstNameFieldId,
    lastNameFieldId,
    excludeSlugs,
    predicateFilter,
  } = props;

  if (!container) {
    return;
  }

  const memorialSearchEntry = container.querySelector(".memorial-search-entry");
  const memorialSearchLoading = container.querySelector(".memorial-search-loading");
  const memorialSearchResultContainer = container.querySelector(
    ".memorial-search-result-container",
  );
  const memorialSearchError = container.querySelector(".memorial-search-error");
  const memorialSearchDuplicate = container.querySelector(".memorial-search-duplicate");
  const memorialSearchIneligible = container.querySelector(
    ".memorial-search-ineligible",
  );
  const manualEntryContainer = container.querySelector(".manual-entry");
  const memorialSlugField = document.getElementById(slugFieldId);
  const rawFirstNameField = document.getElementById(firstNameFieldId);
  const rawLastNameField = document.getElementById(lastNameFieldId);

  const submitButton = container.querySelector('[type="submit"]');

  /**
   * Given received memorial data from a search, display the result on-screen
   *
   * @param {object} memorialData - data about a memorial.
   */
  const showSearchResult = (memorialData) => {
    memorialSearchLoading.classList.add("d-none");

    if (memorialData) {
      if (predicateFilter && !predicateFilter(memorialData)) {
        memorialSearchIneligible.classList.remove("d-none");
        memorialSearchResultContainer.classList.add("d-none");
        memorialSearchEntry.classList.remove("d-none");
        if (manualEntryContainer) {
          manualEntryContainer.classList.remove("d-none");
        } else if (submitButton) {
          submitButton.setAttribute("disabled", "disabled");
        }
        return;
      }

      if (excludeSlugs && excludeSlugs.includes(memorialData.slug)) {
        memorialSearchDuplicate.classList.remove("d-none");
        memorialSearchResultContainer.classList.add("d-none");
        memorialSearchEntry.classList.remove("d-none");
        if (manualEntryContainer) {
          manualEntryContainer.classList.remove("d-none");
        } else if (submitButton) {
          submitButton.setAttribute("disabled", "disabled");
        }
        return;
      }

      memorialSearchResultContainer.classList.remove("d-none");
      if (manualEntryContainer) {
        manualEntryContainer.classList.add("d-none");
      } else if (submitButton) {
        submitButton.removeAttribute("disabled");
      }
      memorialSearchResultContainer.querySelector(".memorial-image").src =
        memorialData.photo;
      memorialSearchResultContainer.querySelector(".name").innerText =
        memorialData.name;
      memorialSearchResultContainer.querySelector(".location").innerText =
        memorialData.location || "";
      if (memorialData.yearOfBirth) {
        memorialSearchResultContainer.querySelector(".years").innerText =
          `${memorialData.yearOfBirth} - ${memorialData.yearOfDeath}`;
      } else {
        memorialSearchResultContainer.querySelector(".years").innerText = "--";
      }
      memorialSlugField.value = memorialData.slug;
      rawFirstNameField.value = memorialData.firstName;
      rawLastNameField.value = memorialData.lastName;
    } else {
      memorialSearchResultContainer.classList.add("d-none");
      memorialSearchEntry.classList.remove("d-none");
      memorialSearchError.classList.remove("d-none");
      if (manualEntryContainer) {
        manualEntryContainer.classList.remove("d-none");
      } else if (submitButton) {
        submitButton.setAttribute("disabled", "disabled");
      }
    }
  };

  /**
   * Search for a memorial by URL.
   *
   * @param {string} memorialUrl - the memorial URL
   * @returns {Promise<void>} - an ignorable promise
   */
  const searchMemorial = async (memorialUrl) => {
    memorialSearchEntry.classList.add("d-none");
    memorialSearchLoading.classList.remove("d-none");
    memorialSearchResultContainer.classList.add("d-none");
    memorialSearchError.classList.add("d-none");
    memorialSearchIneligible.classList.add("d-none");
    memorialSearchDuplicate.classList.add("d-none");

    const searchUrl = `${baseSearchUrl}?url=${encodeURIComponent(memorialUrl)}`;

    try {
      const response = await requests.get(searchUrl);

      if (!response.ok) {
        throw new ResponseError(response);
      }

      const responseData = await response.json();

      showSearchResult(responseData);
    } catch (error) {
      window.Rollbar.warn("Error searching memorial by URL", error);
      showSearchResult(null);
    }
  };

  if (memorialSearchEntry) {
    memorialSearchEntry.querySelector("input").addEventListener("paste", (event) => {
      const pastedUrl = (event.clipboardData || window.clipboardData).getData("text");
      searchMemorial(pastedUrl);
    });

    const clearLink = container.querySelector(".clear-search-result");
    clearLink.addEventListener("click", () => {
      memorialSlugField.value = "";
      rawFirstNameField.value = "";
      rawLastNameField.value = "";
      memorialSearchResultContainer.classList.add("d-none");
      memorialSearchEntry.classList.remove("d-none");
      if (manualEntryContainer) {
        manualEntryContainer.classList.remove("d-none");
      } else if (submitButton) {
        submitButton.setAttribute("disabled", "disabled");
      }
    });
  }
}

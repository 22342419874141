import Modal from "bootstrap/js/dist/modal";
import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_virtual_scroll from "tom-select/dist/js/plugins/virtual_scroll";

import requests, { ResponseError } from "../core/requests";

TomSelect.define("virtual_scroll", TomSelect_virtual_scroll);

/**
 * Initialize the feature-search autocomplete component.
 *
 * @param {object} props - the page props
 * @returns {HTMLElement|null} - the input element to which the autocomplete search is
 *     attached.
 */
export default function initMemorialFeatureAutocomplete(props) {
  const modalElement = document.querySelector("#memorialFeatureSearchModal");

  const searchPhraseInput = document.getElementById(props.searchPhraseInputId);
  if (!searchPhraseInput) {
    return null;
  }

  /**
   * Compose the URL combining the search query and the desired page number.
   *
   * @param {string} query - a search query
   * @param {number} page - a page number
   * @returns {string} - the generated URL.
   */
  const buildUrl = (query, page) => {
    const paramsData = { search: query };

    paramsData.page = page;

    const params = new URLSearchParams(paramsData);

    if (props.memorialFeatureSearchUrl.includes("?")) {
      return `${props.memorialFeatureSearchUrl}&${params.toString()}`;
    }

    return `${props.memorialFeatureSearchUrl}?${params.toString()}`;
  };

  const tomSelectConfig = {
    sortField: [{ field: "$order" }, { field: "$score" }],
    searchField: [],
    firstUrl(query) {
      return buildUrl(query, 1);
    },
    async load(query, callback) {
      try {
        const response = await requests.get(this.getUrl(query));

        if (!response.ok) {
          throw new ResponseError(response);
        }

        const data = await response.json();

        if (data.pagination.next) {
          const nextUrl = buildUrl(query, data.pagination.next);
          this.setNextUrl(query, nextUrl);
        }

        callback(data.results);
      } catch (error) {
        if (!(error.response && error.response.status === 429)) {
          window.Rollbar.error("TomSelect memorial feature search error", error);
        }

        callback();
      }
    },
    loadThrottle: 500,
    placeholder: 'Try "Events"',
    plugins: ["virtual_scroll"],
    valueField: "id",
  };

  const featureAutocomplete = new TomSelect(searchPhraseInput, tomSelectConfig);

  featureAutocomplete.on("change", (newValue) => {
    const selectedOption = featureAutocomplete.options[newValue];
    Modal.getOrCreateInstance(modalElement).hide();
    window.location.href = selectedOption.url;
  });

  modalElement.addEventListener("shown.bs.modal", () => {
    featureAutocomplete.open();
  });

  return searchPhraseInput;
}

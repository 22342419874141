import debounce from "lodash.debounce";
import { sendBrowserAgnosticEvent, storageAvailable } from "../core/utils";

export default class AutoSaveTextField {
  constructor(textField) {
    this.textField = textField;
    this.storageKey = this.textField.dataset.autosaveKey;
    this.remoteLastUpdated =
      parseFloat(this.textField.dataset.autosaveLastUpdated) || 0.0;
    this.storageAvailable = storageAvailable("localStorage");

    this.textField.addEventListener(
      "input",
      debounce(
        () => {
          this.store();
        },
        100,
        { maxWait: 250 },
      ),
    );

    this.initialValue = this.textField.value;

    this.load();

    if (this.textField.value) {
      // Send a slightly deferred `input` event over the DOM in particular for the
      // obit template selector management code.
      setTimeout(() => {
        sendBrowserAgnosticEvent(this.textField, "input");
      }, 300);
    }

    this.indicator = document.querySelector(
      `[data-autosave-indicator-for="${this.textField.id}"]`,
    );
    if (this.indicator) {
      const revertLink = this.indicator.querySelector(".revert-autosave");
      if (revertLink) {
        revertLink.addEventListener("click", () => {
          this.clear();
        });
      }
    }
  }

  load(force = false) {
    if (this.storageAvailable) {
      const data = JSON.parse(window.localStorage.getItem(this.storageKey));
      if (data && data.timestamp && data.text) {
        const ts = parseFloat(data.timestamp);
        if (ts > this.remoteLastUpdated || force) {
          this.textField.value = data.text;
          sendBrowserAgnosticEvent(this.textField, "input");
          sendBrowserAgnosticEvent(this.textField, "el-editor-external-input");
        }
      }
    }
  }

  store() {
    if (this.storageAvailable) {
      if (this.textField.value) {
        const data = {
          text: this.textField.value,
          timestamp: Date.now() / 1000.0,
        };

        window.localStorage.setItem(this.storageKey, JSON.stringify(data));
      } else {
        window.localStorage.removeItem(this.storageKey);
      }
    }

    this.updateIndicator();
  }

  clear() {
    if (this.storageAvailable) {
      window.localStorage.removeItem(this.storageKey);
    }

    this.textField.value = this.initialValue;
    sendBrowserAgnosticEvent(this.textField, "input");
    sendBrowserAgnosticEvent(this.textField, "el-editor-external-input");

    this.updateIndicator();
  }

  updateIndicator() {
    if (this.indicator) {
      // Handle empty rich editor value of <p></p> or <p><br></p>
      const hasNotChanged =
        this.textField.value === this.initialValue ||
        (this.initialValue === "" &&
          (this.textField.value === "<p></p>" ||
            this.textField.value === "<p><br></p>"));

      this.indicator.classList.toggle("d-none", hasNotChanged);
    }
  }
}

import { sendBrowserAgnosticEvent } from "../core/utils";

export default class WordCounter {
  constructor(root) {
    this.root = root;
    this.target = document.getElementById(this.root.dataset.wordCountTarget);
    this.max = parseInt(this.root.dataset.wordCountMax, 10);
    this.counterSpan = this.root.querySelector(".word-counter-count");

    if (!(this.target && this.max)) {
      return;
    }

    this.target.addEventListener("input", () => {
      this.assessCount();
    });
  }

  assessCount() {
    const currentCount = this.getWordCount();
    const overage = currentCount - this.max;

    if (overage > 0) {
      this.counterSpan.innerText = `${currentCount}/${this.max}`;
      sendBrowserAgnosticEvent(this.root, "elwc:overage");
    } else {
      this.counterSpan.innerText = `${currentCount}/${this.max}`;
      sendBrowserAgnosticEvent(this.root, "elwc:compliance");
    }

    this.counterSpan.classList.toggle("overage", overage > 0);
    this.counterSpan.classList.toggle("warning", overage >= -10 && overage <= 0);
  }

  getWordCount() {
    return this.target.value.split(/\s/).filter((token) => token.length > 0).length;
  }
}

import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_virtual_scroll from "tom-select/dist/js/plugins/virtual_scroll";

import initGoogleAutocomplete from "./GoogleAutocomplete";
import { createAutocompleteOption } from "../places/utils";
import requests, { ResponseError } from "../core/requests";

TomSelect.define("virtual_scroll", TomSelect_virtual_scroll);

/**
 * Format a Deceased search result for display in the dropdown.
 *
 * @param {object} deceased - an object representing a deceased in search results.
 * @returns {HTMLDivElement} - the rendered autocomplete option
 */
const formatDeceased = (deceased) => {
  if (deceased.dateOfBirth) {
    return createAutocompleteOption(
      deceased.text,
      `${deceased.dateOfBirth} - ${deceased.dateOfDeath}`,
    );
  }

  return createAutocompleteOption(deceased.text);
};

/**
 * Toggle display of the manual family/predeceased person entry.
 *
 * @param {object} props - the page props
 * @param {boolean} showManualEntry - if true, show the manual entry form (if false,
 *     hide it)
 */
const toggleManualEntry = (props, showManualEntry) => {
  let { deceasedFirstName: firstName, deceasedLastName: lastName } = props;
  const fullNameInput = document.querySelector(`#${props.deceasedNameFieldId}`);
  const firstNameInput = document.querySelector(`#${props.deceasedFirstNameFieldId}`);
  const lastNameInput = document.querySelector(`#${props.deceasedLastNameFieldId}`);

  if (showManualEntry) {
    document
      .querySelector(`.${props.manualEntryContainerClass}`)
      .classList.remove("d-none");
    document
      .querySelector(`.${props.memorialSearchContainerClass}`)
      .classList.add("d-none");

    if (!firstName && !lastName) {
      const fullName = fullNameInput.value;
      const splitNames = fullName.split(" ");
      [firstName] = splitNames;
      lastName = splitNames.slice(1).join(" ");
    }

    fullNameInput.value = "";
    firstNameInput.value = firstName;
    lastNameInput.value = lastName;
  } else {
    document
      .querySelector(`.${props.manualEntryContainerClass}`)
      .classList.add("d-none");
    document
      .querySelector(`.${props.memorialSearchContainerClass}`)
      .classList.remove("d-none");

    firstNameInput.value = "";
    lastNameInput.value = "";
  }
};

/**
 * Initialize the autocomplete search for deceaseds/memorials.
 *
 * @param {object} props - the page props
 * @returns {HTMLElement|null} - the input element, if successfully configured
 */
const initDeceasedAutocomplete = (props) => {
  const deceasedIdentifierInput = document.getElementById(props.deceasedNameFieldId);
  if (!deceasedIdentifierInput) {
    return null;
  }

  /**
   * Compose the URL combining the search query and the desired page number.
   *
   * @param {string} query - a search query
   * @param {number} page - a page number
   * @returns {string} - the generated URL.
   */
  const buildUrl = (query, page) => {
    const paramsData = { search: query };

    paramsData.page = page;

    const params = new URLSearchParams(paramsData);

    return `${props.deceasedSearchUrl}?${params.toString()}`;
  };

  const tomSelectConfig = {
    plugins: ["virtual_scroll"],
    valueField: "id",
    sortField: [{ field: "$order" }, { field: "$score" }],
    searchField: [],
    firstUrl(query) {
      return buildUrl(query, 1);
    },
    async load(query, callback) {
      try {
        const response = await requests.get(this.getUrl(query));

        if (!response.ok) {
          throw new ResponseError(response);
        }

        const data = await response.json();

        if (data.pagination.next) {
          const nextUrl = buildUrl(query, data.pagination.next);
          this.setNextUrl(query, nextUrl);
        }

        callback(data.results);
      } catch (error) {
        if (!(error.response && error.response.status === 429)) {
          window.Rollbar.error("TomSelect deceased search error", error);
        }

        callback();
      }
    },
    loadThrottle: 500,
    placeholder: props.deceasedPlaceholder,
    render: {
      option: (item) => formatDeceased(item),
      not_loading: (data) => {
        if (data.input.length === 0) {
          return null;
        }

        return '<div class="no-results">Please enter the name.</div>';
      },
    },
    shouldLoad: (query) => query.length >= 3,
    create: (input) => ({ id: input.trim(), text: input.trim(), created: true }),
    maxItems: 1,
  };

  const deceasedSearchAutocomplete = new TomSelect(
    deceasedIdentifierInput,
    tomSelectConfig,
  );
  deceasedSearchAutocomplete.on("change", (newValue) => {
    if (newValue) {
      const newOption = deceasedSearchAutocomplete.options[newValue];
      const deceasedMemorialSlugInput = document.getElementById(
        props.deceasedMemorialSlugFieldId,
      );

      if (newOption.created) {
        toggleManualEntry(props, true);
        deceasedMemorialSlugInput.value = "";
      } else {
        deceasedMemorialSlugInput.value = newOption.id;
      }
    }
  });

  // Set the value, creating a new option if necessary
  if (props.initialAutocompleteData && props.initialAutocompleteData.id) {
    const availableOption =
      deceasedSearchAutocomplete.options[props.initialAutocompleteData.id];
    if (!availableOption) {
      deceasedSearchAutocomplete.addOption(props.initialAutocompleteData);
    }

    deceasedSearchAutocomplete.setValue(props.initialAutocompleteData.id);
  }

  if (props.deceasedManualEntryErrors) {
    toggleManualEntry(props, true);
  }

  const followerForm = document.querySelector(`.${props.deceasedFormClass}`);
  followerForm.querySelector(".close-button").addEventListener("click", () => {
    toggleManualEntry(props, false);
    deceasedSearchAutocomplete.clear();
  });

  const locationContainer = document.querySelector(
    `.${props.deceasedLocationContainerClass}`,
  );
  if (locationContainer) {
    initGoogleAutocomplete({
      element: locationContainer.querySelector("input"),
      options: {
        types: ["(cities)"],
      },
    });
  }

  return deceasedIdentifierInput;
};

/**
 * Set up behavior to control submit button toggling based on the raw-data form entry
 * states.
 *
 * @param {object} props - the page props
 */
const setupFormListeners = (props) => {
  const nameField = document.querySelector(`#${props.deceasedNameFieldId}`);
  const firstNameField = document.querySelector(`#${props.deceasedFirstNameFieldId}`);
  const lastNameField = document.querySelector(`#${props.deceasedLastNameFieldId}`);
  const relationshipSelect = document.querySelector(
    `#${props.deceasedRelationshipTypeFieldId}`,
  );

  function toggleButton() {
    const submitButton = document.querySelector(`.${props.deceasedSubmitButton}`);
    if (
      relationshipSelect.value &&
      (nameField.value || (firstNameField.value && lastNameField.value))
    ) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }

  if (nameField.tomselect) {
    nameField.tomselect.on("change", () => toggleButton());
  }

  relationshipSelect.addEventListener("change", () => {
    toggleButton();
  });
};

/**
 * This function sets up autocomplete and form listeners for the search by name
 * deceased forms. The inner function with a similar name is concerned only with the
 * autocomplete part of this component.
 *
 * @param {object} props - the page props
 */
export default function initDeceasedSearchAutocomplete(props) {
  initDeceasedAutocomplete(props);
  setupFormListeners(props);
}

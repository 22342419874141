import "share-api-polyfill";

/**
 * Given a query selector, get the element and initiate `navigator.share` behavior on it
 * Gracefully accepts nonexistent elements and incompatible browsers.
 *
 * @param {string} selector - a query selector of a .btn-native-share element
 */
export default function initMobileShareButton(selector) {
  const shareButton = document.querySelector(selector);
  if (shareButton) {
    shareButton.addEventListener("click", () => {
      if (navigator && navigator.share) {
        navigator
          .share({
            title: shareButton.dataset.shareTitle,
            url: shareButton.dataset.shareUrl,
          })
          .catch(() => {
            // Do nothing but swallow unhandled promise rejections.
          });
      }
    });
  }
}

import Modal from "bootstrap/js/dist/modal";

import requests from "../core/requests";
import {
  handleAjaxFormSubmitProtection,
  readValidFileFromInput,
  sendBrowserAgnosticEvent,
} from "../core/utils";

export default class BelongingEditor {
  constructor(modalContainer, props) {
    this.modalContainer = modalContainer;
    this.createUrl = props.createUrl;
    this.editUrl = props.editUrl;
    this.modal = new Modal(this.modalContainer);

    this.titleNameSpan = this.modalContainer.querySelector(".belonging-editor--name");
    this.titleClaimedBadge = this.modalContainer.querySelector(
      ".belonging-editor--claimed",
    );
    this.titleAvailableBadge = this.modalContainer.querySelector(
      ".belonging-editor--available",
    );

    this.productPhoto = this.modalContainer.querySelector(".product-photo");
    this.productPhotoPlaceholder = this.productPhoto.src;

    const fieldIds = props.belongingFormFieldIds;
    this.photoInput = document.getElementById(fieldIds.photo);
    this.nameInput = document.getElementById(fieldIds.name);
    this.descriptionTextarea = document.getElementById(fieldIds.description);
    this.isPublishedCheckbox = document.getElementById(fieldIds.isPublished);
    this.isClaimedCheckbox = document.getElementById(fieldIds.isClaimed);

    this.productPhoto.addEventListener("click", () => {
      this.photoInput.click();
    });

    this.nameInput.addEventListener("input", () => {
      this.renderDependents();
    });

    this.isClaimedCheckbox.addEventListener("change", () => {
      this.renderDependents();
    });

    this.photoInput.addEventListener("change", () => {
      const file = readValidFileFromInput(this.photoInput);
      if (file) {
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
          this.productPhoto.src = readerEvent.target.result;
          this.productPhoto.parentNode.style.backgroundImage = `url('${readerEvent.target.result}');`;
        };

        reader.readAsDataURL(file);
      }
    });

    this.activeBelonging = null;

    this.onBelongingChanged = props.onBelongingChanged;

    this.form = this.modalContainer.querySelector("form");
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    this.saveButton = this.modalContainer.querySelector(".save-belonging-details");
    this.saveButton.addEventListener("click", () => {
      this.save();
    });
  }

  renderDependents() {
    this.titleClaimedBadge.classList.toggle("d-none", !this.isClaimedCheckbox.checked);
    this.titleAvailableBadge.classList.toggle("d-none", this.isClaimedCheckbox.checked);
    this.titleNameSpan.innerText = this.nameInput.value;
    this.productPhoto.setAttribute("alt", this.nameInput.value);
  }

  reset() {
    this.photoInput.value = null;
    this.nameInput.value = "";
    this.descriptionTextarea.value = "";
    this.isPublishedCheckbox.checked = false;
    this.isClaimedCheckbox.checked = false;

    this.titleNameSpan.innerText = "";
    this.productPhoto.setAttribute("alt", "");
    this.modalContainer.querySelector(".alert-danger").classList.add("d-none");

    this.renderDependents();
  }

  dismiss() {
    this.reset();
    this.modal.hide();
  }

  showForBelonging(belonging) {
    this.activeBelonging = belonging;

    let invokeFilePicker = false;

    if (this.activeBelonging) {
      this.nameInput.value = this.activeBelonging.name;
      this.descriptionTextarea.value = this.activeBelonging.description;
      this.isPublishedCheckbox.checked = this.activeBelonging.isPublished;
      this.isClaimedCheckbox.checked = this.activeBelonging.isClaimed;
      this.productPhoto.src = this.activeBelonging.photo;
      this.productPhoto.parentNode.style.backgroundImage = `url('${this.activeBelonging.photo}');`;
    } else {
      this.nameInput.value = "";
      this.descriptionTextarea.value = "";
      this.isPublishedCheckbox.checked = true;
      this.isClaimedCheckbox.checked = false;
      this.productPhoto.src = this.productPhotoPlaceholder;
      this.productPhoto.parentNode.style.backgroundImage = "none";

      invokeFilePicker = true;
    }

    sendBrowserAgnosticEvent(this.nameInput, "input");
    this.renderDependents();

    this.modal.show();

    if (invokeFilePicker) {
      this.photoInput.click();
    }
  }

  getUrl() {
    if (this.activeBelonging && this.activeBelonging.id) {
      return this.editUrl.replace("__id__", this.activeBelonging.id);
    }

    return this.createUrl;
  }

  async save() {
    this.saveButton.dataset.clicked = true;
    const cancelSubmitProtect = handleAjaxFormSubmitProtection(this.saveButton);
    this.modalContainer.querySelector(".alert-danger").classList.add("d-none");
    this.modalContainer.querySelectorAll(".errorlist").forEach((errList) => {
      while (errList.hasChildNodes()) {
        errList.removeChild(errList.lastChild);
      }
      errList.classList.add("d-none");
    });

    const showGenericError = () => {
      const mainErrorAlert = this.modalContainer.querySelector(".alert-danger");
      mainErrorAlert.classList.remove("d-none");
      mainErrorAlert.querySelector(".error-message").innerText =
        "An unknown error occurred.";
    };

    const handleFail = (data) => {
      window.Rollbar.error(
        "Error modifying Belonging",
        data.responseJSON ? data.responseJSON.errors : data,
      );
      cancelSubmitProtect();

      if (data.errors) {
        const mainErrorAlert = this.modalContainer.querySelector(".alert-danger");
        mainErrorAlert.classList.remove("d-none");
        mainErrorAlert.querySelector(".error-message").innerText =
          data.errors.main || "An unknown error occurred.";
        Object.keys(data.errors).forEach((errKey) => {
          if (errKey === "main") {
            return;
          }

          const errorList = this.modalContainer.querySelector(`.${errKey}-errorlist`);
          if (errorList) {
            errorList.classList.remove("d-none");
            data.errors[errKey].forEach((err) => {
              const errItem = document.createElement("li");
              errItem.innerText = err;
              errorList.appendChild(errItem);
            });
          }
        });
      } else {
        showGenericError();
      }
    };

    let responseData;

    try {
      const response = await requests.post(this.getUrl(), this.form);

      if (!response.ok) {
        showGenericError();
        return;
      }

      responseData = await response.json();
    } catch (error) {
      showGenericError();
      throw error;
    }

    if (responseData.success) {
      cancelSubmitProtect();
      this.onBelongingChanged(responseData.data, this);
      this.dismiss();
    } else {
      handleFail(responseData);
    }
  }
}

import Modal from "bootstrap/js/dist/modal";
import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_remove_button from "tom-select/dist/js/plugins/remove_button";
import TomSelect_virtual_scroll from "tom-select/dist/js/plugins/virtual_scroll";

import { runInviteFormValidation } from "./UserMentions";
import { createAutocompleteOption } from "../places/utils";
import requests, { ResponseError } from "../core/requests";

TomSelect.define("remove_button", TomSelect_remove_button);
TomSelect.define("virtual_scroll", TomSelect_virtual_scroll);

const NO_CHANGES_CLASS = "no-changes";

/**
 * Format a participant for display in autocomplete dropdown.
 *
 * @param {object} participant - the participant data from the server response.
 * @returns {HTMLDivElement|null} - the rendered element (or null)
 */
const formatParticipant = (participant) => {
  if (participant.name && participant.thumbnailUrl) {
    const img = document.createElement("img");
    img.setAttribute("class", "user-profile-photo");
    img.setAttribute("src", participant.thumbnailUrl);
    img.setAttribute("alt", "User profile photo");

    return createAutocompleteOption([img, participant.name]);
  }
  if (participant.name && !participant.thumbnailUrl) {
    return createAutocompleteOption(participant.name);
  }

  return null;
};

/**
 * Clear the invite modal form.
 *
 * @param {Element} inviteModal - the modal container.
 */
const clearInviteForm = (inviteModal) => {
  const inviteFormInputs = inviteModal.querySelectorAll("input");
  inviteFormInputs.forEach((inviteInput) => {
    inviteInput.value = "";
  });
  inviteModal.removeAttribute("data-temporary-participant-identifier");

  const modalParticipantData = inviteModal.querySelector(".temporary-participant-data");
  modalParticipantData.setAttribute("value", "");
};

/**
 * Initialize participant autocomplete behavior, including invite-participant modal.
 *
 * @param {object} props - the page props
 * @returns {Function} - an anonymous function to flush participant data to the json-
 *     data field.
 */
export default function initParticipantAutocomplete(props) {
  const {
    participantsInitialAutocompleteData = [],
    participantsInputIds: { participants, participantsJson },
    userMention: { memorialSlug, userSearchEndpoint },
  } = props;

  const participantsField = document.querySelector(`#${participants}`);
  // Values are added to participantsField by tomselect which causes the dirty form
  // tracker to think there's been a change. We add the NO_CHANGES_CLASS on initial load
  // so that the dirty form tracker knows to ignore the field. On field change, the
  // NO_CHANGES_CLASS is removed.
  participantsField.classList.add(NO_CHANGES_CLASS);
  const hiddenParticipantsJsonInput = document.querySelector(`#${participantsJson}`);
  const form = participantsField.closest("form");

  // this houses the participant data before submission
  participantsField.temporaryParticipants = participantsInitialAutocompleteData;
  const currentParticipantsIdentifier = `${participants}-temp`;

  const inviteModal = document.querySelector("#modal-user-mention");
  const modalParticipantData = inviteModal.querySelector(".temporary-participant-data");

  function flushParticipantDataToJsonField() {
    hiddenParticipantsJsonInput.setAttribute(
      "value",
      JSON.stringify(participantsField.temporaryParticipants),
    );
    clearInviteForm(inviteModal);

    participantsField.tomselect.clear();
  }

  /**
   * Compose the URL combining the search query and the desired page number.
   *
   * @param {string} query - a search query
   * @param {number} page - a page number
   * @returns {string} - the generated URL.
   */
  const buildUrl = (query, page) => {
    const paramsData = {
      search: query,
      "memorial-slug": memorialSlug,
    };

    paramsData.page = page;

    const params = new URLSearchParams(paramsData);

    return `${userSearchEndpoint}?${params.toString()}`;
  };

  const tomSelectConfig = {
    sortField: [{ field: "$order" }, { field: "$score" }],
    searchField: [],
    create: (input) => {
      const term = input.trim();

      if (term === "") {
        return null;
      }

      return {
        id: term,
        name: term,
        created: true,
        text: term,
      };
    },
    firstUrl(query) {
      return buildUrl(query, 1);
    },
    async load(query, callback) {
      try {
        const response = await requests.get(this.getUrl(query));

        if (!response.ok) {
          throw new ResponseError(response);
        }

        const data = await response.json();

        if (data.pagination.next) {
          const nextUrl = buildUrl(query, data.pagination.next);
          this.setNextUrl(query, nextUrl);
        }

        callback(
          data.results.map((user) => ({
            id: user.userHash,
            name: user.userMentionName,
            created: false,
            text: user.userMentionName,
            thumbnailUrl: user.thumbnailUrl,
            userSlug: user.userSlug,
          })),
        );
      } catch (error) {
        if (!(error.response && error.response.status === 429)) {
          window.Rollbar.error("TomSelect event participant error", error);
        }

        callback();
      }
    },
    loadThrottle: 500,
    render: {
      option: (item) => formatParticipant(item),
      not_loading: (data) => {
        if (data.input.length === 0) {
          return null;
        }

        return '<div class="no-results">Please enter more text.</div>';
      },
    },
    shouldLoad: (query) => query.length >= 3,
    placeholder: "Example: Laura Adams",
    plugins: {
      virtual_scroll: true,
      remove_button: {
        title: "Remove this item",
      },
    },
    valueField: "id",
  };

  const participantAutocomplete = new TomSelect(participantsField, tomSelectConfig);

  if (
    participantsInitialAutocompleteData &&
    participantsInitialAutocompleteData.length
  ) {
    participantAutocomplete.addOptions(participantsInitialAutocompleteData);
    participantAutocomplete.addItems(
      participantsInitialAutocompleteData.map((data) => data.id),
    );
  }

  participantAutocomplete.on("item_remove", (value) => {
    participantsField.dataset.unselect = true;
    participantsField.classList.remove(NO_CHANGES_CLASS);

    const deselectedData = participantAutocomplete.options[value];

    participantsField.temporaryParticipants =
      participantsField.temporaryParticipants.filter(
        (participantData) =>
          !(
            participantData.id === deselectedData.id &&
            participantData.name === deselectedData.name &&
            participantData.created === deselectedData.created &&
            participantData.userSlug === deselectedData.userSlug
          ),
      );
  });

  // On open, close dropdown immediately if has data-unselect
  participantAutocomplete.on("dropdown_open", () => {
    if (participantsField.dataset.unselect) {
      participantAutocomplete.close();
      participantsField.removeAttribute("data-unselect");
    }
  });

  // On select
  participantAutocomplete.on("item_add", (value) => {
    participantsField.classList.remove(NO_CHANGES_CLASS);
    const selectedOption = participantAutocomplete.options[value];

    if (selectedOption.created) {
      Modal.getOrCreateInstance(inviteModal).show();
      modalParticipantData.setAttribute("value", JSON.stringify(selectedOption));
      inviteModal.dataset.temporaryParticipantIdentifier =
        currentParticipantsIdentifier;
    } else {
      // existing user chosen
      participantsField.temporaryParticipants.push(selectedOption);
    }
  });

  form.addEventListener("submit", () => {
    flushParticipantDataToJsonField();
  });

  inviteModal.addEventListener("input", () => {
    runInviteFormValidation(inviteModal.querySelector("form"));
  });

  inviteModal
    .querySelector(".notify-user-mention-button")
    .addEventListener("click", () => {
      Modal.getOrCreateInstance(inviteModal).hide();

      if (
        inviteModal.dataset.temporaryParticipantIdentifier ===
          currentParticipantsIdentifier &&
        modalParticipantData.value
      ) {
        const inviteData = JSON.parse(modalParticipantData.value);
        const firstNameField = inviteModal.querySelector(
          "#user-mention-invite-first-name",
        );
        const lastNameField = inviteModal.querySelector(
          "#user-mention-invite-last-name",
        );
        const emailField = inviteModal.querySelector("#user-mention-invite-email");

        inviteData.name = `${firstNameField.value} ${lastNameField.value}`;
        inviteData.email = emailField.value;

        participantsField.temporaryParticipants.push(inviteData);
        clearInviteForm(inviteModal);
      }
    });

  inviteModal
    .querySelector(".cancel-notify-user-mention-button")
    .addEventListener("click", () => {
      if (
        inviteModal.dataset.temporaryParticipantIdentifier ===
          currentParticipantsIdentifier &&
        modalParticipantData.value
      ) {
        participantsField.temporaryParticipants.push(
          JSON.parse(modalParticipantData.value),
        );
        clearInviteForm(inviteModal);
      }
    });

  return flushParticipantDataToJsonField;
}

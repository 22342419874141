export default class ProgressRing {
  constructor(root, initialPercentage = 0) {
    this.root = root;
    this.circle = this.root.querySelector(".progress-ring__circle");
    this.label = this.root.querySelector(".progress-ring__label");

    const radius = this.circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;

    this.circle.style.strokeDasharray = `${circumference} ${circumference}`;
    this.circle.style.strokeDashoffset = `${circumference}`;

    this.setPercentage(parseInt(initialPercentage, 10));
  }

  setPercentage(newPercentage) {
    const radius = this.circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    this.circle.style.strokeDashoffset =
      circumference - (newPercentage / 100) * circumference;

    if (this.label) {
      this.label.textContent = `${newPercentage}%`;
    }
  }
}

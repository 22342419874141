export default function initMemorialOnboardingFundraisingGuidance() {
  const choiceField = document.querySelector('input[name="_fundraising_choice"]');
  const choices = document.querySelectorAll(".fundraising-guidance-option");

  if (choiceField && choices.length) {
    choices.forEach((choice) => {
      choice.addEventListener("click", () => {
        choiceField.value = choice.dataset.fundraisingChoice;
        choiceField.closest("form").submit();
      });
    });
  }
}

import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_virtual_scroll from "tom-select/dist/js/plugins/virtual_scroll";

import { createAutocompleteOption } from "../places/utils";
import requests, { ResponseError } from "../core/requests";

TomSelect.define("virtual_scroll", TomSelect_virtual_scroll);

/**
 * Format the city search result for display in the autocomplete.
 *
 * @param {object} city - an object representing a city in search results
 * @returns {HTMLDivElement} - the rendered element for embedding in the dropdown
 */
const formatCity = (city) => createAutocompleteOption(city.text);

/**
 * Initialize the autocomplete behavior for city search.
 *
 * @param {object} props - the page props
 * @returns {HTMLElement|null} - the input to which the autocomplete behavior is bound
 */
export default function initCityAutocomplete(props) {
  const cityIdentifierInput = document.getElementById(props.cityIdentifierInputId);
  if (!cityIdentifierInput) {
    return null;
  }

  let openUrlOnSelect = false;
  if ("openUrlOnSelect" in props) {
    ({ openUrlOnSelect } = props);
  }

  /**
   * Compose the URL combining the search query and the desired page number.
   *
   * @param {string} query - a search query
   * @param {number} page - a page number
   * @returns {string} - the generated URL.
   */
  const buildUrl = (query, page) => {
    const paramsData = { search: query };

    if (props.businessTypeName) {
      paramsData["for-business-type"] = props.businessTypeName;
    }

    paramsData.page = page;

    const params = new URLSearchParams(paramsData);

    return `${props.citySearchUrl}?${params.toString()}`;
  };

  const tomSelectConfig = {
    plugins: ["virtual_scroll"],
    valueField: "id",
    sortField: [{ field: "$order" }, { field: "$score" }],
    searchField: [],
    firstUrl(query) {
      return buildUrl(query, 1);
    },
    async load(query, callback) {
      try {
        const response = await requests.get(this.getUrl(query));

        if (!response.ok) {
          throw new ResponseError(response);
        }

        const data = await response.json();

        if (data.pagination.next) {
          const nextUrl = buildUrl(query, data.pagination.next);
          this.setNextUrl(query, nextUrl);
        }

        callback(data.results);
      } catch (error) {
        if (!(error.response && error.response.status === 429)) {
          window.Rollbar.error("TomSelect city search error", error);
        }

        callback();
      }
    },
    loadThrottle: 500,
    placeholder: props.placeholder || "Please select a city",
    render: {
      option: (item) => formatCity(item),
      not_loading: (data) => {
        if (data.input.length === 0) {
          return null;
        }

        return '<div class="no-results">Please enter more text.</div>';
      },
    },
    shouldLoad: (query) => query.length >= 3,
    onChange(value) {
      if (openUrlOnSelect && value) {
        const optionSelected = this.options[value];
        if (openUrlOnSelect) {
          window.location.href = optionSelected.url;
        }
      }
    },
    maxItems: 1,
  };

  new TomSelect(cityIdentifierInput, tomSelectConfig);

  return cityIdentifierInput;
}

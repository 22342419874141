import TomSelect from "tom-select/dist/js/tom-select.base";
/**
 * Initialize the cause-of-death search/autocomplete behavior.
 *
 * @param {object} props - the page props
 */
export default function initCauseOfDeathAutocomplete(props) {
  const causeOfDeathInput = document.getElementById(props.inputId);

  if (!causeOfDeathInput) {
    return;
  }

  const tomSelectConfig = {
    create: (input) => ({ id: input.trim(), text: input.trim(), created: true }),
    maxItems: 1,
    placeholder: "Ex. Cancer",
    valueField: "id",
    sortField: [{ field: "$order" }, { field: "$score" }],
  };

  const tomSelect = new TomSelect(causeOfDeathInput, tomSelectConfig);

  // Set the value, creating a new option if necessary
  if (props.initialAutocompleteData) {
    const availableOption = tomSelect.options[props.initialAutocompleteData.id];
    if (!availableOption) {
      tomSelect.addOption(props.initialAutocompleteData);
    }

    tomSelect.setValue(props.initialAutocompleteData.id);
  }

  // We want to disable the search bar when we have one of the association boolean
  // checkboxes selected
  let notShared;
  if (props.notShared) {
    notShared = document.getElementById(props.notShared);
  }

  if (props.notShared && props.initialPendingData) {
    tomSelect.disable();
  }

  if (props.notShared) {
    notShared.addEventListener("change", () => {
      // If the user selected a check box we should disable the search box
      if (notShared.checked) {
        tomSelect.clear();
        tomSelect.disable();
      } else {
        tomSelect.enable();
      }
    });
  }

  const strInput = document.getElementById(props.strInputId);
  if (strInput) {
    const form = strInput.closest("form");
    if (form) {
      form.addEventListener("submit", () => {
        // When a user is submitting a user-created item (i.e a cause of death
        // not listed), we need to update the information that is submitted by the form.
        const selectedOption = tomSelect.options[tomSelect.getValue()];
        if (selectedOption && selectedOption.created) {
          strInput.value = selectedOption.text;
          tomSelect.clear();
        } else {
          strInput.value = "";
        }

        // If the user is able to come back to the form and select that they are
        // not going to sharing a cause of death, we want to trigger a change to the
        // string that will remove their cause of death if there is one already set.
        if (notShared && notShared.checked) {
          strInput.value = "";
          tomSelect.clear();
        }
      });
    }
  }
}

import Collapse from "bootstrap/js/dist/collapse";

import initDateTimePicker from "./DateTimePicker";
import initGoogleAutocomplete from "./GoogleAutocomplete";
import initParticipantAutocomplete from "./Participant";
import { initAttendeeFormset } from "../memorials/rsvps";
import { scrollInMemorialPrivateContentContainer } from "../memorials/utils";
import { generateElements } from "../core/utils";

const placeholderEventsStatePending =
  "Example: We're planning a celebration of life sometime in June in Chicago. I'll add more details as soon as we lock down a date.";

/**
 * Disable the edit events forms when a non-events-public event_state is selected.
 *
 * @param {boolean} disabled - if the forms should be disabled.
 */
const setEventsContainerInputsDisabledState = (disabled) => {
  const eventInputContainers = document.querySelectorAll(".edit-events-container");
  eventInputContainers.forEach((eventInputContainer) => {
    eventInputContainer
      .querySelectorAll("input, textarea, select")
      .forEach((eventInput) => {
        if (eventInput.tomselect) {
          if (disabled) {
            eventInput.tomselect.disable();
          } else {
            eventInput.tomselect.enable();
          }
        } else {
          eventInput.disabled = disabled;
        }
      });
  });
};

/**
 * Initialize behavior where if the "other" title is focused, the "other" event type
 * radio gets selected.
 *
 * @param {number} formCount - the number of forms (which is used as the index of the
 *     title input to which to attach the behavior)
 */
const addEventTypeOtherFocus = (formCount) => {
  const title = document.getElementById(`id_form-${formCount}-event_type_other_title`);
  const otherInput = title.parentElement.querySelector('[value="0"]');
  title.addEventListener("click", () => {
    otherInput.checked = true;
  });
};

/**
 * Initialize handling event type selection for an event form.
 *
 * @param {HTMLElement} formContainer - the form-containing element.
 */
const attachEventTypeListener = (formContainer) => {
  const eventTypeContainer = formContainer.querySelector(".event-type");

  /**
   * Handle the event selection -- show and hide the name field, etc.
   */
  const handleEventSelection = () => {
    const selectedEventType = eventTypeContainer.querySelector(
      'input[type="radio"]:checked',
    );
    const eventName = formContainer.querySelector(".event-name");
    if (selectedEventType && eventName) {
      const otherField = document.getElementById(
        `id_${selectedEventType.getAttribute("name")}_other_title`,
      );
      const newTitle =
        selectedEventType.value === "0"
          ? otherField.value
          : document.querySelector(
              `label[for=${selectedEventType.getAttribute("id")}] .radio-label-text`,
            ).innerText;

      if (newTitle) {
        eventName.innerText = newTitle;
        eventName.classList.remove("empty");
      } else {
        eventName.innerText = "New Event";
        eventName.classList.add("empty");
      }

      if (selectedEventType.value === "0") {
        otherField.setAttribute("required", "required");
      } else {
        otherField.removeAttribute("required");
      }
    }
  };

  eventTypeContainer.querySelectorAll('input[type="radio"]').forEach((radio) => {
    radio.addEventListener("click", handleEventSelection);
  });

  eventTypeContainer
    .querySelector('input[type="text"]')
    .addEventListener("input", handleEventSelection);

  handleEventSelection();
};

/**
 * Initialize the Google places autocomplete behavior for an event form.
 *
 * @param {HTMLInputElement} element - the input to which to attach autocomplete.
 * @param {HTMLInputElement} metadataElement - the metadata element into which to place
 *     metadata
 * @param {boolean} alwaysBlockReturnKeySubmit - if true, block return key sending a
 *     submit event to the form
 */
const addEventLocationAutocomplete = (
  element,
  metadataElement,
  alwaysBlockReturnKeySubmit,
) => {
  initGoogleAutocomplete({
    element,
    metadataElement,
    options: {
      types: ["establishment", "geocode"],
    },
    alwaysBlockReturnKeySubmit,
  });
};

/**
 * Initialize the RSVP limit feature on an event form.
 *
 * @param {HTMLElement} container - the event form container
 */
const initRSVPLimitBehavior = (container) => {
  const rsvpsEnabledCheckbox = container.querySelector(".event-rsvps-enabled-checkbox");
  const rsvpsLimitContainer = container.querySelector(".rsvps-limit-container");

  if (!(rsvpsEnabledCheckbox && rsvpsLimitContainer)) {
    return;
  }

  const rsvpsLimitContainerCollapse = Collapse.getOrCreateInstance(
    rsvpsLimitContainer,
    { toggle: false },
  );
  const rsvpsLimitCheckbox = container.querySelector(".event-rsvps-limit-checkbox");
  const rsvpsLimitFieldContainer = container.querySelector(
    ".rsvps-limit-field-container",
  );
  const rsvpsLimitField = rsvpsLimitFieldContainer.querySelector('[type="number"]');
  const rsvpsLimitFieldContainerCollapse = Collapse.getOrCreateInstance(
    rsvpsLimitFieldContainer,
    { toggle: false },
  );

  /**
   * Update the limit display based on RSVP/limit state.
   */
  const updateRSVPLimitDisplay = () => {
    if (rsvpsEnabledCheckbox.checked) {
      rsvpsLimitContainerCollapse.show();
    } else {
      rsvpsLimitContainerCollapse.hide();
    }

    if (rsvpsLimitCheckbox.checked) {
      if (!rsvpsLimitField.value) {
        const minValue = rsvpsLimitField.getAttribute("min");
        if (minValue) {
          rsvpsLimitField.value = Number.parseInt(minValue, 10);
        } else {
          rsvpsLimitField.value = 10;
        }
      }

      rsvpsLimitFieldContainerCollapse.show();
    } else {
      rsvpsLimitField.value = null;
      rsvpsLimitFieldContainerCollapse.hide();
    }
  };

  rsvpsEnabledCheckbox.addEventListener("change", updateRSVPLimitDisplay);
  rsvpsLimitCheckbox.addEventListener("change", updateRSVPLimitDisplay);

  updateRSVPLimitDisplay();
};

/**
 * Initialize the streaming configuration behavior for an event form.
 *
 * @param {HTMLElement} container - the event form container
 */
const initStreamingOptions = (container) => {
  const eventStreamingCheckbox = container.querySelector(
    ".event-stream-enabled-checkbox",
  );
  const zoomHostingCheckbox = container.querySelector(".event-zoom-enabled-checkbox");
  const urlContainer = container.querySelector(".event-streaming-url-container");
  const zoomDataContainer = container.querySelector(".event-zoom-data-container");
  const eventLocationLabel = container.querySelector(".event-location-label");

  /**
   * Update the location field label with (optional for streams) as necessary.
   */
  const updateLocationLabel = () => {
    if (eventStreamingCheckbox.checked || zoomHostingCheckbox.checked) {
      if (eventLocationLabel.textContent.indexOf(" (optional for streams)") === -1) {
        eventLocationLabel.textContent = eventLocationLabel.textContent.concat(
          " (optional for streams)",
        );
      }
    } else {
      eventLocationLabel.textContent = eventLocationLabel.textContent.replace(
        " (optional for streams)",
        "",
      );
    }
  };

  eventStreamingCheckbox.addEventListener("click", (event) => {
    updateLocationLabel();
    if (event.target.checked) {
      urlContainer.classList.remove("d-none");
    } else {
      urlContainer.classList.add("d-none");
    }
  });

  zoomHostingCheckbox.addEventListener("click", (event) => {
    updateLocationLabel();
    if (event.target.checked) {
      zoomDataContainer.classList.remove("d-none");
    } else {
      zoomDataContainer.classList.add("d-none");
    }
  });
};

/**
 * Initialize "Extra Data" fields for an event form -- COVID precautions, attachments.
 *
 * @param {HTMLElement} container - the event form container
 * @param {object} props - the page props
 * @returns {Function} - a function to flush selected participants to the JSON field.
 */
const initEventExtraData = (container, props) => {
  let flushParticipantData;
  if (container) {
    const showCovid = container.querySelector(".toggle-covid-precautions");
    const covidInputs = container.querySelector(".event-covid-precautions");

    if (showCovid && covidInputs) {
      showCovid.addEventListener("click", () => {
        showCovid.classList.add("d-none");
        covidInputs.classList.remove("d-none");
      });
    }

    const covidPrecautionOtherContainer = container.querySelector(
      ".covid-precaution-other-container",
    );
    if (covidPrecautionOtherContainer) {
      const checkbox = covidPrecautionOtherContainer.querySelector(
        'input[type="checkbox"]',
      );
      const label = covidPrecautionOtherContainer.querySelector(
        ".covid-precaution-other-label",
      );
      const field = covidPrecautionOtherContainer.querySelector(
        ".covid-precaution-other-field-container",
      );

      checkbox.addEventListener("change", () => {
        label.classList.toggle("d-none", checkbox.checked);
        field.classList.toggle("d-none", !checkbox.checked);

        if (!checkbox.checked) {
          field.querySelector('input[type="text"]').value = "";
        }
      });
    }

    const attachmentInputContainer = container.querySelector(
      ".program-attachment-field",
    );
    const attachmentInput = container.querySelector('input[type="file"]');
    const downloadProgramLink = container.querySelector(".download-program-link");
    const removeContainer = container.querySelector(".program-attachment-remove-field");
    const removeField = container.querySelector(".remove-program-input");
    if (attachmentInput) {
      attachmentInput.addEventListener("change", (event) => {
        const { files } = event.currentTarget;
        if (files && files[0]) {
          removeField.value = null;
          removeContainer.classList.remove("d-none");
        }
      });
    }

    const programTextCTA = container.querySelector(".program-text-cta");
    const programTextContainer = container.querySelector(".program-text-container");
    if (programTextCTA && programTextContainer) {
      programTextCTA.addEventListener("click", () => {
        programTextCTA.classList.add("d-none");
        programTextContainer.classList.remove("d-none");
      });

      const programTextarea = programTextContainer.querySelector("textarea");

      // NOTE: We don't want to import editor here because we don't want any chunks
      // shared between the editor and memorialsPrivate entrypoints. Additionally, we
      // shouldn't use dependOn either as any editor would then need to be loaded
      // anywhere we load memorialsPrivate.
      //
      // So, instead, we just trust that the editor bundle was loaded and is available
      // in window.eljs...
      window.eljs.editor.initEditor({
        editor: {
          customButtons: ["h2", "h3"],
          inputId: programTextarea.id,
          inputContainerSelector: ".program-text-container",
          templates: null,
          templatesContainerSelector: null,
        },
        userMention: props.userMention,
      });
    }

    const speakersContainer = container.querySelector(".participants-input");
    if (speakersContainer) {
      const participants = speakersContainer.querySelector("select");
      const participantsJson = speakersContainer.querySelector('input[type="hidden"]');

      const participantAutocompleteProps = {
        participantsInitialAutocompleteData:
          props.participantsInitialAutocompleteDataByEvent[container.dataset.uuid],
        participantsInputIds: {
          participants: participants.id,
          participantsJson: participantsJson.id,
        },
        userMention: props.userMention,
      };

      flushParticipantData = initParticipantAutocomplete(participantAutocompleteProps);
    }

    if (removeContainer) {
      const removeLink = removeContainer.querySelector(".remove-program-attachment");
      removeLink.addEventListener("click", () => {
        if (attachmentInput) {
          attachmentInput.value = null;
        }

        if (removeField) {
          removeField.value = true;
        }

        if (downloadProgramLink) {
          downloadProgramLink.classList.add("d-none");
        }

        removeContainer.classList.add("d-none");
        attachmentInputContainer.classList.remove("d-none");
      });
    }
  }

  return flushParticipantData;
};

/**
 * Expand the RSVPs container
 *
 * @param {HTMLElement} triggeringElement - the element triggering the showing of RSVPs container.
 * @param {boolean} scrollToDetails - if true, scroll to the RSVP details.
 */
const expandRSVPs = (triggeringElement, scrollToDetails = false) => {
  const rsvpsContainer =
    triggeringElement.parentElement.parentElement.querySelector(".rsvps-details");
  triggeringElement.classList.add("d-none");
  rsvpsContainer.classList.remove("d-none");

  if (scrollToDetails) {
    scrollInMemorialPrivateContentContainer(rsvpsContainer, -340);
  }
};

/**
 * Initialize the edit events page. Formset management, initializing copious features
 * on each form, and probably more.
 *
 * @param {object} props - the page props
 * @param {Array<Function>} deleteEventCallbacks - an array of callback functions to
 *     call when an event is deleted
 */
export function initEditEvents(props, deleteEventCallbacks) {
  // Placeholders need to be initialized for event state note fields
  const placeholderEventsStateNotHappening = `Example: ${props.memorialFirstName} did not want a funeral service, but we appreciate all the love and support and encourage everyone to share their favorite memories of ${props.memorialFirstName} on this memorial website.`;
  const placeholderEventsStatePrivate = `Example: ${props.memorialFirstName} requested a small service with immediate family only, but we appreciate everyone's love and support and encourage everyone to share their favorite memories of ${props.memorialFirstName} on this memorial website.`;
  const changeTimezoneContainer = document.querySelector(".change-timezone-container");
  const bottomSubmitRow = document.querySelector(".bottom-submit-row");
  const topSubmitRow = document.querySelector(".top-submit-row");

  if (props.eventsState === props.eventsStatePublic) {
    setEventsContainerInputsDisabledState(false);
  } else {
    setEventsContainerInputsDisabledState(true);
  }

  const nonPublicEventStateReasonField = document.querySelector(
    ".events-state-non-public .other-reason",
  );
  let eventsNote = "";
  if (nonPublicEventStateReasonField) {
    if (props.eventsState === props.eventsStatePrivate) {
      nonPublicEventStateReasonField.setAttribute(
        "placeholder",
        placeholderEventsStatePrivate,
      );
    } else if (props.eventsState === props.eventsStateNotHappening) {
      nonPublicEventStateReasonField.setAttribute(
        "placeholder",
        placeholderEventsStateNotHappening,
      );
    } else if (props.eventsState === props.eventsStatePending) {
      nonPublicEventStateReasonField.setAttribute(
        "placeholder",
        placeholderEventsStatePending,
      );
    }

    // we need to preserve the event state note toggles, so let's keep that here
    eventsNote = nonPublicEventStateReasonField.value;
  }

  const privateEventStreamCheckbox = document.querySelector(
    '.private-event-stream input[type="checkbox"]',
  );
  const streamEntryField = document.querySelector(
    ".private-event-stream .stream-entry",
  );
  if (privateEventStreamCheckbox && streamEntryField) {
    privateEventStreamCheckbox.addEventListener("change", (event) => {
      streamEntryField.classList.toggle("d-none", !event.target.checked);
    });
  }

  const privateEventZoomCheckbox = document.querySelector(
    '.private-event-zoom input[type="checkbox"]',
  );
  const privateEventZoomDataContainer = document.querySelector(
    ".private-event-zoom .event-zoom-data-container",
  );

  if (privateEventZoomCheckbox && privateEventZoomDataContainer) {
    privateEventZoomCheckbox.addEventListener("change", (event) => {
      privateEventZoomDataContainer.classList.toggle("d-none", !event.target.checked);
    });
  }

  document
    .querySelectorAll('.event-state-selection-wrapper input[type="radio"]')
    .forEach((stateRadio) => {
      stateRadio.addEventListener("change", (event) => {
        const selectedRadio = event.target;

        // when toggling between radio buttons, we want to display a warning that events
        // related to this memorial will be deleted if moving from a public event state with
        // events to a non public or pending state
        if (
          props.eventsState === props.eventsStatePublic &&
          selectedRadio.value !== `${props.eventsStatePublic}`
        ) {
          document.querySelector(".event-delete-warning").classList.remove("d-none");
        }

        setEventsContainerInputsDisabledState(
          selectedRadio.value !== `${props.eventsStatePublic}`,
        );

        document
          .querySelectorAll(
            ".events-state-non-public .private-event-stream, .events-state-non-public .private-event-zoom",
          )
          .forEach((streamOption) => {
            streamOption.classList.toggle(
              "d-none",
              selectedRadio.value !== `${props.eventsStatePrivate}`,
            );
          });

        const otherReason = document.querySelector(
          ".events-state-non-public .other-reason",
        );
        if (selectedRadio.value === `${props.eventsStatePublic}`) {
          // if we are toggling to make the memorial event state public, we should
          // toggle the appropriate cards on and off and hide the event deletion warning.
          document
            .querySelectorAll(
              ".events-state-non-public, .events-state-pending, .events-state-no-info, .event-delete-warning",
            )
            .forEach((toHide) => {
              toHide.classList.add("d-none");
            });
          document
            .querySelectorAll(".edit-events-containers, .add-event-container")
            .forEach((toShow) => {
              toShow.classList.remove("d-none");
            });

          if (topSubmitRow) {
            topSubmitRow.classList.add("d-none");
          }

          if (bottomSubmitRow) {
            bottomSubmitRow.classList.remove("d-none");
          }

          const totalFormCount = document.querySelectorAll(
            ".edit-events-containers .edit-events-container",
          ).length;
          document
            .querySelectorAll(".remove-event")
            .forEach((removeButton) =>
              removeButton.classList.toggle("d-none", totalFormCount <= 1),
            );

          if (!props.hideTimezone) {
            changeTimezoneContainer.classList.remove("d-none");
          }
        } else if (
          [`${props.eventsStatePrivate}`, `${props.eventsStateNotHappening}`].includes(
            selectedRadio.value,
          )
        ) {
          if (selectedRadio.value === `${props.eventsStatePrivate}`) {
            otherReason.setAttribute("placeholder", placeholderEventsStatePrivate);
            otherReason.value =
              props.eventsState === props.eventsStatePrivate && eventsNote
                ? eventsNote
                : "";
          } else {
            otherReason.setAttribute("placeholder", placeholderEventsStateNotHappening);
            otherReason.value =
              props.eventsState === props.eventsStateNotHappening && eventsNote
                ? eventsNote
                : "";
          }

          document
            .querySelectorAll(
              ".edit-events-containers, .add-event-container, .events-state-pending, .events-state-no-info",
            )
            .forEach((toHide) => toHide.classList.add("d-none"));
          document.querySelector(".events-state-non-public").classList.remove("d-none");

          if (topSubmitRow) {
            topSubmitRow.classList.remove("d-none");
          }

          if (bottomSubmitRow) {
            bottomSubmitRow.classList.add("d-none");
          }

          if (!props.hideTimezone) {
            changeTimezoneContainer.classList.add("d-none");
          }
        } else if (selectedRadio.value === `${props.eventsStatePending}`) {
          otherReason.setAttribute("placeholder", placeholderEventsStatePending);
          otherReason.value =
            props.eventsState === props.eventsStatePending && eventsNote
              ? eventsNote
              : "";

          // toggle the appropriate containers for the pending state when clicking on the pending
          // radio button
          document
            .querySelectorAll(
              ".edit-events-containers, .add-event-container, .events-state-no-info, .private-event-stream, .private-event-zoom",
            )
            .forEach((toHide) => toHide.classList.add("d-none"));
          document
            .querySelectorAll(".events-state-pending, .events-state-non-public")
            .forEach((toShow) => toShow.classList.remove("d-none"));

          if (topSubmitRow) {
            topSubmitRow.classList.remove("d-none");
          }

          if (bottomSubmitRow) {
            bottomSubmitRow.classList.add("d-none");
          }

          if (!props.hideTimezone) {
            changeTimezoneContainer.classList.add("d-none");
          }
        } else if (selectedRadio.value === `${props.eventsStateNoInfo}`) {
          // toggle the appropriate containers for the "no information" when clicking on the pending
          // radio button
          document
            .querySelectorAll(
              ".edit-events-containers, .add-event-container, .events-state-non-public, .events-state-pending",
            )
            .forEach((toHide) => toHide.classList.add("d-none"));
          document.querySelector(".events-state-no-info").classList.add("d-none");

          if (topSubmitRow) {
            topSubmitRow.classList.remove("d-none");
          }

          if (bottomSubmitRow) {
            bottomSubmitRow.classList.add("d-none");
          }

          if (!props.hideTimezone) {
            changeTimezoneContainer.classList.add("d-none");
          }
        }
      });
    });

  document.querySelectorAll(".datetime-input").forEach((datetimeInputElement) => {
    initDateTimePicker({ element: datetimeInputElement });

    // Listen for clearing of the input field. If this is the case then we want to make
    // the hidden DateTimePicker input field have a cleared value in case we want to
    // edit an event and remove an end date.
    const pickerInput = datetimeInputElement.nextElementSibling;
    pickerInput.addEventListener("input", () => {
      if (!pickerInput.value) {
        datetimeInputElement.value = "";
      }
    });
  });

  const deleteCallbacks = Array.from(
    document.querySelectorAll(".edit-events-container"),
  )
    .map((container) => {
      initStreamingOptions(container);
      initRSVPLimitBehavior(container);
      return initEventExtraData(container, props);
    })
    .filter((callback) => callback);
  deleteEventCallbacks = [...deleteEventCallbacks, ...deleteCallbacks];

  document.querySelectorAll(".remove-event a").forEach((removeEventAnchor) => {
    removeEventAnchor.addEventListener("click", (event) => {
      const idField = removeEventAnchor
        .closest(".edit-events-container")
        .querySelector('input[type="hidden"]');
      if (idField && idField.value) {
        if (window.confirm("Are you sure you want to delete this event?")) {
          if (deleteEventCallbacks.length >= 1) {
            deleteEventCallbacks.forEach((cb) => cb());
          }

          const form = removeEventAnchor.closest("form");
          const toDelete = event.currentTarget.previousElementSibling;
          toDelete.checked = true;
          form.setAttribute("data-dirty-track-is-submitting", "1");
          form.action = "?dr=1&edit-events=1";
          form.submit();
        }
      }
    });
  });

  const showRSVPs = document.querySelectorAll(".show-rsvps");
  showRSVPs.forEach((element) => {
    element.addEventListener("click", () => {
      expandRSVPs(element);
    });
  });

  if (props.attendeeFormsetProps) {
    Object.keys(props.attendeeFormsetProps).forEach((eventUUID) => {
      const formsetProps = props.attendeeFormsetProps[eventUUID];
      initAttendeeFormset(eventUUID, formsetProps);
    });
  }
}

// NOTE: This function used to be run from a callback function from the Google Maps API
// before we switched to async/await; hence the name.
export function initEditEventsOnLoad(props, deleteEventCallbacks) {
  const inputElements = document.getElementsByClassName("location-autocomplete");
  let { totalFormCount } = props;
  const totalFormCountInput = document.getElementById("id_form-TOTAL_FORMS");
  totalFormCountInput.value = totalFormCount;
  const bottomSubmitRow = document.querySelector(".bottom-submit-row");

  for (let i = 0; i < inputElements.length; i += 1) {
    addEventLocationAutocomplete(
      inputElements[i],
      document.getElementById(`id_form-${i}-location_metadata`),
    );
    addEventTypeOtherFocus(i);
  }

  const resequenceForms = (removedIdFormField) => {
    // Given the ID form field from the form we've removed, iterate over remaining forms
    // to ensure their index sequence in the formset is coalesced.
    const formIdRegex = /form-([0-9]+)-[A-Za-z_]+/;
    const removedIndex = parseInt(
      removedIdFormField.getAttribute("name").match(formIdRegex)[1],
      10,
    );
    if (removedIndex < parseInt(totalFormCountInput.value, 10)) {
      document.querySelectorAll(".edit-events-container").forEach((_container) => {
        const containerIndex = parseInt(
          _container
            .querySelector('input[type="hidden"]')
            .getAttribute("name")
            .match(formIdRegex)[1],
          10,
        );
        if (containerIndex > removedIndex) {
          _container.querySelectorAll("input").forEach((input) => {
            if (input.name.includes(`-${containerIndex}-`)) {
              input.setAttribute(
                "name",
                input
                  .getAttribute("name")
                  .replace(`-${containerIndex}-`, `-${containerIndex - 1}-`),
              );
              input.setAttribute(
                "id",
                input
                  .getAttribute("id")
                  .replace(`-${containerIndex}-`, `-${containerIndex - 1}-`),
              );
            }
          });

          _container.querySelectorAll("label").forEach((label) => {
            if (label.getAttribute("for").includes(`-${containerIndex}-`)) {
              label.setAttribute(
                "for",
                label
                  .getAttribute("for")
                  .replace(`-${containerIndex}-`, `-${containerIndex - 1}-`),
              );
            }
          });
        }
      });
    }

    document
      .querySelectorAll(".remove-event")
      .forEach((removeButton) =>
        removeButton.classList.toggle("d-none", totalFormCount <= 1),
      );
  };

  // For every form _currently_ loaded, if there's no instance ID, we need to handle
  // the remove event appropriately, which includes
  document.querySelectorAll(".edit-events-container").forEach((container) => {
    const idFormField = container.querySelector('input[type="hidden"]');
    if (idFormField && !idFormField.value) {
      container.querySelectorAll(".remove-event a").forEach((removeAnchor) =>
        removeAnchor.addEventListener("click", () => {
          if (deleteEventCallbacks.length >= 1) {
            deleteEventCallbacks.forEach((cb) => cb());
          }
          removeAnchor.closest(".edit-events-container").remove();
          totalFormCount -= 1;
          totalFormCountInput.value = totalFormCount;
          resequenceForms(idFormField);
        }),
      );
    }

    attachEventTypeListener(container);
  });

  document.querySelectorAll(".add-event-button").forEach((addEventButton) =>
    addEventButton.addEventListener("click", () => {
      totalFormCount += 1;
      const newForm = props.emptyForm.replace(/__prefix__/g, totalFormCount - 1);
      const formElements = [...generateElements(newForm)];
      const eventFormsContainer = document.querySelector(".edit-events-containers");

      eventFormsContainer.append(...formElements);

      const eventForm = formElements[0];

      document
        .querySelectorAll(".remove-event")
        .forEach((removeButton) =>
          removeButton.classList.toggle("d-none", totalFormCount <= 1),
        );

      totalFormCountInput.value = totalFormCount;

      const eventExtraDataCallback = initEventExtraData(
        eventFormsContainer.querySelector(".edit-events-container:last-of-type"),
        props,
      );
      if (typeof eventExtraDataCallback !== "undefined") {
        deleteEventCallbacks.push(eventExtraDataCallback);
      }

      eventForm.querySelectorAll(".remove-event a").forEach((removeButton) =>
        removeButton.addEventListener("click", () => {
          if (deleteEventCallbacks.length >= 1) {
            deleteEventCallbacks.forEach((cb) => cb());
          }

          const container = removeButton.closest(".edit-events-container");
          attachEventTypeListener(container);
          const idFormField = container.querySelector('input[type="hidden"]');

          if (idFormField && !idFormField.value) {
            container.remove();
            totalFormCount -= 1;
            totalFormCountInput.value = totalFormCount;
            resequenceForms(idFormField);
          }
        }),
      );

      const elementMetadataId = document.getElementById(
        `id_form-${totalFormCount - 1}-location_metadata`,
      );
      addEventLocationAutocomplete(
        inputElements[totalFormCount - 1],
        elementMetadataId,
        true,
      );
      initDateTimePicker({ element: `#id_form-${totalFormCount - 1}-start` });
      initDateTimePicker({ element: `#id_form-${totalFormCount - 1}-end` });
      initRSVPLimitBehavior(eventForm);
      initStreamingOptions(eventForm);
      attachEventTypeListener(eventForm);
      addEventTypeOtherFocus(totalFormCount - 1);

      if (bottomSubmitRow) {
        bottomSubmitRow.classList.remove("d-none");
      }
    }),
  );

  const searchParams = new URLSearchParams(window.location.search);
  const expandEventRSVPS = searchParams.get("expand-rsvp-details");
  if (expandEventRSVPS) {
    const eventContainer = document.querySelector(
      `.edit-events-container[data-uuid='${expandEventRSVPS}']`,
    );
    const expandElement = eventContainer.querySelector(".show-rsvps");
    if (expandElement) {
      expandRSVPs(expandElement, true);
    }
  }
}

import Cleave from "cleave.js";
import Collapse from "bootstrap/js/dist/collapse";

import initUGCTemplates from "./UGCTemplates";
import { initPopovers } from "../core/utils";

export default function initFundraiserForm(props, onCleaveInit = null) {
  // Initialize Cleave and currency selection UX
  let cleave;

  props.personalFundraiserForms.forEach((personalFundraiserForm) => {
    let fundraisingGoalContainer = document.querySelector(
      `#${personalFundraiserForm.fields.fundraisingGoal}`,
    );
    if (fundraisingGoalContainer) {
      fundraisingGoalContainer = fundraisingGoalContainer.closest(
        ".fundraising-goal-container",
      );
    }
    const fundraisingGoalDisabled = document.querySelector(
      `#${personalFundraiserForm.fields.fundraisingGoalDisabled}`,
    );

    if (fundraisingGoalContainer && fundraisingGoalDisabled) {
      const goalAmountContainer = fundraisingGoalContainer.querySelector(
        ".goal-amount-container",
      );
      const currencyWithoutGoalContainer = fundraisingGoalContainer.querySelector(
        ".currency-no-goal-container",
      );
      const goalContainer = fundraisingGoalContainer.querySelector(".goal-container");
      const goalAmountCollapse = Collapse.getOrCreateInstance(goalAmountContainer, {
        toggle: false,
      });

      let currencyWithoutGoalCollapse;
      if (currencyWithoutGoalContainer) {
        currencyWithoutGoalCollapse = Collapse.getOrCreateInstance(
          currencyWithoutGoalContainer,
          { toggle: false },
        );
      }

      fundraisingGoalDisabled.addEventListener("change", () => {
        // swap goal container with goalless container
        goalAmountCollapse.toggle();
        if (currencyWithoutGoalContainer) {
          currencyWithoutGoalCollapse.toggle();
        }

        if (fundraisingGoalDisabled.checked) {
          goalContainer.parentElement.querySelector("input").value = "";
        }
      });
    }

    const fundraisingGoalInput = document.getElementById(
      personalFundraiserForm.fields.fundraisingGoal,
    );
    if (fundraisingGoalInput) {
      cleave = new Cleave(fundraisingGoalInput, {
        numeral: true,
        numeralPositiveOnly: true,
      });

      if (onCleaveInit) {
        onCleaveInit(cleave);
      }

      fundraisingGoalInput.closest("form").addEventListener("submit", () => {
        fundraisingGoalInput.value = cleave.getRawValue();
      });
    }

    const currencySelect = document.getElementById(
      personalFundraiserForm.fields.currencySelect,
    );
    const currencyNote = document.querySelector(".intl-currency-warning");

    if (currencySelect && currencyNote) {
      let previousCurrency = currencySelect.selectedOptions[0].value;
      currencySelect.addEventListener("change", () => {
        const selectedCurrency = currencySelect.selectedOptions[0].value;
        currencyNote.classList.toggle("d-none", selectedCurrency === "usd");

        if (props.exchangeRates && previousCurrency !== selectedCurrency) {
          const currentValue = parseFloat(cleave.getRawValue()).toFixed(2);
          cleave.setRawValue(
            Math.round(
              currentValue * props.exchangeRates[previousCurrency][selectedCurrency],
            ),
          );
          previousCurrency = selectedCurrency;
        }
      });
    }
  });

  // Initialize cause story template UX
  const causeStoryField = document.querySelector(
    `#${props.causeStoryForm.fields.causeStory}`,
  );
  const causeStoryTemplatesContainer = document.querySelector(
    ".cause-story-templates-container",
  );

  initUGCTemplates(
    causeStoryTemplatesContainer,
    causeStoryField,
    props.causeStoryTemplates,
    () => props.shouldShowCauseStoryTemplates === true,
    false,
    null,
    false,
  );

  // NOTE: The naming here is a misdirection. This is used for paste event detection.
  const previousCauseStoryField = document.querySelector(
    `#${props.causeStoryForm.fields.previousCauseStory}`,
  );
  if (previousCauseStoryField) {
    causeStoryField.addEventListener("paste", (event) => {
      previousCauseStoryField.value = (
        event.clipboardData || window.clipboardData
      ).getData("text");
    });
  }

  props.charityFundraiserForms.forEach((charityFundraiserForm) => {
    const charityvestDesignationSelector = document.querySelector(
      `#${charityFundraiserForm.fields.charityvestDesignation}`,
    );
    if (charityvestDesignationSelector) {
      const parentCard = charityvestDesignationSelector.closest(".body-card");
      const otherContainer = parentCard.querySelector(".charityvest-designation-other");

      charityvestDesignationSelector.addEventListener("change", () => {
        otherContainer.classList.toggle(
          "d-none",
          !(charityvestDesignationSelector.value === "other_purpose"),
        );
      });
    }
  });

  const fundraisingManagerSelect = document.getElementById(
    "change-fundraising-manager",
  );
  if (fundraisingManagerSelect) {
    fundraisingManagerSelect.addEventListener("change", () => {
      const selected = fundraisingManagerSelect.selectedOptions[0];
      if (selected && !selected.dataset.isOriginal) {
        fundraisingManagerSelect.setAttribute("name", "_change_fundraising_manager");
        const form = fundraisingManagerSelect.closest("form");
        form.dataset.forceSubmit = "true";
        form.submit();
      }
    });
  }

  initPopovers();
}

import Modal from "bootstrap/js/dist/modal";

import initBusinessAutocomplete from "../../components/BusinessAutocomplete";
import { scrollToElement } from "../../core/utils";
import initExpandableCollapses from "../../components/ExpandableCollapse";

/**
 * Initialize the Charity Search handling for the page.
 *
 * @param {object} props - the page props, including Autocomplete props for
 *     Charity search
 */
function initCharitySearchListener(props) {
  initBusinessAutocomplete(props.charitySearch);

  const charityBusinessIdentifierInput = document.getElementById(
    props.charitySearch.businessIdentifierInputId,
  );
  const selectCharityBtn = document.querySelector(".btn-submit-typed-charity");
  const modalElement = document.querySelector(".charity-search-helper-modal");

  charityBusinessIdentifierInput.tomselect.on("change", (newValue) => {
    if (newValue) {
      const newOption = charityBusinessIdentifierInput.tomselect.options[newValue];
      if (newOption.created) {
        const causeNameInput = modalElement.querySelector(".cause-name-input");
        causeNameInput.value = newOption.id;

        Modal.getOrCreateInstance(modalElement).show();
      }

      selectCharityBtn.classList.remove("d-none");
    }
  });
}

/**
 * Initialize the cause chooser grid.
 */
function initChooseCauseGrid() {
  const causeCards = document.querySelectorAll(".cause-card");
  const causeExpanders = document.querySelectorAll(".cause-card .cause-expander");
  const rectangles = document.querySelectorAll(".rectangle");

  // open and close cause-card when click on cause-card
  causeExpanders.forEach((causeExpander) => {
    causeExpander.addEventListener("click", () => {
      const thisCard = causeExpander.closest(".cause-card");
      const thisCauseCardExpander = thisCard.querySelector(".cause-card-expander");
      const thisRectangle = thisCard.querySelector(".rectangle");
      const isAlreadyExpanded = thisCard.classList.contains("is-expanded");

      rectangles.forEach((rectangle) => {
        rectangle.classList.toggle(
          "d-none",
          thisRectangle !== rectangle || isAlreadyExpanded,
        );
      });

      causeCards.forEach((card) => {
        card.classList.toggle(
          "is-expanded",
          card === thisCard ? !isAlreadyExpanded : false,
        );
        card.classList.toggle(
          "is-collapsed",
          card === thisCard ? isAlreadyExpanded : true,
        );
        card.style.marginBottom =
          card === thisCard && !isAlreadyExpanded
            ? `${thisCauseCardExpander.offsetHeight + 20}px`
            : "20px";
      });

      scrollToElement(
        thisCard,
        -80,
        false,
        window.isOnMobile
          ? document.scrollingElement
          : thisCard.closest(".private-content-container") || document.scrollingElement,
      );
    });
  });
}

/**
 * Init the expandable charity overview sections with `initExpandableCollapse`
 */
function initExpandableCharityOverview() {
  // This initializes the Read More/Read Less functionality of already opened charity
  // overviews.
  function adjustCurrentCardBottomMargin(event) {
    const currentExpander = event.target.closest(".cause-card-expander");
    const currentCard = currentExpander.closest(".cause-card");

    currentCard.style.marginBottom = `${currentExpander.offsetHeight + 20}px`;
  }

  initExpandableCollapses();

  document.querySelectorAll(".cause-description").forEach((descriptionExpander) => {
    descriptionExpander.addEventListener(
      "shown.bs.collapse",
      adjustCurrentCardBottomMargin,
    );
    descriptionExpander.addEventListener(
      "hidden.bs.collapse",
      adjustCurrentCardBottomMargin,
    );
  });
}

/**
 * Initialize the choose-cause experience page (onboarding and private)
 *
 * @param {object} props - the page props.
 */
export default function initMemorialPrivateChooseCause(props) {
  initCharitySearchListener(props);
  initChooseCauseGrid();
  initExpandableCharityOverview();
}

import flatpickr from "flatpickr";

export default function initDateTimePicker(props) {
  flatpickr(props.element, {
    allowInput: true,
    altFormat: "l, F j, Y h:i K",
    altInput: true,
    enableTime: true,
    onChange(selectedDates, dateStr, instance) {
      instance._previousInput = instance._input.value;
    },
    onClose(selectedDates, dateStr, instance) {
      if (
        instance.config.allowInput &&
        instance._input.value &&
        instance._input.value !== instance._previousInput
      ) {
        instance.setDate(instance._input.value, true, instance.config.altFormat);
      }
    },
    onReady(selectedDates, dateStr, instance) {
      instance._previousInput = instance._input.value;
    },
  });
}

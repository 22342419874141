import Modal from "bootstrap/js/dist/modal";

import requests from "../core/requests";

export default function initSubscriberNotificationsModal(props) {
  const notificationsModalElement = document.querySelector(
    ".memorial-notifications-modal",
  );
  const notificationsModal = Modal.getOrCreateInstance(notificationsModalElement);
  const notifyBtn = notificationsModalElement.querySelector(".btn-secondary");

  notificationsModal.show();
  notifyBtn.addEventListener("click", async () => {
    notificationsModal.hide();

    try {
      const response = await requests.post(props.sendNotificationsEndpoint);

      if (!response.ok) {
        throw new Error();
      }
    } catch {
      window.Rollbar.error("Error sending memorial notifications to subscribers.");
    }
  });
}

import Modal from "bootstrap/js/dist/modal";

import initSubscriberNotificationsModal from "../../components/SubscriberNotificationsModal";
import requests, { ResponseError } from "../../core/requests";

export default function initInvites(props) {
  if (props.showNotificationsPrompt) {
    initSubscriberNotificationsModal(props);
  }

  const openImportContactsButton = document.querySelector(
    ".open-import-contacts-button",
  );
  const loadingOverlay = document.querySelector(".modal-body-overlay.loading");
  const connectPromptOverlay = document.querySelector(
    ".modal-body-overlay.connect-prompt",
  );
  const errorOverlay = document.querySelector(".modal-body-overlay.error");
  const contactSearch = document.querySelector(".contact-search");
  const contactListGroup = document.querySelector(".contact-list-group");
  const importContactsButton = document.querySelector(".import-contacts-button");
  const recipientsField = document.querySelector(".invite-recipients-field textarea");
  const selectAllContactsButton = document.querySelector(".select-all-contacts-button");

  if (contactSearch) {
    contactSearch.addEventListener("input", () => {
      const term = contactSearch.value.toLowerCase();
      document.querySelector(".clear-contact-search").classList.toggle("d-none", !term);
      contactListGroup.querySelectorAll(".list-group-item").forEach((element) => {
        const searchTarget = (
          element.getAttribute("data-name") + element.getAttribute("data-email")
        ).toLowerCase();
        const show = !term || searchTarget.includes(term);
        element.classList.toggle("d-none", !show);
      });
    });

    document.querySelector(".clear-contact-search").addEventListener("click", (e) => {
      e.currentTarget.classList.add("d-none");
      contactSearch.value = "";
      contactListGroup.querySelectorAll(".list-group-item").forEach((element) => {
        element.classList.remove("d-none");
      });
    });

    const loadContacts = async () => {
      const alreadySelected = (recipientsField.value || "")
        .toLowerCase()
        .split(",")
        .map((em) => em.trim());

      while (contactListGroup.firstChild) {
        contactListGroup.removeChild(contactListGroup.firstChild);
      }

      errorOverlay.classList.add("d-none");
      loadingOverlay.classList.remove("d-none");
      importContactsButton.classList.add("disabled");

      let responseData;

      try {
        const response = await requests.get(props.getContactsUrl);

        if (!response.ok) {
          throw new ResponseError(response);
        }

        responseData = await response.json();
      } catch (error) {
        window.Rollbar.warn("Error getting Google contacts", error);
        loadingOverlay.classList.add("d-none");
        errorOverlay.classList.remove("d-none");
      }

      if (responseData) {
        if (responseData.contacts.length === 0) {
          const element = document.createElement("div");
          element.setAttribute("class", "list-group-item list-group-item-disabled");
          element.innerText = "You have no contacts attached to your Google account.";
        }

        responseData.contacts.forEach((contact) => {
          const element = document.createElement("a");
          element.setAttribute("href", "javascript:;");
          element.setAttribute(
            "class",
            "list-group-item list-group-item-action contact-list-group-item",
          );
          element.setAttribute("data-email", contact.email);
          element.setAttribute("data-name", contact.name);
          element.innerHTML = `<div class="checkbox"></div><div class="contact"><h5>${contact.name}</h5><span>${contact.email}</span></div>`;
          element.addEventListener("click", () => {
            element.classList.toggle("active");

            if (
              document.querySelectorAll(".contact-list-group-item").length ===
              document.querySelectorAll(".contact-list-group-item.active").length
            ) {
              selectAllContactsButton.innerText = "Deselect All";
              selectAllContactsButton.setAttribute("data-all-selected", "true");
            } else {
              selectAllContactsButton.innerText = "Select All";
              selectAllContactsButton.removeAttribute("data-all-selected");
            }
          });

          if (alreadySelected.includes(contact.email.toLowerCase())) {
            element.classList.add("active");
          }

          contactListGroup.appendChild(element);
        });

        selectAllContactsButton.classList.remove("d-none");
        loadingOverlay.classList.add("d-none");
        importContactsButton.classList.remove("disabled");
      }
    };

    errorOverlay.querySelector(".btn").addEventListener("click", (evt) => {
      if (!errorOverlay.getAttribute("data-has-retried")) {
        errorOverlay.setAttribute("data-has-retried", "true");
        evt.currentTarget.innerText = "Error! Click to reload and reconnect to Google.";
        loadContacts();
      } else {
        window.location = `${window.location.pathname}?clear-gauth=1`;
      }
    });

    const importGoogleContactsModalElement = document.querySelector(
      "#import-google-contacts-modal",
    );
    if (props.hasCredentials) {
      importGoogleContactsModalElement.addEventListener("show.bs.modal", async () => {
        await loadContacts();
      });
    } else {
      const initializerButton = document.querySelector(".oauth-initializer");
      initializerButton.addEventListener("click", () => {
        const alternate = document.querySelector(
          initializerButton.getAttribute("data-alternate"),
        );
        if (alternate && alternate.classList.contains("invisible")) {
          setTimeout(() => {
            alternate.classList.remove("invisible");
          }, 2000);
        }

        const url = initializerButton.getAttribute("data-oauth-url");
        const w = 400;
        const h = 680;
        const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
        const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
        window.open(
          url,
          "OAuthWindow",
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
        );
      });
      window.oAuthComplete = async () => {
        if (connectPromptOverlay && loadingOverlay) {
          connectPromptOverlay.classList.add("d-none");
          loadingOverlay.classList.remove("d-none");
        }
        await loadContacts();
      };
    }

    if (importContactsButton) {
      importContactsButton.addEventListener("click", () => {
        openImportContactsButton.innerText = "Add more imported contacts";
        if (!importContactsButton.classList.contains("disabled")) {
          const alreadySelected = (recipientsField.value || "")
            .toLowerCase()
            .split(",")
            .map((em) => em.trim());
          const selectedEmails = Array.from(
            contactListGroup.querySelectorAll(".list-group-item.active"),
          ).map((item) => item.getAttribute("data-email").toLowerCase());
          const deselectedEmails = Array.from(
            contactListGroup.querySelectorAll(".list-group-item"),
          )
            .map((item) => item.getAttribute("data-email").toLowerCase())
            .filter((email) => !selectedEmails.includes(email));
          const newSelectedEmails = selectedEmails.filter(
            (email) => !alreadySelected.includes(email.toLowerCase()),
          );
          const newRecipients = alreadySelected
            .filter((email) => !deselectedEmails.includes(email.toLowerCase()))
            .concat(newSelectedEmails)
            .filter((email) => email);

          recipientsField.value = `${newRecipients.join(", ")}`;

          Modal.getOrCreateInstance(importGoogleContactsModalElement).hide();
        }
      });
    }

    if (selectAllContactsButton) {
      selectAllContactsButton.addEventListener("click", () => {
        if (selectAllContactsButton.getAttribute("data-all-selected")) {
          document.querySelectorAll(".contact-list-group-item").forEach((el) => {
            el.classList.remove("active");
          });
        } else {
          document.querySelectorAll(".contact-list-group-item").forEach((el) => {
            el.classList.add("active");
          });
        }

        if (
          document.querySelectorAll(".contact-list-group-item").length ===
          document.querySelectorAll(".contact-list-group-item.active").length
        ) {
          selectAllContactsButton.innerText = "Deselect All";
          selectAllContactsButton.setAttribute("data-all-selected", "true");
        } else {
          selectAllContactsButton.innerText = "Select All";
          selectAllContactsButton.removeAttribute("data-all-selected");
        }
      });
    }
  }

  const inviteRecordsBodyCard = document.querySelector(".invite-records-body-card");
  if (inviteRecordsBodyCard) {
    const toggleButton = inviteRecordsBodyCard.querySelector(
      ".toggle-collapse-invite-records",
    );
    toggleButton.addEventListener("click", () => {
      inviteRecordsBodyCard.classList.toggle("collapsed");

      if (inviteRecordsBodyCard.classList.contains("collapsed")) {
        inviteRecordsBodyCard.style.height = null;
      } else {
        inviteRecordsBodyCard.style.height = `${inviteRecordsBodyCard.scrollHeight + 40}px`;
      }
    });
  }
}

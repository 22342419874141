import WordCounter from "../../components/WordCounter";
import AutoSaveTextField from "../../components/AutoSaveTextField";
import initPayableCheckout from "../../commerce/PayableCheckout";

export default function initPartnerObituaryOrderCreate(props) {
  const photoUrlField = document.getElementById(props.form.fields.photoUrl);
  const selectedPhoto = document.querySelector(".selected-photo");
  const photoSelectorChoices = document.querySelectorAll(
    ".photo-selector-choices > img",
  );

  if (photoUrlField && selectedPhoto && photoSelectorChoices.length) {
    photoSelectorChoices.forEach((img) => {
      img.addEventListener("click", () => {
        photoSelectorChoices.forEach((_img) => {
          _img.classList.remove("selected");
        });

        img.classList.add("selected");

        if (img.classList.contains("no-photo")) {
          photoUrlField.value = "";
          selectedPhoto.src = "";
          selectedPhoto.classList.add("d-none");
        } else {
          photoUrlField.value = img.src;
          selectedPhoto.src = img.src;
          selectedPhoto.classList.remove("d-none");
        }
      });
    });
  }

  const form = document.querySelector(".partner-obituary-order-create-form");
  let formDisabled = false;
  form.addEventListener("submit", (event) => {
    if (formDisabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  });

  const toggleFormEnabled = (forceEnabled) => {
    formDisabled = !forceEnabled;
    form.querySelectorAll('[type="submit"]').forEach((btn) => {
      btn.disabled = !forceEnabled;
      btn.classList.toggle("disabled", !forceEnabled);
    });
  };

  const wordCountRoot = document.querySelector(
    `.word-counter[data-word-count-target="${props.form.fields.obituary}"]`,
  );
  if (wordCountRoot) {
    const wordCounter = new WordCounter(wordCountRoot);
    wordCounter.assessCount();

    wordCountRoot.addEventListener("elwc:overage", () => {
      toggleFormEnabled(false);
    });
    wordCountRoot.addEventListener("elwc:compliance", () => {
      toggleFormEnabled(true);
    });
  }

  const obitField = document.getElementById(props.form.fields.obituary);
  if (obitField) {
    new AutoSaveTextField(obitField);
  }

  initPayableCheckout(props);
}

import Modal from "bootstrap/js/dist/modal";
import debounce from "lodash.debounce";
import Tribute from "tributejs";

import requests from "../core/requests";
import { animateCSS, fadeOut } from "../core/utils";

const MENU_ITEM_LIMIT = 8;

export function runInviteFormValidation(inviteForm) {
  const submitBtn = inviteForm.querySelector(".notify-user-mention-button");

  function isValid(el) {
    return el.checkValidity();
  }

  const firstNameField = inviteForm.querySelector("#user-mention-invite-first-name");
  const lastNameField = inviteForm.querySelector("#user-mention-invite-last-name");
  const emailField = inviteForm.querySelector("#user-mention-invite-email");

  if ([firstNameField, lastNameField, emailField].every(isValid)) {
    submitBtn.classList.remove("disabled");
  } else {
    submitBtn.classList.add("disabled");
  }
}

export class UserMentionWriteHandler {
  constructor(mentionableElement, memorialSlug, searchEndpoint) {
    this.form = mentionableElement.closest("form");
    this.mentionsInput = this.form.querySelector(".user-mentions-input");
    this.inviteForm = document.querySelector("#user-mention-invite-form");
    this.memorialSlug = memorialSlug;
    this.mentionModalElement = document.querySelector("#modal-user-mention");
    this.mentionModal = new Modal(this.mentionModalElement);
    this.mentionableElement = mentionableElement;
    this.processableMentions = new Set();
    this.searchEndpoint = searchEndpoint;
    this.currentUserSearch = "";
    this.inviteSuccessModal = document.querySelector(".mention-invite-success-modal");

    this.boundSetHiddenUserData = this.setUserQueryValue.bind(null, this);
    this.boundValidateForm = this.validateInviteForm.bind(this);
    this.boundAcceptUserData =
      this.addSubmittedUserDataToProcessableMentions.bind(this);
  }

  async showSuccessModal() {
    // TODO: Check this once we fix a bug preventing users from being invited
    this.inviteSuccessModal.classList.remove("d-none");
    await animateCSS(this.inviteSuccessModal, "fadeIn");
    fadeOut(this.inviteSuccessModal, 5000);
  }

  listenForUserMentionTrigger() {
    const tribute = new Tribute({
      allowSpaces: false,
      searchOpts: {
        skip: true,
      },
      // class added in the flyout menu for active item
      selectClass: "user-mention-highlight",

      // class added to the menu container
      containerClass: "tribute-container",

      // class added to each list item
      itemClass: "user-mention-option",

      // function called on select that returns the content to insert
      selectTemplate: (item) => {
        if (typeof item === "undefined") return null;

        if (item.original.userHash === "no-match-item") {
          this.mentionModal.show();
          this.setupListenersOnUserMentionInviteForm(item.original.userMentionName);
        } else if (item.original.userHash === "rate-limit-item") {
          // pass
        } else if (item.original.userHash === "match-error-item") {
          // pass
        } else {
          this.processableMentions.add(item.original);
        }

        return `@${item.original.userMentionName}`;
      },

      // template for displaying item in menu
      menuItemTemplate: (item) => {
        if (item.original.userHash === "no-match-item") {
          return `<a data-user-query="${item.original.userMentionName}" class="user-mention-invite-user" target="_blank">No match found - Click to notify person</a>`;
        }

        if (item.original.thumbnailUrl) {
          return `<span><img class="user-profile-photo" src="${item.original.thumbnailUrl}" alt="User profile photo"></img> ${item.original.userMentionName}</span>`;
        }

        return item.string;
      },

      // template for when no match is found (optional),
      // If no template is provided, menu is hidden.
      noMatchTemplate: null,

      // column to search against in the object (accepts function or string)
      lookup: "userMentionName",

      // column that contains the content to insert by default
      fillAttr: "userHash",

      // TODO: Figure out how to handle pagination with Tribute.js
      // REQUIRED: array of objects to match or a function that returns data
      values: debounce(
        async (text, cb) => {
          const noMatchItem = { userMentionName: text, userHash: "no-match-item" };
          const rateLimitItem = {
            userMentionName:
              "An error occurred. Please wait a few minutes and try again.",
            userHash: "rate-limited-item",
          };
          const searchErrorItem = {
            userMentionName: "An error occurred. Please try again.",
            userHash: "search-error-item",
          };
          try {
            const response = await requests.get(this.searchEndpoint, {
              search: text,
              "memorial-slug": this.memorialSlug,
            });

            if (!response.ok) {
              if (response.status === 429) {
                return cb([rateLimitItem]);
              }

              throw new Error();
            }

            const responseData = await response.json();
            const dropdownData = [...responseData.results];
            dropdownData.splice(MENU_ITEM_LIMIT - 1, 0, noMatchItem);

            return cb(dropdownData);
          } catch {
            return cb([searchErrorItem]);
          }
        },
        100,
        { maxWait: 250 },
      ),

      // specify whether a space is required before the trigger string
      requireLeadingSpace: true,

      // Limits the number of items in the menu
      menuItemLimit: MENU_ITEM_LIMIT,

      // specify the minimum number of characters that must be typed before menu appears
      menuShowMinLength: 3,
    });

    tribute.attach(this.mentionableElement);
  }

  setUserQueryValue(klass, event) {
    if (event.target.classList.contains("modal-open")) {
      const userQueryHiddenInput =
        klass.inviteForm.querySelector(".user-mention-query");
      userQueryHiddenInput.value = `@${klass.currentUserSearch}`;
    }
  }

  validateInviteForm() {
    runInviteFormValidation(this.inviteForm);
  }

  addSubmittedUserDataToProcessableMentions() {
    const userQueryHiddenInput = this.inviteForm.querySelector(".user-mention-query");

    const firstNameInput = this.inviteForm.querySelector(
      "#user-mention-invite-first-name",
    );
    const lastNameInput = this.inviteForm.querySelector(
      "#user-mention-invite-last-name",
    );
    const emailInput = this.inviteForm.querySelector("#user-mention-invite-email");

    const email = emailInput.value;
    const fullName = `${firstNameInput.value} ${lastNameInput.value}`;

    this.processableMentions.add({ userMentionName: fullName, email, disabled: true });
    if ("mediumEditorElement" in this.mentionableElement.dataset) {
      window.eljs.editor.replaceContent(
        this.mentionableElement,
        userQueryHiddenInput.value,
        `@${fullName}`,
      );
    } else {
      this.mentionableElement.value = this.mentionableElement.value.replace(
        userQueryHiddenInput.value,
        `@${fullName}`,
      );
    }

    this.mentionModal.hide();
    firstNameInput.value = "";
    lastNameInput.value = "";
    emailInput.value = "";

    this.showSuccessModal();
  }

  setupListenersOnUserMentionInviteForm(userQuery) {
    this.currentUserSearch = userQuery;

    document
      .querySelector("body")
      .addEventListener("click", this.boundSetHiddenUserData);
    this.inviteForm.addEventListener("input", this.boundValidateForm);
    this.inviteForm
      .querySelector(".notify-user-mention-button")
      .addEventListener("click", this.boundAcceptUserData);
  }

  addMentionsToFormOnSubmit() {
    if (this.mentionsInput) {
      this.form.addEventListener("submit", () => {
        if (!this.mentionableElement.offsetParent) {
          // This listener is not the one that should be listening;
          return;
        }

        const mentions = [...this.processableMentions];
        this.mentionsInput.setAttribute("value", JSON.stringify(mentions));
      });
    }
  }

  clearListenersOnUserMentionInviteModalClose() {
    this.mentionModalElement.addEventListener("hide.bs.modal", () => {
      document
        .querySelector("body")
        .removeEventListener("click", this.boundSetHiddenUserData);
      this.inviteForm.removeEventListener("input", this.boundValidateForm);
      this.inviteForm
        .querySelector(".notify-user-mention-button")
        .removeEventListener("click", this.boundAcceptUserData);
    });
  }

  setUp() {
    this.listenForUserMentionTrigger();
    this.addMentionsToFormOnSubmit();
    this.clearListenersOnUserMentionInviteModalClose();
  }
}

export function initUserMentionsForElement(element, memorialSlug, searchEndpoint) {
  const handler = new UserMentionWriteHandler(element, memorialSlug, searchEndpoint);
  handler.setUp();
}

export function initUserMentions(props) {
  const {
    memorialIsExample,
    userMention: { memorialSlug, userMentionClass, userSearchEndpoint },
  } = props;

  // set up write user mentions
  if (!memorialIsExample) {
    const mentionableElements = document.querySelectorAll(`.${userMentionClass}`);

    mentionableElements.forEach((element) => {
      initUserMentionsForElement(element, memorialSlug, userSearchEndpoint);
    });
  }
}

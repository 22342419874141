import Collapse from "bootstrap/js/dist/collapse"; // eslint-disable-line no-unused-vars

/**
 * Initialize the expandable collapse element. Can be called again and again on the same
 * element. This is useful when you have an element whose size isn't settled until it's
 * visible.
 *
 * The given element should have the `expandable-collapse` classes and bet configured as
 * a Bootstrap Collapse.
 *
 * The HTML for the controls is as follows:
 *
 * <div class="control">
 *   <a href="javascript:;" data-bs-toggle="collapse" data-bs-target="<collapse-id>" aria-expanded="false">
 *     <span class="more">Read More</span>
 *     <span class="less">Read Less</span>
 *   </a>
 * </div>
 *
 * @param {HTMLElement} rootElement - the collapse element.
 */
export function initExpandableCollapse(rootElement) {
  let lineCount = 1;
  if (rootElement.dataset.collapsedLineCount) {
    lineCount = parseInt(rootElement.dataset.collapsedLineCount, 10);
  }

  const lineHeightStr = window.getComputedStyle(rootElement).lineHeight;
  const lineHeight = parseInt(lineHeightStr.replace("px", ""), 10);
  const collapsedHeight = lineHeight * lineCount;
  const gradientHeight = Math.max(collapsedHeight / 3, lineHeight * 2);

  if (rootElement.scrollHeight > 0 && rootElement.scrollHeight < collapsedHeight + 20) {
    rootElement.classList.add("show", "short");
  } else {
    rootElement.style.setProperty(
      "--expandable-collapse-gradient-height",
      `${gradientHeight}px`,
    );
    rootElement.style.setProperty(
      "--expandable-collapse-collapsed-height",
      `${collapsedHeight}px`,
    );
  }
}

/**
 * Initialize the expandable collapse element.
 */
export default function initExpandableCollapses() {
  document.querySelectorAll(".expandable-collapse").forEach((rootElement) => {
    initExpandableCollapse(rootElement);
  });
}

import { sendBrowserAgnosticEvent } from "../core/utils";

const supportsBlur = () =>
  CSS &&
  (CSS.supports("backdrop-filter: blur(20px)") ||
    CSS.supports("-webkit-backdrop-filter: blur(20px)"));

export function initProductPhotoBlur(threshold = 0.2) {
  const images = document.querySelectorAll(".product-photo");

  const processImage = (img) => {
    const aspect = img.naturalWidth / img.naturalHeight;
    if (Math.abs(aspect - 1.333) <= threshold) {
      img.closest(".product-photo-container").classList.add("fill");
    } else {
      const canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);

      const pixelCoordinates = [
        [0, 0],
        [0, canvas.height / 2],
        [0, canvas.height - 1],
        [canvas.width / 2, 0],
        [canvas.width / 2, canvas.height - 1],
        [canvas.width - 1, 0],
        [canvas.width - 1, canvas.height / 2],
        [canvas.width - 1, canvas.height - 1],
      ];

      const pixelData = pixelCoordinates.map((p) =>
        ctx.getImageData(p[0], p[1], 1, 1).data.slice(0, 4),
      );

      const container = img.parentNode;

      if (pixelData.every((d) => d.every((p, i) => p === pixelData[0][i]))) {
        const bg = pixelData[0];
        container.style.backgroundColor = `rgba(${bg[0]}, ${bg[1]}, ${bg[2]}, ${bg[3]})`;
      } else if ("objectFit" in document.documentElement.style && supportsBlur()) {
        container.style.backgroundImage = `url('${img.src}')`;
        container.style.backgroundSize = "cover";
      } else {
        const avg = pixelData
          .reduce((acc, curr) => acc.map((val, idx) => val + curr[idx]), [0, 0, 0, 0])
          .map((val) => val / pixelData.length);
        container.style.backgroundColor = `rgba(${avg[0]}, ${avg[1]}, ${avg[2]}, ${avg[3]})`;
      }
    }
  };

  if (images && images.forEach) {
    images.forEach((image) => {
      image.addEventListener("load", () => {
        processImage(image);
      });
      if (image.complete) {
        sendBrowserAgnosticEvent(image, "load");
      }
    });
  }
}

export function initCheckoutDynamicFormFields(props) {
  // Delivery instructions display toggle.
  const toggleTriggers = document.querySelectorAll(
    '[data-toggle-for="delivery-instructions"]',
  );
  toggleTriggers.forEach((trigger) =>
    trigger.addEventListener("click", () => {
      const toggleTargets = document.querySelectorAll(
        '[data-toggle-name="delivery-instructions"]',
      );
      toggleTargets.forEach((t) => {
        t.classList.toggle("d-none");
      });
    }),
  );

  if (!props.skipBillingAndDeliverySync) {
    let billingNameChanged = false;
    const deliveryNameInput = document.getElementById(props.formFieldIds.deliveryName);
    const billingNameInput = document.getElementById(props.formFieldIds.billingName);

    if (deliveryNameInput && billingNameInput) {
      deliveryNameInput.addEventListener("input", () => {
        if (!billingNameChanged || !billingNameInput.value) {
          billingNameInput.value = deliveryNameInput.value;
          billingNameChanged = false;
          sendBrowserAgnosticEvent(billingNameInput, "fill");
        }
      });

      billingNameInput.addEventListener("click", () => {
        billingNameChanged = true;
      });
    }
  }
}

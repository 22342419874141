import "../../../sass/memorials/private/private.scss";

import Glide, { Swipe } from "@glidejs/glide/dist/glide.modular.esm";
import Alert from "bootstrap/js/dist/alert";
import Carousel from "bootstrap/js/dist/carousel";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown"; // eslint-disable-line no-unused-vars
import Modal from "bootstrap/js/dist/modal";
import Tab from "bootstrap/js/dist/tab"; // eslint-disable-line no-unused-vars

import BelongingEditor from "../../commerce/BelongingEditor";

import { initProductPhotoBlur } from "../../commerce/utils";
import AutoSaveTextField from "../../components/AutoSaveTextField";
import initBusinessAssociationForm from "../../components/BusinessAssociationForm";
import initCauseOfDeathAutocomplete from "../../components/CauseOfDeathAutocomplete";
import initCityAutocomplete from "../../components/CityAutocomplete";
import { initEditEvents, initEditEventsOnLoad } from "../../components/EditEvents";
import DeceasedPhotoUploader from "../../components/DeceasedPhotoUploader";
import initDeceasedSearchAutocomplete from "../../components/DeceasedSearchAutocomplete";
import { initEditDropdowns } from "../../components/EditMenuDropdowns";
import initFundraiserForm from "../../components/FundraiserForm";
import initGoogleAutocomplete from "../../components/GoogleAutocomplete";
import initMemorialFeatureAutocomplete from "../../components/MemorialFeatureAutocomplete";
import initMobileShareButton from "../../components/MobileShareButton";
import initOrganDonationForm from "../../components/OrganDonationForm";
import initPhotoCropper from "../../components/PhotoCropper";
import ProgressRing from "../../components/ProgressRing";
import initSubscriberNotificationsModal from "../../components/SubscriberNotificationsModal";
import initSuccessModal from "../../components/SuccessModal";
import initUGCTemplates from "../../components/UGCTemplates";
import { initUserMentions } from "../../components/UserMentions";
import config from "../../core/config";
import {
  generateElements,
  getNextSiblingMatchingSelector,
  handleAjaxFormSubmitProtection,
  initClipboard,
  initDirtyTracker,
  initPopovers,
  initTooltips,
  isInViewport,
  isVisible,
  registerFormSubmitProtection,
  storageAvailable,
  trackCustomUserAction,
} from "../../core/utils";
import initRelationshipForms from "./relationshipForms";
import requests, { ResponseError } from "../../core/requests";
import {
  initAddFuneralHomeWarning,
  scrollInMemorialPrivateContentContainer,
} from "../utils";
import initPayableCheckout from "../../commerce/PayableCheckout";
import initAddPaymentMethod from "../../commerce/PaymentMethod";

export { default as initMemorialPrivateHowToHelp } from "./howToHelp";
export { default as initInvites } from "./invites";
export {
  initFundraiserOnboarding,
  initMemorialOnboardingInviteManager,
} from "./onboarding";
export { default as initMemorialOnboardingEvents } from "./onboardingEvents";
export { default as initMemorialOnboardingFamily } from "./onboardingFamily";
export { default as initMemorialOnboardingFinalRestingPlace } from "./onboardingFinalRestingPlace";
export { default as initMemorialOnboardingFundraisingGuidance } from "./onboardingFundraisingGuidance";
export { default as initMemorialOnboardingPhoto } from "./onboardingPhoto";
export { default as initMemorialOnboardingShare } from "./onboardingShare";
export { default as initPartnerObituaryOrderCheckout } from "./partnerObituary";
export { default as initMemorialPrivateChooseCause } from "./privateChooseCause";

const eventActions = {
  "cause-name": "cause-name-change-after-launch",
  "cause-url": "cause-website-change-after-launch",
  "cause-type": "sympathy-gift-type-change-after-launch",
};

const errorMessages = {
  "cause-name":
    "The cause name can't be changed if there are outstanding pledges to the cause.",
  "cause-url":
    "The cause website address can't be changed if there are outstanding pledges to the cause.",
  "cause-type":
    "The type of sympathy gift can't be changed if there are outstanding pledges to the cause.",
};

const TOUR_DELAY = 5000;

function initTimezoneSelector(timezoneSelectorId) {
  const timezoneSelector = document.querySelector(`#${timezoneSelectorId}`);
  if (timezoneSelector) {
    let browserTimezone;
    try {
      browserTimezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {
      browserTimezone = null;
    }

    if (browserTimezone) {
      const match = Array.from(timezoneSelector.options).find(
        (opt) => opt.value === browserTimezone,
      );

      if (match) {
        timezoneSelector.insertBefore(
          match,
          timezoneSelector.firstElementChild.nextElementSibling,
        );
      }
    }
  }
}

async function completeSharingChecklistItem(checklistItemKey, endpoint) {
  try {
    const response = await requests.post(endpoint, {
      "sharing-checklist-item": checklistItemKey,
      "sharing-checklist-value": 1,
    });

    if (!response.ok) {
      throw new ResponseError(response);
    }
  } catch {
    return;
  }

  document.querySelectorAll(`[data-alert-key="${checklistItemKey}"]`).forEach((a) => {
    a.remove();
  });
}

const SIDEBAR_KEY = "el-sidebar-hidden";

class SidebarManager {
  constructor(sidebar) {
    this.sidebar = sidebar;
    this.storageAvailable = storageAvailable("sessionStorage");
  }

  isShowing() {
    return !this.sidebar.classList.contains("hidden");
  }

  sync() {
    const hidden = window.sessionStorage.getItem(SIDEBAR_KEY) === "true";
    this.sidebar.classList.toggle("hidden", hidden);
  }

  show() {
    if (this.storageAvailable) {
      window.sessionStorage.setItem(SIDEBAR_KEY, "false");
      this.sync();
    } else {
      this.sidebar.classList.remove("hidden");
    }
  }

  hide() {
    if (this.storageAvailable) {
      window.sessionStorage.setItem(SIDEBAR_KEY, "true");
      this.sync();
    } else {
      this.sidebar.classList.add("hidden");
    }
  }

  toggle() {
    if (this.storageAvailable) {
      if (this.isShowing()) {
        this.hide();
      } else {
        this.show();
      }
    } else {
      this.sidebar.classList.toggle("hidden");
    }
  }
}

function initMemorialUpdateEdits() {
  document.querySelectorAll(".remove-memorial-update").forEach((link) => {
    link.addEventListener("click", async () => {
      if (
        window.confirm(
          "Are you sure you want to delete this update? Subscribers will not be notified.",
        )
      ) {
        const deleteUrl = link.getAttribute("data-delete-url");

        link.innerHTML = "Removing...";
        try {
          const response = await requests.delete(deleteUrl);

          if (!response.ok) {
            throw new ResponseError(response);
          }
        } catch {
          link.innerHTML = "Removing failed.";
          return;
        }

        link.closest(".memorial-update-container").remove();
      }
    });
  });
}

function initMemorialGiftPremiumControls(props) {
  if (!props.hasPremium) {
    return;
  }

  if (props.disableMemorialGiftsId) {
    const disableMemorialGiftsContainers = document.querySelectorAll(
      ".disable-memorial-gifts-container",
    );
    if (disableMemorialGiftsContainers.length > 0) {
      disableMemorialGiftsContainers.forEach((disableMemorialGiftsContainer) => {
        const disableMemorialGiftsToggle = disableMemorialGiftsContainer.querySelector(
          'input[type="checkbox"]',
        );
        const handleDisableToggle = () => {
          const giftTogglesContainer = document.querySelector(".gift-toggles");
          const giftTogglesDoNotSuggestGifts = giftTogglesContainer.querySelector(
            ".disable-memorial-gifts-container",
          );
          const causeConfigurationContainers = document.querySelectorAll(
            ".cause-configuration-container",
          );
          const preferencesContainer = document.querySelector(
            ".memorial-gifts-display-preferences-container",
          );
          const standaloneStoryAndChecklist = document.querySelector(
            ".standalone-story-and-checklist",
          );
          const addFundraiserBtn = document.querySelector(
            ".full-width-add-fundraiser-button",
          );

          if (giftTogglesContainer && causeConfigurationContainers) {
            giftTogglesContainer.classList.toggle(
              "gifts-hidden",
              disableMemorialGiftsToggle.checked,
            );
            causeConfigurationContainers.forEach((causeConfigurationContainer) => {
              causeConfigurationContainer.parentElement.classList.toggle(
                "d-none",
                disableMemorialGiftsToggle.checked,
              );
            });
            giftTogglesDoNotSuggestGifts.classList.toggle(
              "d-none",
              !disableMemorialGiftsToggle.checked,
            );
            preferencesContainer.classList.toggle(
              "d-none",
              disableMemorialGiftsToggle.checked,
            );

            if (standaloneStoryAndChecklist) {
              standaloneStoryAndChecklist.classList.toggle(
                "d-none",
                disableMemorialGiftsToggle.checked,
              );
            }

            if (addFundraiserBtn) {
              addFundraiserBtn.classList.toggle(
                "d-none",
                disableMemorialGiftsToggle.checked,
              );
            }
          }

          disableMemorialGiftsContainers.forEach((container) => {
            container.querySelector('input[type="checkbox"]').checked =
              disableMemorialGiftsToggle.checked;
          });
        };

        disableMemorialGiftsToggle.addEventListener("change", handleDisableToggle);
        handleDisableToggle();
      });
    }
  }

  if (props.disableMemorialGiftListDisplayId) {
    const disableListDisplay = document.getElementById(
      props.disableMemorialGiftListDisplayId,
    );
    const disableContributionsDisplay = document.getElementById(
      props.disableMemorialGiftContributionsDisplayId,
    );

    if (disableListDisplay) {
      const handleListDisplayToggle = () => {
        if (disableListDisplay.checked) {
          disableContributionsDisplay.checked = true;
          disableContributionsDisplay.disabled = true;
        } else {
          disableContributionsDisplay.disabled = false;
        }
      };

      const undoDisabledCheckboxOnSubmit = (checkboxEl) => {
        const targetForm = checkboxEl.closest("form");
        if (targetForm && checkboxEl) {
          targetForm.addEventListener("submit", () => {
            if (checkboxEl.disabled) {
              checkboxEl.disabled = false;
            }
          });
        }
      };

      disableListDisplay.addEventListener("change", handleListDisplayToggle);
      handleListDisplayToggle();
      undoDisabledCheckboxOnSubmit(disableContributionsDisplay);
    }
  }
}

function initPremiumUpsell(props) {
  if (!props.hasPremium) {
    const disabledPremiumCheckboxContainers = document.querySelectorAll(
      ".premium-preference-toggle.disabled",
    );
    disabledPremiumCheckboxContainers.forEach((checkboxContainer) => {
      checkboxContainer
        .querySelector(".click-capture-overlay")
        .addEventListener("click", () => {
          Modal.getOrCreateInstance(".premium-upsell-modal").show();
        });
    });
  }
}

function initTimezoneModal() {
  const urlParams = new URLSearchParams(window.location.search);
  if (
    urlParams.get("timezone-added") ||
    urlParams.get("timezone-changed") ||
    urlParams.get("timezone-removed")
  ) {
    const message = document.getElementsByClassName("timezone-modal-text")[0];

    if (urlParams.get("timezone-added")) {
      message.innerHTML =
        "This timezone will now be displayed throughout this memorial website.";
    } else if (urlParams.get("timezone-changed")) {
      message.innerHTML =
        "Your memorial website and any event times have been updated to reflect this new timezone. If you have memorial events listed, please check to ensure the times shown are what you intended.";
    } else {
      message.innerHTML =
        "The timezone has been removed from this memorial website. If you have memorial events listed, please check to ensure the times shown are what you intended.";
    }

    Modal.getOrCreateInstance(".timezone-selection-modal").show();
  }
}

function initThankYouNoteCheckboxes() {
  document
    .querySelectorAll(".sent-thank-you-checkbox")
    .forEach((sentThankYouCheckbox) => {
      sentThankYouCheckbox.addEventListener("click", async (event) => {
        event.preventDefault();

        const checkbox = event.currentTarget;
        checkbox.disabled = true;

        let responseData;

        try {
          const response = await requests.post(sentThankYouCheckbox.dataset.updateUrl, {
            toggle_thank_you: true,
          });

          if (!response.ok) {
            throw new ResponseError(response);
          }

          responseData = await response.json();
        } catch {
          checkbox.disabled = false;
        }

        if (responseData) {
          checkbox.disabled = false;
          checkbox.checked = responseData.sentThankYou;
        }
      });
    });
}

export function initMemorialPrivateBase(props) {
  // Notification-sending prompt
  if (props.showNotificationsPrompt) {
    initSubscriberNotificationsModal(props);
  }

  // Initialize UserMention code
  if (props.userMention) {
    initUserMentions(props);
  }

  // Initialize JS for sidebar hide/show in tablet mode.
  const sidebar = document.querySelector(".private-sidebar");
  if (sidebar) {
    const sidebarToggle = sidebar.querySelector(".sidebar-toggle");
    const sidebarManager = new SidebarManager(sidebar);
    sidebarManager.sync();

    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", () => {
        sidebarManager.toggle();
      });
    }

    // If there are visible errorlists, hide sidebar.
    if (
      Array.from(document.querySelectorAll(".errorlist")).some(
        (el) => el.offset !== null,
      )
    ) {
      sidebarManager.hide();
    }

    sidebar.querySelectorAll(".sidebar-nav .nav-link").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        sidebarManager.hide();
        window.location.href = e.currentTarget.href;
      });
    });
  }

  // Initialize: clipboard management, "show less", dirty form tracker, premium upsell,
  // timezone modal.
  initClipboard();
  initDirtyTracker(props);
  initPremiumUpsell(props);
  initTimezoneModal();
  initAddFuneralHomeWarning();

  // Initialize FB share links
  // NOTE: this is the callback function for when the FaceBook SDK loads
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: config.facebookAppId,
      autoLogAppEvents: true,
      xfbml: true,
      version: config.facebookApiVersion,
    });

    document.querySelectorAll(".btn-facebook-share").forEach((facebookBtn) => {
      facebookBtn.addEventListener("click", () => {
        if (config.debug) {
          console.log(
            `Would share the following link to Facebook: ${props.sharingUrl}`,
          );
        }

        window.FB.ui(
          {
            method: "share",
            href: props.sharingUrl,
          },
          (response) => {
            if (response && !response.error_code && typeof response !== "undefined") {
              completeSharingChecklistItem(
                "share_to_facebook",
                props.updateSharingChecklistEndpoint,
              );
            }
          },
        );
      });
    });
  };

  // Initialize the share button if supported.
  initMobileShareButton("#sidebar-mobile-actions-share .btn-native-share");

  const autocompleteSharingChecklistItems = document.querySelectorAll(
    ".autocomplete-sharing-checklist-item",
  );
  autocompleteSharingChecklistItems.forEach((item) => {
    item.addEventListener("click", () => {
      completeSharingChecklistItem(
        item.dataset.alertKey,
        props.updateSharingChecklistEndpoint,
      );
    });
  });

  const allAlertsContainer = document.querySelector(".private-all-alerts");
  const showAllAlerts = document.querySelector(".show-all-alerts");
  if (showAllAlerts) {
    showAllAlerts.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (
        props.isPrivateDashboard &&
        allAlertsContainer &&
        allAlertsContainer.offsetParent == null
      ) {
        allAlertsContainer.classList.remove("d-none");
        allAlertsContainer.classList.add("full-screen");
      } else {
        scrollInMemorialPrivateContentContainer(allAlertsContainer);
        allAlertsContainer.classList.add("expand-alerts");
        sidebar.classList.add("hidden");
      }
    });
  }

  if (
    props.isPrivateDashboard &&
    window.location.hash === "#all-alerts" &&
    allAlertsContainer &&
    allAlertsContainer.offsetParent == null
  ) {
    allAlertsContainer.classList.remove("d-none");
    allAlertsContainer.classList.add("full-screen");
  }

  const closeAlerts = document.querySelector(".close-alerts");
  if (closeAlerts) {
    closeAlerts.addEventListener("click", () => {
      allAlertsContainer.classList.add("d-none");
      allAlertsContainer.classList.remove("full-screen");
    });
  }

  const hideAlertButtons = document.querySelectorAll(".dismiss-alert-button");
  if (hideAlertButtons) {
    hideAlertButtons.forEach((alertButton) => {
      if (alertButton.dataset.alertKey) {
        alertButton.addEventListener("click", async () => {
          const revert = handleAjaxFormSubmitProtection(alertButton);
          const listItem = alertButton.closest(".list-group-item");

          let responseData;

          try {
            const response = await requests.post(props.dismissPrivateAlertUrl, {
              key: alertButton.dataset.alertKey,
            });

            if (!response.ok) {
              throw new ResponseError(response);
            }

            responseData = await response.json();
          } catch {
            revert();
            return;
          }

          if (responseData) {
            if (responseData.success) {
              listItem.remove();
            } else {
              alertButton.innerText = "Error";
            }
          }
        });
      }
    });
  }

  // Init autoscroll
  if (props.autoScrollTargets) {
    const autoScroll = () => {
      Object.keys(props.autoScrollTargets).forEach((hash) => {
        if (window.location.hash === hash) {
          const node = document.querySelector(props.autoScrollTargets[hash]);
          if (node) {
            scrollInMemorialPrivateContentContainer(node, -240);
            sidebar.classList.add("hidden");
          }
        }
      });
    };

    window.onhashchange = autoScroll;
    autoScroll();
  }

  const onboardingReentryBanner = document.querySelector(".exit-onboarding-wizard");
  if (onboardingReentryBanner) {
    const alert = Alert.getOrCreateInstance(onboardingReentryBanner);
    const closeBannerBtn = onboardingReentryBanner.querySelector(".btn-close");
    closeBannerBtn.addEventListener("htmx:afterRequest", () => {
      alert.close();
    });
  }

  initMemorialFeatureAutocomplete(props.memorialFeatureSearch);
  initPopovers();
  initTooltips();
}

function initMemorialPrivateTour(props) {
  // Instead of relying on anything else here let's just cut off the tour from being
  // accessible at narrower than 1000 pixels wide. That's when the layout changes,
  // more or less.
  if (window.innerWidth < 1000) {
    return;
  }

  const { tourData, containerSelector } = props;

  const container = document.querySelector(containerSelector);

  let [currentTourStep] = tourData;

  if (container) {
    let tourHasBeenInvoked = false;
    let advanceTour;

    const closeTour = () => {
      document.querySelectorAll(".tour-target").forEach((target) => {
        target.classList.remove("tour-target");
      });

      document.querySelectorAll(".tour-step").forEach((step) => {
        step.classList.add("d-none");
      });

      container.classList.add("d-none");
    };

    const renderCurrentTourStep = () => {
      document.querySelectorAll(".tour-step").forEach((step) => {
        step.classList.add("d-none");
        const tourStepTarget = document.querySelector(
          `[data-tour-target-for="${step.id}"]`,
        );
        if (tourStepTarget) {
          tourStepTarget.classList.toggle(
            "tour-target",
            step.id === currentTourStep.id,
          );
        }
      });

      const currentStep = document.getElementById(currentTourStep.id);

      if (currentStep) {
        if (currentTourStep.alone) {
          currentStep.style.position = "absolute";
          currentStep.style.width = "33%";
          currentStep.style.left = "33%";
          currentStep.style.top = "25%";
          currentStep.classList.remove("d-none");
        } else {
          const tourStepTarget = document.querySelector(
            `[data-tour-target-for="${currentStep.id}"]`,
          );
          if (tourStepTarget) {
            if (!isInViewport(tourStepTarget)) {
              tourStepTarget.scrollIntoView(false);
            }

            // Do the following in a 100ms timeout because `scrollIntView` doesn't
            // synchronously update the bounding client rect we need.
            setTimeout(() => {
              const targetRect = tourStepTarget.getBoundingClientRect();

              const leftEdge = targetRect.left;
              const rightEdge = targetRect.left + targetRect.width;
              const topEdge = targetRect.top;
              const bottomEdge = targetRect.top + targetRect.height;

              // Reset positioning to null state.
              currentStep.style.position = "absolute";
              currentStep.style.left = null;
              currentStep.style.right = null;
              currentStep.style.top = null;
              currentStep.style.bottom = null;

              let horizontalSpace;
              let horizontalAnchor;
              let verticalSpace;
              let verticalAnchor;

              // Determine if we're placing above or below target.
              if (topEdge > window.innerHeight - bottomEdge) {
                // More space on top.
                verticalSpace = window.innerHeight - topEdge;
                verticalAnchor = "bottom";
              } else {
                // More space on bottom.
                verticalSpace = bottomEdge;
                verticalAnchor = "top";
              }

              // Determine if we're placing left or right of target.
              if (leftEdge > window.innerWidth - rightEdge) {
                // More space to left.
                horizontalSpace = window.innerWidth - leftEdge;
                horizontalAnchor = "right";
              } else {
                // More space to right.
                horizontalSpace = rightEdge;
                horizontalAnchor = "left";
              }

              // Determine if we're placing beside or above/below.
              if (horizontalSpace > verticalSpace) {
                // Left/right
                currentStep.style.setProperty(horizontalAnchor, `${horizontalSpace}px`);
                currentStep.style.top = `${topEdge}px`;
              } else {
                // Top/bottom
                currentStep.style.setProperty(verticalAnchor, `${verticalSpace}px`);
                currentStep.style.left = `${leftEdge}px`;
              }

              currentStep.classList.remove("d-none");

              // Correct flowing over bottom
              if (
                window.innerHeight - currentStep.getBoundingClientRect().bottom <
                60
              ) {
                currentStep.style.top = null;
                currentStep.style.bottom = "0";
              }
            }, 150);
          } else {
            advanceTour();
          }
        }
      }
    };

    advanceTour = () => {
      const currentIndex = tourData.indexOf(currentTourStep);
      if (currentIndex + 1 >= tourData.length) {
        // We're at the end!
        [currentTourStep] = tourData;
        closeTour();
      } else {
        currentTourStep = tourData[currentIndex + 1];
        renderCurrentTourStep();
      }
    };

    const beginTour = () => {
      container.classList.remove("d-none");
      renderCurrentTourStep();
      tourHasBeenInvoked = true;
    };

    document.querySelectorAll(".tour-next").forEach((nextBtn) => {
      nextBtn.addEventListener("click", () => {
        advanceTour();
      });
    });

    document.querySelectorAll(".tour-exit").forEach((exitBtn) => {
      exitBtn.addEventListener("click", () => {
        closeTour();
      });
    });

    // Initial timeout
    const timerFire = () => {
      if (!tourHasBeenInvoked) {
        beginTour();
      }
    };

    let timeoutId = setTimeout(timerFire, TOUR_DELAY);

    document.addEventListener("mousemove", () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      if (!tourHasBeenInvoked) {
        timeoutId = setTimeout(timerFire, TOUR_DELAY);
      }
    });

    // Don't let the timer fire while page is backgrounded.
    // Restart it when we're foregrounded if applicable.
    document.addEventListener("visibilitychange", () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      if (!document.hidden && !tourHasBeenInvoked) {
        setTimeout(timerFire, TOUR_DELAY);
      }
    });
  }
}

export function initMemorialInvitePeople(props) {
  const personCountSpan = document.querySelector(".person-count");
  const { emptyForm } = props.invitePeopleFormset;
  const totalFormsField = document.getElementById(
    props.invitePeopleFormset.totalFormsFieldId,
  );
  let totalForms = props.invitePeopleFormset.total;

  const initManagerRelationshipBehavior = (container) => {
    const relationshipEntryContainer = container.querySelector(".relationship-entry");
    const managerToggles = container.querySelectorAll('[type="radio"]');

    managerToggles.forEach((toggle) => {
      toggle.addEventListener("change", () => {
        const showingManager = toggle.checked && toggle.value === "True";
        relationshipEntryContainer.classList.toggle("d-none", !showingManager);
      });
    });
  };

  const initRelationshipOtherBehavior = (selectField, strField) => {
    if (selectField && strField) {
      selectField.addEventListener("change", (evt) => {
        const index = evt.target.selectedIndex;
        const selected = evt.target.options[index];
        if (selected.value === "other") {
          strField.classList.remove("d-none");
        } else {
          strField.querySelector("input").value = "";
          strField.classList.add("d-none");
        }
      });
    }
  };

  const updateSubmitButton = () => {
    const submitButton = document.querySelector("#invite-people-btn");
    personCountSpan.innerText = `${totalForms} pe${totalForms === 1 ? "rson" : "ople"}`;
    if (totalForms === 1) {
      const nameFields = document.querySelectorAll(".name-entry input");
      const emailField = document.querySelector(".email-entry input");
      submitButton.disabled = !(
        Array.from(nameFields).every((field) => field.value) && emailField.value
      );
    } else {
      submitButton.disabled = false;
    }
  };

  const updateNumberOfForms = (increment) => {
    totalForms += increment;
    totalFormsField.value = totalForms;
    updateSubmitButton();
  };

  const removeFormHandler = (event) => {
    const formContainer = event.currentTarget.closest(".invite-person-survivor-form");
    if (formContainer) {
      updateNumberOfForms(-1);
      formContainer.remove();
    }
  };

  document
    .querySelectorAll(".invite-person-survivor-form.first input")
    .forEach((input) => {
      input.addEventListener("input", () => {
        updateSubmitButton();
      });
    });

  document.querySelectorAll(".invite-person-survivor-form").forEach((formContainer) => {
    const selectField = formContainer.querySelector("select");
    const strField = formContainer.querySelector(".survivor-relationship-other");
    initRelationshipOtherBehavior(selectField, strField);
    initManagerRelationshipBehavior(formContainer);
  });

  document.querySelectorAll(".remove-invite-person-form").forEach((removeButton) => {
    removeButton.addEventListener("click", removeFormHandler);
  });

  const addInvitePersonFormButton = document.querySelector(".add-invite-person-btn");
  if (addInvitePersonFormButton) {
    addInvitePersonFormButton.addEventListener("click", () => {
      const container = addInvitePersonFormButton.parentElement;
      const newIndex = totalForms;

      // Update count state.
      updateNumberOfForms(1);

      // Create new form.
      const newFormContainer = document.createElement("div");
      newFormContainer.classList.add("add-survivor-form");
      newFormContainer.classList.add("invite-person-survivor-form");

      // Put in HTML and insert into DOM.
      newFormContainer.innerHTML = emptyForm.replace(/__prefix__/g, newIndex);
      container.insertBefore(newFormContainer, addInvitePersonFormButton);
      newFormContainer
        .querySelector(".remove-invite-person-form")
        .addEventListener("click", removeFormHandler);

      const selectField = newFormContainer.querySelector("select");
      const strField = newFormContainer.querySelector(".survivor-relationship-other");
      initRelationshipOtherBehavior(selectField, strField);
      initManagerRelationshipBehavior(newFormContainer);
    });
  }
}

export function initMemorialPrivateDashboard(props) {
  const recommendationsWrapper = document.querySelector(
    ".creator-recommendations-carousel-wrapper",
  );
  if (recommendationsWrapper) {
    new Glide(recommendationsWrapper.querySelector(".glide"), {
      animationDuration: 600,
      peek: { before: 20, after: 20 },
      type: "carousel",
    }).mount({ Swipe });
  }

  initCityAutocomplete({
    initialAutocompleteData: props.initialAutocompleteData,
    cityIdentifierInputId: "id_memorial-city_slug",
    citySearchUrl: props.citySearchUrl,
  });

  // Community FAQ stuff

  // when someone is typing an answer to an faq, let's show the submit button
  document.querySelectorAll(".answer-text").forEach((answerText) => {
    const collapseElement = answerText
      .closest(".carousel-item")
      .querySelector(".collapse");
    answerText.addEventListener("focus", () => {
      Collapse.getOrCreateInstance(collapseElement, { toggle: false }).show();
    });
  });

  const carouselElement = document.querySelector(
    ".memorial-community-questions .carousel",
  );
  if (carouselElement) {
    new Carousel(carouselElement);

    // hide the submit button when navigating the faq carousel
    carouselElement
      .querySelectorAll(".previous-btn, .next-btn, .carousel-indicators")
      .forEach((notAnswerText) => {
        notAnswerText.addEventListener("click", () => {
          const openCollapseElement = notAnswerText
            .closest(".carousel")
            .querySelector(".collapse.show");
          if (openCollapseElement) {
            Collapse.getOrCreateInstance(openCollapseElement, { toggle: false }).hide();
          }
        });
      });
  }

  initSuccessModal();

  let hasSubmitted = false;
  const form = document.querySelector(".memorial-community-questions form");
  if (form) {
    form.addEventListener("submit", (event) => {
      event.preventDefault();

      const submitBtn = [...form.querySelectorAll("button")].find((element) =>
        isVisible(element),
      );
      if (submitBtn) {
        document.getElementById("id_submitted_question_index").value = submitBtn.value;
        if (!hasSubmitted) {
          hasSubmitted = true;
          form.submit();
        }
      }
    });
  }

  initThankYouNoteCheckboxes();

  const ringContainer = document.querySelector(".checklist-progress-container");
  if (ringContainer) {
    new ProgressRing(ringContainer, props.checklistProgress);
  }

  if (props.tourProps) {
    initMemorialPrivateTour(props.tourProps);
  }

  if (props.invitePeopleFormset) {
    initMemorialInvitePeople(props);
  }
}

function setupDeceasedTitleAndSuffix(props) {
  const { titleInputId, titleOtherStrInputId, suffixInputId, suffixOtherStrInputId } =
    props;

  if (titleInputId && titleOtherStrInputId && suffixInputId && suffixOtherStrInputId) {
    // Deceased title, e.g. Honorable
    const titleInput = document.querySelector(`#${props.titleInputId}`);
    const titleOtherStrContainer = document.querySelector(
      `#${props.titleOtherStrInputId}`,
    ).parentNode;
    titleInput.addEventListener("change", (event) => {
      if (event.target.value === "other") {
        titleOtherStrContainer.classList.remove("d-none");
      } else {
        titleOtherStrContainer.classList.add("d-none");
      }
    });

    // Deceased suffix, e.g. IV
    const suffixInput = document.querySelector(`#${props.suffixInputId}`);
    const suffixOtherStrContainer = document.querySelector(
      `#${props.suffixOtherStrInputId}`,
    ).parentNode;
    suffixInput.addEventListener("change", (event) => {
      if (event.target.value === "other") {
        suffixOtherStrContainer.classList.remove("d-none");
      } else {
        suffixOtherStrContainer.classList.add("d-none");
      }
    });
  }
}

export function initMemorialDeceasedForm(props) {
  setupDeceasedTitleAndSuffix(props);

  // Deceased nicknaming
  const addNickname = document.querySelector(".add-nickname");
  addNickname.addEventListener("click", () => {
    addNickname.nextElementSibling.classList.remove("d-none");
    addNickname.classList.add("d-none");
  });

  // DOB/DOD
  const dateOfDeathInputs = document.querySelectorAll(
    ".date-of-death-input-group select",
  );

  function disableDateOfDeathPicker() {
    dateOfDeathInputs.forEach((currentInput) => {
      currentInput.selectedIndex = null;
      currentInput.classList.add("disabled-input");
      currentInput.removeAttribute("required");
    });
  }

  function enableDateOfDeathPicker() {
    dateOfDeathInputs.forEach((currentInput) => {
      currentInput.classList.remove("disabled-input");
      currentInput.setAttribute("required", "");
    });
  }

  const notYetPassed = document.getElementById(props.notYetPassedInputId);
  notYetPassed.addEventListener("change", () => {
    if (notYetPassed.checked) {
      disableDateOfDeathPicker();
    } else {
      enableDateOfDeathPicker();
    }
  });

  dateOfDeathInputs.forEach((currentInput) => {
    currentInput.addEventListener("click", () => {
      if (notYetPassed.checked) {
        enableDateOfDeathPicker();
        notYetPassed.checked = false;
      }
    });
  });

  if (notYetPassed.checked) {
    disableDateOfDeathPicker();
  }

  // Relationship (where relevant)
  if (props.relationshipSelectId) {
    const relationshipSelect = document.getElementById(props.relationshipSelectId);
    const relationshipOther = document.querySelector(".relationship-other");

    if (relationshipSelect && relationshipOther) {
      relationshipSelect.addEventListener("change", (evt) => {
        const index = evt.target.selectedIndex;
        const selected = evt.target.options[index];
        if (selected.value === "other") {
          relationshipOther.classList.remove("d-none");
        } else {
          relationshipOther.querySelector("input").value = "";
          relationshipOther.classList.add("d-none");
        }
      });
    }
  }

  initGoogleAutocomplete({
    element: document.getElementById("id_deceased-location_str"),
    options: {
      types: ["(cities)"],
    },
  });
}

export function initMemorialPrivateBasicInformation(props) {
  initMemorialDeceasedForm(props);

  const photosUploadedBanner = document.querySelector(".photos-uploaded-banner");

  const onPhotosUpdated = () => {
    if (photosUploadedBanner) {
      photosUploadedBanner.classList.remove("d-none");
    }
  };

  // Initialize multi-photo uploader.
  const multiPhotoUploader = new DeceasedPhotoUploader({
    onPhotosUpdated,
    ...props.deceasedPhotoUploaderProps,
  });

  const photosSection = document.querySelector(".memorial-deceased-form-photos");
  if (photosSection) {
    photosSection.addEventListener("click", (e) => {
      // We are clicking a layout-selector element.
      const layoutSelector = e.target.closest(".layout-selector");
      if (layoutSelector !== null) {
        photosUploadedBanner.classList.add("d-none");
        multiPhotoUploader.show(false);
      }
    });
  }
}

export function initMemorialPrivatePassing(props) {
  initBusinessAssociationForm(props);
  initCauseOfDeathAutocomplete(props.causeOfDeathSearch);
  initOrganDonationForm(props.organDonationSearch);

  initGoogleAutocomplete({
    element: document.getElementById("id_business_association-remains_location"),
    metadataElement: document.getElementById(
      "id_business_association-remains_location_metadata",
    ),
    options: {
      types: ["establishment", "geocode"],
    },
  });
}

function addDeliveryLocationAutocomplete(
  element,
  metadataElement,
  alwaysBlockReturnKeySubmit,
) {
  initGoogleAutocomplete({
    element,
    metadataElement,
    options: {
      componentRestrictions: { country: ["ca", "us"] },
      types: ["address"],
    },
    alwaysBlockReturnKeySubmit,
  });
}

function initDeliveryAddressesOnLoad(props) {
  const formsetContainer = document.querySelector(
    ".delivery-addresses-formset-container",
  );
  const inputElements = formsetContainer.getElementsByClassName(
    "location-autocomplete",
  );
  const addAddressContainer = formsetContainer.querySelector(".add-address-container");

  const deliveryAddressForms = document.querySelector(".delivery-address-forms");
  const totalFormCountInput = document.getElementById(
    props.deliveryAddressesTotalFormCountInputId,
  );
  let totalFormCount = props.deliveryAddressesTotalFormCount;

  function addRemoveAddressHandler(removeAddressButton) {
    removeAddressButton.addEventListener("click", () => {
      removeAddressButton.querySelector('input[type="checkbox"]').checked = true;
      removeAddressButton.parentElement.classList.add("d-none");
      const formIdField =
        removeAddressButton.previousElementSibling.querySelector(
          'input[type="hidden"]',
        );
      if (formIdField && !formIdField.value) {
        totalFormCount -= 1;
        totalFormCountInput.value = totalFormCount;
        removeAddressButton.remove();
      }
    });
  }

  const addAddressButton = addAddressContainer.querySelector("button");
  addAddressButton.addEventListener("click", () => {
    totalFormCount += 1;
    totalFormCountInput.value = totalFormCount;
    deliveryAddressForms.insertAdjacentHTML(
      "beforeend",
      props.emptyDeliveryAddressForm.replace(/__prefix__/g, totalFormCount - 1),
    );

    addDeliveryLocationAutocomplete(
      inputElements[totalFormCount - 1],
      document.getElementById(
        `id_delivery_addresses-${totalFormCount - 1}-location_metadata`,
      ),
      true,
    );

    addRemoveAddressHandler(
      deliveryAddressForms.lastElementChild.querySelector(".remove-address"),
    );
  });

  const allowFlowersToggle = document.getElementById(
    props.preferencesFormFieldIds.allowFlowers,
  );
  allowFlowersToggle.addEventListener("change", () => {
    if (allowFlowersToggle.checked) {
      formsetContainer.classList.remove("d-none");
    } else {
      formsetContainer.classList.add("d-none");
    }
  });

  for (let i = 0; i < inputElements.length; i += 1) {
    addDeliveryLocationAutocomplete(
      inputElements[i],
      document.getElementById(`id_delivery_addresses-${i}-location_metadata`),
    );
  }

  deliveryAddressForms
    .querySelectorAll(".remove-address")
    .forEach((removeAddressButton) => {
      addRemoveAddressHandler(removeAddressButton);
    });
}

export function initMemorialPrivateGifts(props) {
  initFundraiserForm(props, (clv) => {
    props.fundraisingGoalCleave = clv;
  });

  const { causeStoryForm } = props;

  if (props.isNewCause) {
    const causeStoryInput = document.querySelector(
      `#${causeStoryForm.fields.causeStory}`,
    );
    if (causeStoryInput) {
      const val = causeStoryInput.value;
      causeStoryInput.defaultValue = "must-edit-me!";
      causeStoryInput.value = val;
    }
  }

  const clearFundraisingUser = document.querySelector(".clear-fundraising-user-link");
  if (clearFundraisingUser) {
    clearFundraisingUser.addEventListener("click", () => {
      const input = document.getElementById(clearFundraisingUser.dataset.clearInput);
      input.value = 1;

      const form = input.closest("form");
      if (form) {
        form.requestSubmit();
      }
    });
  }

  document
    .querySelectorAll(".show-error-on-click")
    .forEach((showErrorOnClickElement) => {
      showErrorOnClickElement.addEventListener(
        "click",
        () => {
          const disabledFieldName = showErrorOnClickElement.dataset.disabledName;

          const errorList = getNextSiblingMatchingSelector(
            showErrorOnClickElement,
            ".errorlist",
          );
          if (errorList) {
            const errorMessage = errorMessages[disabledFieldName];
            showErrorOnClickElement.parentElement.append(
              ...generateElements(
                `<ul class="errorlist"><li>${errorMessage}</li></ul>`,
              ),
            );
            errorList.classList.remove("d-none");
          }

          const eventAction = eventActions[disabledFieldName];
          trackCustomUserAction(
            window.location.href,
            "memorial edit info",
            eventAction,
          );
        },
        { once: true },
      );
    });

  initMemorialGiftPremiumControls({
    disableMemorialGiftsId: props.premiumForm.fields.disableMemorialGifts,
    disableMemorialGiftListDisplayId:
      props.premiumForm.fields.disableMemorialGiftListDisplay,
    disableMemorialGiftContributionsDisplayId:
      props.premiumForm.fields.disableMemorialGiftContributionsDisplay,
    ...props,
  });

  props.charityFundraiserForms.forEach((charityFundraiserForm) => {
    const selfServeCheckbox = document.querySelector(
      `#${charityFundraiserForm.fields.selfServeDonations}`,
    );
    if (selfServeCheckbox) {
      const parentBodyCard = selfServeCheckbox.closest(".body-card");
      const customCharityFinePrint = parentBodyCard.querySelector(
        ".custom-charity-fine-print",
      );
      const selfServeCharityFinePrint = parentBodyCard.querySelector(
        ".self-serve-charity-fine-print",
      );
      const fundraisingManagerForm = parentBodyCard.querySelector(
        ".fundraising-user-selection",
      );
      const selfServeChanged = () => {
        selfServeCharityFinePrint.classList.toggle(
          "d-none",
          !selfServeCheckbox.checked,
        );
        customCharityFinePrint.classList.toggle("d-none", selfServeCheckbox.checked);

        if (fundraisingManagerForm) {
          fundraisingManagerForm.classList.toggle("d-none", !selfServeCheckbox.checked);
        }
      };

      selfServeCheckbox.addEventListener("change", selfServeChanged);
      selfServeChanged();
    }
  });

  initDeliveryAddressesOnLoad(props);

  initMemorialUpdateEdits();
}

export function initMemorialPrivateFavorites() {
  initEditDropdowns();
}

function initUserBlocking(props) {
  const handleListUpdate = (searchEvent) => {
    const searchField = searchEvent.target;
    const container = searchField.closest(".list-container");
    const scrollContainer = container.querySelector(".user-scroll-container");
    const emptyElement = container.querySelector(".empty");
    const noSearch = emptyElement.querySelector(".no-search");
    const emptySearch = emptyElement.querySelector(".empty-search");

    let showEmpty = true;

    container.querySelectorAll(".blockable-user").forEach((user) => {
      const searchContent = user.getAttribute("data-search-string").toLowerCase();
      if (searchContent.includes(searchField.value.toLowerCase())) {
        showEmpty = false;
        user.classList.remove("d-none");
      } else {
        user.classList.add("d-none");
      }
    });

    if (showEmpty) {
      emptyElement.classList.remove("d-none");
      scrollContainer.classList.add("d-none");

      if (searchField.value) {
        emptySearch.classList.remove("d-none");
        noSearch.classList.add("d-none");
      } else {
        emptySearch.classList.add("d-none");
        noSearch.classList.remove("d-none");
      }
    } else {
      emptyElement.classList.add("d-none");
      scrollContainer.classList.remove("d-none");
    }
  };

  const userSearchingContainers = document.querySelectorAll(
    ".user-blocking-container > div",
  );
  if (userSearchingContainers) {
    userSearchingContainers.forEach((container) => {
      const searchField = container.querySelector(".search-field");
      searchField.addEventListener("keyup", handleListUpdate);
      searchField.addEventListener("paste", handleListUpdate);
      handleListUpdate({ target: searchField });
    });
  }

  const userBlockButtons = document.querySelectorAll(".block-user-button");
  const blockedUserList = document.querySelector(
    ".blocked-user-list .user-scroll-container",
  );
  const unblockedUserList = document.querySelector(
    ".unblocked-user-list .user-scroll-container",
  );

  userBlockButtons.forEach((blockBtn) => {
    blockBtn.addEventListener("click", async () => {
      const revert = handleAjaxFormSubmitProtection(blockBtn);
      const isBlocking = blockBtn.getAttribute("data-block") === "1";
      const listItem = blockBtn.closest(".list-group-item");

      try {
        const response = await requests.post(props.blockUrl, {
          user: blockBtn.getAttribute("data-block-user"),
          block: isBlocking,
        });

        if (!response.ok) {
          throw new ResponseError(response);
        }
      } catch {
        revert();
        return;
      }

      blockBtn.innerText = isBlocking ? "Unblock" : "Block";
      blockBtn.setAttribute("data-block", isBlocking ? "0" : "1");
      const list = isBlocking ? blockedUserList : unblockedUserList;
      list.appendChild(listItem);

      document.querySelectorAll(".search-field").forEach((f) => {
        handleListUpdate({ target: f });
      });
      revert();
    });
  });
}

function initRelatedPhotoUpload(props) {
  const editRelatedPhotoLinks = document.querySelectorAll(".edit-related-photo-link");
  const uploadRelatedPhotoPersonField = document.querySelector(
    'input[name="_upload_related_person_photo"]',
  );
  const uploadForm = uploadRelatedPhotoPersonField.closest("form");
  const fileInput = uploadForm.querySelector('input[type="file"]');

  editRelatedPhotoLinks.forEach((link) => {
    link.addEventListener("click", () => {
      fileInput.dataset.toPerson = link.dataset.toPerson;
      if (link.dataset.memorial) {
        fileInput.dataset.memorial = link.dataset.memorial;
      } else {
        fileInput.removeAttribute("data-memorial");
      }
      fileInput.click();
    });
  });

  initPhotoCropper({
    photoInput: document.querySelector(
      `#${props.relatedPhotoForm.fields.rawPublicProfilePhoto}`,
    ),
    xInput: document.querySelector(`#${props.relatedPhotoForm.fields.x}`),
    yInput: document.querySelector(`#${props.relatedPhotoForm.fields.y}`),
    heightInput: document.querySelector(`#${props.relatedPhotoForm.fields.height}`),
    widthInput: document.querySelector(`#${props.relatedPhotoForm.fields.width}`),
    cropperOptions: {
      viewMode: 0,
      cropBoxMovable: false,
      cropBoxResizable: false,
      dragMode: "move",
      minCropBoxWidth: 373,
      minCropBoxHeight: 280,
      minCanvasHeight: 280,
      aspectRatio: 1,
    },
    cropButtonSubmits: true,
    onComplete: () => {
      uploadRelatedPhotoPersonField.value = fileInput.dataset.toPerson;
      uploadForm.submit();
    },
    onModalShown: (cropperInstance) => {
      if (fileInput.dataset.memorial) {
        cropperInstance.setAspectRatio(4 / 3);
      } else {
        cropperInstance.setAspectRatio(1.0);
      }
    },
  });
}

function initRelativeAwaitingConfirmationTips() {
  let firstSection = null;
  const awaitingConfirmationSectionAlerts = document.querySelectorAll(
    "a.awaiting-confirmation-alert",
  );
  const awaitingConfirmationSections = document.querySelectorAll(".added-by-others");
  if (awaitingConfirmationSections.length) {
    firstSection = awaitingConfirmationSections[0];
  }

  awaitingConfirmationSectionAlerts.forEach((awaitingConfirmationSectionAlert) => {
    if (awaitingConfirmationSectionAlert && firstSection) {
      awaitingConfirmationSectionAlert.href = "#";
      awaitingConfirmationSectionAlert.addEventListener("click", () => {
        scrollInMemorialPrivateContentContainer(firstSection);
      });
    }
  });
}

export function initMemorialPrivateFamily(props) {
  initRelationshipForms(props);
  initRelatedPhotoUpload(props);
  initRelativeAwaitingConfirmationTips();
  initDeceasedSearchAutocomplete(props.followerFormProps);
}

export function initMemorialPrivateEvents(props) {
  const deleteEventCallbacks = [];
  initEditEvents(props, deleteEventCallbacks);
  initEditEventsOnLoad(props, deleteEventCallbacks);
  initTimezoneSelector(props.forms.timezone.fields.timezone);
}

export function initMemorialObituary(props) {
  if (props && props.placeholderOverride) {
    const obituaryTextArea = document.querySelector(".edit-obituary textarea");
    if (obituaryTextArea) {
      obituaryTextArea.setAttribute("placeholder", props.placeholderOverride);
    }
  }

  const autoSaves = [];
  document.querySelectorAll(".el-autosave").forEach((el) => {
    autoSaves.push(new AutoSaveTextField(el));
  });

  const showTemplateSelectorButton = document.querySelector(".show-templates");
  const templateSelectorContainer = document.querySelector(
    ".obituary-templates-container-container",
  );

  if (showTemplateSelectorButton && templateSelectorContainer) {
    templateSelectorContainer.classList.add("d-none");

    showTemplateSelectorButton.addEventListener("click", () => {
      templateSelectorContainer.classList.remove("d-none");
    });
  }
}

export function initMemorialNote(props) {
  // Memorial note template selection
  const noteInput = document.getElementById(props.noteInputId);
  const noteTemplatesContainer = document.querySelector(".note-templates-container");
  const templatesData = props.noteTemplates;
  initUGCTemplates(
    noteTemplatesContainer,
    noteInput,
    templatesData,
    null,
    true,
    null,
    false,
  );
}

function initPasswordProtect() {
  const passwordProtectToggle = document.querySelector(
    ".password-protect-toggle input",
  );
  const passwordProtectFieldContainer = document.querySelector(
    ".password-field-container",
  );
  if (passwordProtectToggle && passwordProtectFieldContainer) {
    passwordProtectToggle.addEventListener("change", () => {
      if (passwordProtectToggle.checked) {
        passwordProtectFieldContainer.classList.remove("d-none");
      } else {
        passwordProtectFieldContainer.classList.add("d-none");
      }
    });
  }
}

export async function initMemorialPrivatePremium(props) {
  initPasswordProtect();
  initMemorialGiftPremiumControls(props);
  initUserBlocking(props);

  await initPayableCheckout(props);
  await initAddPaymentMethod(props);

  document.body.addEventListener("htmx:afterSettle", (evt) => {
    const premiumForm =
      evt.detail.elt.id === "premium-checkout" ? evt.detail.elt : null;
    if (premiumForm) {
      registerFormSubmitProtection(premiumForm);
    }
  });
}

export function initMemorialPrivateSettings(props) {
  initPasswordProtect();

  document.querySelectorAll(".remove-manager-link").forEach((link) => {
    link.addEventListener("click", async () => {
      if (window.confirm("Are you sure you want to remove this manager?")) {
        const deleteUrl = link.getAttribute("data-delete-url");

        link.innerHTML = "Removing...";
        try {
          const response = await requests.delete(deleteUrl);
          if (!response.ok) {
            throw new ResponseError(response);
          }
        } catch {
          link.innerHTML = "Removing failed.";
          return;
        }

        link.closest(".list-group-item").remove();
      }
    });
  });

  const themeOptions = document.querySelectorAll(".memorial-theme-option");
  const themeNames = Array.from(themeOptions).map(
    (themeOption) => themeOption.dataset.themeName,
  );
  themeOptions.forEach((themeOption) => {
    const radio = themeOption.querySelector('input[type="radio"]');
    if (radio) {
      radio.addEventListener("change", () => {
        themeNames.forEach((name) => {
          document.body.classList.toggle(
            name,
            name === radio.closest(".memorial-theme-option").dataset.themeName &&
              radio.checked,
          );
        });
        document.body.classList.add("theme");
      });
    }
  });

  initTimezoneSelector(props.forms.timezone.fields.timezone);
}

/**
 * Initialize the legacy `/memorial/<slug>/edit/` page. The Django view provides a map
 * from "legacy" hash points (#edit-family, for example) to the reversed URLs for their
 * new-private-mode view counterparts. This JS simply takes the `hash` from the current
 * location (if it exists) and issues a client-side redirect to that URL, falling back
 * to a default URL `/memorial/<slug>/` if nothing exists in that map.
 * This has to be done in JS/client-side because not all browsers send #hash fragments
 * to the server on request, and as such we can't issue the redirect logic from inside
 * of Django.
 *
 * @param {object} props - Props from Django
 */
export function initLegacyEdit(props) {
  window.location.href = props.urls[window.location.hash] || props.defaultUrl;
}

export function initFeedbackForm(props) {
  const feedbackReasonField = document.getElementById(props.reasonCommentFieldId);
  const feedbackReasonChoices = document.querySelectorAll(
    `input[name="${props.reasonInputName}"]`,
  );

  feedbackReasonChoices.forEach((choice) => {
    choice.addEventListener("change", () => {
      feedbackReasonField.setAttribute(
        "placeholder",
        props.reasonCommentFormPrompts[choice.value],
      );
      feedbackReasonField.classList.remove("d-none");
    });
  });
}

export function initMemorialPrivateNoteAndUpdates(props) {
  const noteInput = document.getElementById(props.noteInputId);
  const noteTemplatesContainer = document.querySelector(".note-templates-container");
  const templatesData = props.noteTemplates;
  initUGCTemplates(
    noteTemplatesContainer,
    noteInput,
    templatesData,
    null,
    true,
    null,
    false,
  );

  initMemorialUpdateEdits();
}

export function initMemorialPrivateBelongings(props) {
  initProductPhotoBlur();

  const editorContainer = document.querySelector(".modal-belonging-editor");
  if (editorContainer) {
    const invokeEditor = (editor) => (event) => {
      if (event.currentTarget.classList.contains("add-belonging")) {
        editor.showForBelonging(null);
      } else {
        const { name, description, isPublished, isClaimed, belonging, photo } =
          event.currentTarget.dataset;

        editor.showForBelonging({
          name,
          description,
          photo,
          isPublished: isPublished === "true",
          isClaimed: isClaimed === "true",
          id: belonging,
        });
      }
    };

    const onBelongingChanged = (newBelonging, editor) => {
      const listedBelongings = document.querySelector(
        ".listed-belongings .belongings-list",
      );
      const unlistedBelongings = document.querySelector(
        ".unlisted-belongings .belongings-list",
      );

      const existing = document.querySelector(
        `.belonging[data-belonging="${newBelonging.id}"]`,
      );

      const belongingsList = newBelonging.is_published
        ? listedBelongings
        : unlistedBelongings;

      let insertBeforeTarget = belongingsList.querySelector(".belonging.add-belonging");

      if (existing) {
        if (existing.parentElement === belongingsList) {
          insertBeforeTarget = existing.nextSibling;
        }
        existing.remove();
      }

      const newItemWrapper = document.createElement("div");
      const truncatedDescription = `${newBelonging.description.slice(0, 30)}${newBelonging.description.length > 30 ? "..." : ""}`;
      newItemWrapper.innerHTML = props.emptyListItem
        .replace(/__name__/g, newBelonging.name)
        .replace(/__description__/g, truncatedDescription)
        .replace(/__photo__/g, newBelonging.photo);
      const listItem = newItemWrapper.firstChild;
      listItem.classList.toggle("claimed", newBelonging.is_claimed);
      listItem.dataset.belonging = newBelonging.id;
      listItem.dataset.name = newBelonging.name;
      listItem.dataset.description = newBelonging.description;
      listItem.dataset.isPublished = newBelonging.is_published ? "true" : "false";
      listItem.dataset.isClaimed = newBelonging.is_claimed ? "true" : "false";

      const claimedBadge = listItem.querySelector(".claimed-badge");
      if (claimedBadge) {
        claimedBadge.classList.toggle("d-none", !newBelonging.is_claimed);
      }

      belongingsList.insertBefore(listItem, insertBeforeTarget);

      listItem.addEventListener("click", invokeEditor(editor));
    };

    const belongingEditor = new BelongingEditor(editorContainer, {
      ...props.editorProps,
      onBelongingChanged,
    });

    document.querySelectorAll(".belonging").forEach((belongingItem) => {
      belongingItem.addEventListener("click", invokeEditor(belongingEditor));
    });
  }

  const notifyCheckboxContainerForm = document.querySelector(
    ".notify-subscribers-toggle",
  );
  if (notifyCheckboxContainerForm) {
    const notifyCheckbox = notifyCheckboxContainerForm.querySelector(
      'input[type="checkbox"]',
    );
    notifyCheckbox.addEventListener("click", () => {
      notifyCheckbox.parentElement.classList.add("d-none");

      const spinnerContainer =
        notifyCheckboxContainerForm.querySelector(".spinner-container");
      spinnerContainer.classList.remove("d-none");

      notifyCheckboxContainerForm.submit();
    });
  }
}

export function initPhotoArchiveRequest() {
  const photoArchiveRequestError = document.querySelector(
    ".photo-archive-request-error",
  );
  if (photoArchiveRequestError) {
    document.body.addEventListener("htmx:responseError", () => {
      photoArchiveRequestError.classList.remove("d-none");
    });
  }
}

export function initMemorialPrivateCommunityContent(props) {
  initUserBlocking(props);
  initPhotoArchiveRequest();
}

export function initAIObituaryComposerForm() {
  const composerForm = document.querySelector(".obituary-composer-form");

  const scrollFix = () => {
    if (composerForm) {
      const editContent = document.querySelector(".edit-memorial-content");
      if (editContent) {
        setTimeout(() => {
          editContent.parentNode.parentNode.scrollIntoView(true);
        }, 100);
      } else {
        document.body.scrollIntoView(true);
      }
    }
  };

  document.body.addEventListener("htmx:beforeRequest", () => {
    scrollFix();
  });

  scrollFix();

  if (composerForm) {
    const locationAutocompleteFields = composerForm.querySelectorAll(
      '[data-google-autocomplete="location"]',
    );
    const placeAutocompleteFields = composerForm.querySelectorAll(
      '[data-google-autocomplete="place"]',
    );

    if (typeof window.google === "object" && typeof window.google.maps === "object") {
      locationAutocompleteFields.forEach((field) => {
        initGoogleAutocomplete({ element: field, options: { types: ["(cities)"] } });
      });

      placeAutocompleteFields.forEach((field) => {
        initGoogleAutocomplete({
          element: field,
          options: {
            types: ["establishment", "geocode"],
          },
        });
      });
    }

    const handleSchoolNameInput = (event) => {
      const existingNameInputs = Array.from(
        document.querySelectorAll(".school-name-input"),
      );

      if (event.currentTarget === existingNameInputs.slice(-1)[0]) {
        if (event.currentTarget.value) {
          const container = event.currentTarget.closest(".d-flex");
          const cloned = container.cloneNode(true);
          const nameInput = cloned.querySelector(".school-name-input");
          nameInput.value = "";
          nameInput.setAttribute("id", `id_school_name_${existingNameInputs.length}`);
          nameInput.setAttribute("name", `school_name_${existingNameInputs.length}`);
          nameInput.addEventListener("change", handleSchoolNameInput);

          initGoogleAutocomplete({
            element: nameInput,
            options: {
              types: ["establishment", "geocode"],
            },
          });

          const yearInput = cloned.querySelector(".school-year-input");
          yearInput.value = "";
          yearInput.setAttribute("id", `id_school_year_${existingNameInputs.length}`);
          yearInput.setAttribute("name", `school_year_${existingNameInputs.length}`);

          container.parentNode.appendChild(cloned);
        }
      } else {
        const container = event.currentTarget.closest(".d-flex");
        const parent = container.parentNode;
        container.remove();

        // Renumber the inputs.
        parent.querySelectorAll(":scope > .d-flex").forEach((childNode, idx) => {
          const nameInput = childNode.querySelector(".school-name-input");
          nameInput.setAttribute("id", `id_school_name_${idx}`);
          nameInput.setAttribute("name", `school_name_${idx}`);

          const yearInput = childNode.querySelector(".school-year-input");
          yearInput.setAttribute("id", `id_school_year_${idx}`);
          yearInput.setAttribute("name", `school_year_${idx}`);
        });
      }
    };

    const schoolNameInputs = document.querySelectorAll(".school-name-input");

    schoolNameInputs.forEach((input) => {
      input.addEventListener("change", handleSchoolNameInput);
    });
  }
}

import DeceasedPhotoUploader from "../../components/DeceasedPhotoUploader";
import config from "../../core/config";

export default function initMemorialOnboardingPhoto(props) {
  const uploader = new DeceasedPhotoUploader(props.deceasedPhotoUploaderProps);
  const selectPhotosButton = document.querySelector(".start-select-photos");
  selectPhotosButton.addEventListener("click", () => {
    uploader.show();
  });

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: config.facebookAppId,
      autoLogAppEvents: true,
      xfbml: true,
      version: config.facebookApiVersion,
    });
  };
}

import Collapse from "bootstrap/js/dist/collapse";

import initBusinessAutocomplete from "./BusinessAutocomplete";
import { handleAjaxFormSubmitProtection, isHidden } from "../core/utils";
import requests, { ResponseError } from "../core/requests";

/**
 * Update a BusinessAutocomplete component with new settings.
 *
 * We're defaulting addSubmitListener to false so we can manually add the submit
 * listener for each autocomplete during initialization to prevent a new listener from
 * being added each time this function is called.
 *
 * @param {object} props - the autocomplete page props
 * @param {boolean} includeInitialValue - whether or not to include the initial value
 * @param {string} businessPlaceholder - the placeholder value for the given business type
 * @param {boolean} [addSubmitListener] - whether or not to add a submit listener
 */
function updateBusinessAutocomplete(
  props,
  includeInitialValue,
  businessPlaceholder,
  addSubmitListener = false,
) {
  if (props && props.businessIdentifierInputId) {
    let initialAutocompleteData = {};
    let initialPendingData = false;
    if (includeInitialValue) {
      initialAutocompleteData = props.initialAutocompleteData;
      initialPendingData = props.initialPendingData;
    }

    const mergedProps = {
      ...props,
      businessPlaceholder,
      initialPendingData,
      initialAutocompleteData,
      addSubmitListener,
    };

    const businessIdentifierInput = document.getElementById(
      props.businessIdentifierInputId,
    );
    if (businessIdentifierInput && businessIdentifierInput.tomselect) {
      businessIdentifierInput.tomselect.destroy();
    }

    initBusinessAutocomplete(mergedProps);
  }
}

/**
 * Given an array/nodelist of review-input-wrapping container elements, clear all the
 * inputs within.
 *
 * @param {NodeList | Array<Element>} reviewInputWrappers - the containers wrapping
 *     the review inputs.
 */
function clearReviewInputs(reviewInputWrappers) {
  reviewInputWrappers.forEach((reviewInputWrapper) => {
    const stars = reviewInputWrapper.querySelectorAll('input[type="radio"]');

    stars.forEach((item) => {
      item.checked = false;
    });

    const reviewTextarea = reviewInputWrapper.querySelector("textarea");
    if (reviewTextarea) {
      reviewTextarea.value = "";
    }
    reviewInputWrapper.parentNode.parentNode
      .querySelectorAll(":scope .errorlist")
      .forEach((errorlist) => errorlist.classList.add("d-none"));
  });
}

/**
 * Initialize the business reviews section.
 *
 * @param {object} props - the page props.
 */
function initBusinessReviewSection(props) {
  const reviewSection = document.querySelector(".business-association-review-section");
  const reviewSectionHeader = document.querySelector(".review-form-header");
  const funeralHomeReviewSection = document.querySelector(
    ".funeral-home-review-section",
  );
  const funeralHomeSlugInput = document.querySelector(
    `#${props.funeralHomeSearch.businessIdentifierInputId}`,
  );
  const funeralHomePendingCheckbox = document.querySelector(
    `#${props.funeralHomeSearch.businessPending}`,
  );
  const funeralHomeReviewSubmitBtn = funeralHomeReviewSection.querySelector("button");
  const cemeteryReviewSection = document.querySelector(".cemetery-review-section");
  const cemeterySlugInput = document.querySelector(
    `#${props.cemeterySearch.businessIdentifierInputId}`,
  );
  const cemeteryPendingCheckbox = document.querySelector(
    `#${props.cemeterySearch.businessPending}`,
  );
  const cemeteryReviewSubmitBtn = cemeteryReviewSection.querySelector("button");
  const hospiceReviewSection = document.querySelector(".hospice-review-section");
  const hospiceSlugInput = document.querySelector(
    `#${props.hospiceSearch.businessIdentifierInputId}`,
  );
  const hospicePendingCheckbox = document.querySelector(
    `#${props.hospiceSearch.businessPending}`,
  );
  const hospiceReviewSubmitBtn = hospiceReviewSection.querySelector("button");
  const donationOrgReviewSection = document.querySelector(
    ".donation-org-review-section",
  );
  const donationOrgSlugInput = document.querySelector(
    `#${props.donationOrgSearch.businessIdentifierInputId}`,
  );
  const donationOrgPendingCheckbox = document.querySelector(
    `#${props.donationOrgSearch.businessPending}`,
  );
  const donationOrgReviewSubmitBtn = donationOrgReviewSection.querySelector("button");
  const deathDoulaReviewSection = document.querySelector(".death-doula-review-section");
  const deathDoulaSlugInput = document.querySelector(
    `#${props.deathDoulaSearch.businessIdentifierInputId}`,
  );
  const deathDoulaPendingCheckbox = document.querySelector(
    `#${props.deathDoulaSearch.businessPending}`,
  );
  const deathDoulaReviewSubmitBtn = deathDoulaReviewSection.querySelector("button");

  /**
   * Show the business review form.
   */
  const showBusinessReviewForm = () => {
    const selectedFuneralHome =
      funeralHomeSlugInput.tomselect.options[funeralHomeSlugInput.tomselect.getValue()];
    const selectedCemetery =
      cemeterySlugInput.tomselect.options[cemeterySlugInput.tomselect.getValue()];
    const selectedHospice =
      hospiceSlugInput.tomselect.options[hospiceSlugInput.tomselect.getValue()];
    const selectedDonationOrg =
      donationOrgSlugInput.tomselect.options[donationOrgSlugInput.tomselect.getValue()];
    const selectedDeathDoula =
      deathDoulaSlugInput.tomselect.options[deathDoulaSlugInput.tomselect.getValue()];
    const funeralHomesReviewed = props.businessesUserHasReviewed.funeralHomes;
    const cemeteriesReviewed = props.businessesUserHasReviewed.cemeteries;
    const hospicesReviewed = props.businessesUserHasReviewed.hospices;
    const donationOrgsReviewed = props.businessesUserHasReviewed.donationOrgs;
    const deathDoulasReviewed = props.businessesUserHasReviewed.deathDoulas;
    let showFuneralHomeReviewForm = false;
    let showCemeteryReviewForm = false;
    let showHospiceReviewForm = false;
    let showDonationOrgReviewForm = false;
    let showDeathDoulaReviewForm = false;

    if (
      selectedFuneralHome &&
      selectedFuneralHome.id &&
      !selectedFuneralHome.created &&
      !funeralHomePendingCheckbox.checked
    ) {
      const funeralHomeSlug = selectedFuneralHome.id;
      const funeralHomeName = selectedFuneralHome.text;
      // check if user has not yet reviewed business before showing review form
      showFuneralHomeReviewForm = !funeralHomesReviewed.includes(funeralHomeSlug);
      if (showFuneralHomeReviewForm) {
        funeralHomeReviewSection.classList.remove("d-none");
        const nameSpan = funeralHomeReviewSection.querySelector("span");
        if (nameSpan) {
          nameSpan.innerText = funeralHomeName;
        }
      } else {
        funeralHomeReviewSection.classList.add("d-none");
      }
    } else {
      funeralHomeReviewSection.classList.add("d-none");
    }

    if (
      selectedCemetery &&
      selectedCemetery.id &&
      !selectedCemetery.created &&
      !cemeteryPendingCheckbox.checked
    ) {
      const cemeterySlug = selectedCemetery.id;
      const cemeteryName = selectedCemetery.text;
      // check if user has not yet reviewed business before showing review form
      showCemeteryReviewForm = !cemeteriesReviewed.includes(cemeterySlug);
      if (showCemeteryReviewForm) {
        cemeteryReviewSection.classList.remove("d-none");
        const nameSpan = cemeteryReviewSection.querySelector("span");
        if (nameSpan) {
          nameSpan.innerText = cemeteryName;
        }
      } else {
        cemeteryReviewSection.classList.add("d-none");
      }
    } else {
      cemeteryReviewSection.classList.add("d-none");
    }

    if (
      selectedHospice &&
      selectedHospice.id &&
      !selectedHospice.created &&
      !hospicePendingCheckbox.checked
    ) {
      const hospiceSlug = selectedHospice.id;
      const hospiceName = selectedHospice.text;
      // check if user has not yet reviewed business before showing review form
      showHospiceReviewForm = !hospicesReviewed.includes(hospiceSlug);
      if (showHospiceReviewForm) {
        hospiceReviewSection.classList.remove("d-none");
        const nameSpan = hospiceReviewSection.querySelector("span");
        if (nameSpan) {
          nameSpan.innerText = hospiceName;
        }
      } else {
        hospiceReviewSection.classList.add("d-none");
      }
    } else {
      hospiceReviewSection.classList.add("d-none");
    }

    if (
      selectedDonationOrg &&
      selectedDonationOrg.id &&
      !selectedDonationOrg.created &&
      !donationOrgPendingCheckbox.checked
    ) {
      const donationOrgSlug = selectedDonationOrg.id;
      const donationOrgName = selectedDonationOrg.text;
      // check if user has not yet reviewed business before showing review form
      showDonationOrgReviewForm = !donationOrgsReviewed.includes(donationOrgSlug);
      if (showDonationOrgReviewForm) {
        donationOrgReviewSection.classList.remove("d-none");
        const nameSpan = donationOrgReviewSection.querySelector("span");
        if (nameSpan) {
          nameSpan.innerText = donationOrgName;
        }
      } else {
        donationOrgReviewSection.classList.add("d-none");
      }
    } else {
      donationOrgReviewSection.classList.add("d-none");
    }

    if (
      selectedDeathDoula &&
      selectedDeathDoula.id &&
      !selectedDeathDoula.created &&
      !deathDoulaPendingCheckbox.checked
    ) {
      const deathDoulaSlug = selectedDeathDoula.id;
      const deathDoulaName = selectedDeathDoula.text;
      // check if user has not yet reviewed business before showing review form
      showDeathDoulaReviewForm = !deathDoulasReviewed.includes(deathDoulaSlug);
      if (showDeathDoulaReviewForm) {
        deathDoulaReviewSection.classList.remove("d-none");
        const nameSpan = deathDoulaReviewSection.querySelector("span");
        if (nameSpan) {
          nameSpan.innerText = deathDoulaName;
        }
      } else {
        deathDoulaReviewSection.classList.add("d-none");
      }
    } else {
      deathDoulaReviewSection.classList.add("d-none");
    }

    if (
      showFuneralHomeReviewForm ||
      showCemeteryReviewForm ||
      showHospiceReviewForm ||
      showDonationOrgReviewForm ||
      showDeathDoulaReviewForm
    ) {
      reviewSection.classList.remove("d-none");
      reviewSectionHeader.classList.remove("d-none");
    } else {
      reviewSection.classList.add("d-none");
    }
  };

  /**
   * Show the business review success message.
   *
   * @param {string} businessType - the type of business
   * @param {string} businessName - the name of the business
   * @param {string} businessSlug - the business slug
   */
  const showBusinessReviewSuccessState = (businessType, businessName, businessSlug) => {
    const successMessage = document.querySelector(".review-success-message");

    if (businessType === props.funeralHomeSearch.businessTypeName) {
      funeralHomeReviewSection.classList.add("d-none");
      props.businessesUserHasReviewed.funeralHomes.push(businessSlug);
    } else if (businessType === props.cemeterySearch.businessTypeName) {
      cemeteryReviewSection.classList.add("d-none");
      props.businessesUserHasReviewed.cemeteries.push(businessSlug);
    } else if (businessType === props.hospiceSearch.businessTypeName) {
      hospiceReviewSection.classList.add("d-none");
      props.businessesUserHasReviewed.hospices.push(businessSlug);
    } else if (businessType === props.donationOrgSearch.businessTypeName) {
      donationOrgReviewSection.classList.add("d-none");
      props.businessesUserHasReviewed.donationOrgs.push(businessSlug);
    } else if (businessType === props.deathDoulaSearch.businessTypeName) {
      deathDoulaReviewSection.classList.add("d-none");
      props.businessesUserHasReviewed.deathDoulas.push(businessSlug);
    }

    reviewSectionHeader.classList.add("d-none");

    const business1 = document.querySelector(".business-1");
    const business2 = document.querySelector(".business-2");
    const business2Wrapper = document.querySelector(".business-2-wrapper");

    if (isHidden(successMessage)) {
      successMessage.classList.remove("d-none");
      business1.innerText = businessName;
    } else {
      business2Wrapper.classList.remove("d-none");
      business2.innerText = businessName;
    }
  };

  /**
   * Initialize the automatically-showing submit button on the review forms.
   */
  const initExpandableSubmitButton = () => {
    const reviewForms = document.querySelectorAll(".async-review-form");
    reviewForms.forEach((reviewForm) => {
      const collapse = new Collapse(
        reviewForm.querySelector(".collapsed-submit-wrapper"),
        { toggle: false },
      );
      reviewForm.querySelector("textarea").addEventListener("focus", () => {
        collapse.show();
      });

      reviewForm.querySelector("textarea").addEventListener("focusout", (event) => {
        if (!event.currentTarget.value) {
          collapse.hide();
        }
      });
    });
  };

  /**
   * Submit a business review (async).
   *
   * @param {HTMLElement} submitBtn - the triggering button.
   * @param {string} businessType - the business type
   * @returns {Promise<void>} - An ignorable void promise
   */
  const submitBusinessReview = async (submitBtn, businessType) => {
    const submitButtonRestore = handleAjaxFormSubmitProtection(submitBtn);
    const url = submitBtn.dataset.submitUrl;
    const reviewInputWrapper = submitBtn
      .closest(".review-form-container")
      .querySelector(".review-input-wrapper");

    const text = reviewInputWrapper.querySelector("textarea").value;
    const rating = reviewInputWrapper.querySelector(
      'input[type="radio"]:checked',
    ).value;
    const searchParams = new URLSearchParams(window.location.search);
    const flow = searchParams.get("association-flow");
    let slug = "";
    let name = "";
    if (businessType === props.funeralHomeSearch.businessTypeName) {
      slug = funeralHomeSlugInput.tomselect.getValue();
      name =
        funeralHomeSlugInput.tomselect.options[
          funeralHomeSlugInput.tomselect.getValue()
        ].text;
    } else if (businessType === props.cemeterySearch.businessTypeName) {
      slug = cemeterySlugInput.tomselect.getValue();
      name =
        cemeterySlugInput.tomselect.options[cemeterySlugInput.tomselect.getValue()]
          .text;
    } else if (businessType === props.hospiceSearch.businessTypeName) {
      slug = hospiceSlugInput.tomselect.getValue();
      name =
        hospiceSlugInput.tomselect.options[hospiceSlugInput.tomselect.getValue()].text;
    } else if (businessType === props.donationOrgSearch.businessTypeName) {
      slug = donationOrgSlugInput.tomselect.getValue();
      name =
        donationOrgSlugInput.tomselect.options[
          donationOrgSlugInput.tomselect.getValue()
        ].text;
    } else if (businessType === props.deathDoulaSearch.businessTypeName) {
      slug = deathDoulaSlugInput.tomselect.getValue();
      name =
        deathDoulaSlugInput.tomselect.options[deathDoulaSlugInput.tomselect.getValue()]
          .text;
    }

    try {
      const response = await requests.post(url, {
        "review-text": text,
        "review-rating": rating,
        slug,
        flow,
        businessType,
      });

      if (!response.ok) {
        throw new ResponseError(response);
      }

      clearReviewInputs([reviewInputWrapper]);
      showBusinessReviewSuccessState(businessType, name, slug);
      submitButtonRestore();
    } catch (err) {
      window.Rollbar.error("Error while submitting business review", err);
      reviewInputWrapper.parentNode.parentNode
        .querySelector(".errorlist")
        .classList.remove("d-none");
      submitButtonRestore();
    }
  };

  funeralHomeSlugInput.addEventListener("change", showBusinessReviewForm);
  funeralHomePendingCheckbox.addEventListener("change", showBusinessReviewForm);
  cemeterySlugInput.addEventListener("change", showBusinessReviewForm);
  cemeteryPendingCheckbox.addEventListener("change", showBusinessReviewForm);
  hospiceSlugInput.addEventListener("change", showBusinessReviewForm);
  hospicePendingCheckbox.addEventListener("change", showBusinessReviewForm);
  donationOrgSlugInput.addEventListener("change", showBusinessReviewForm);
  donationOrgPendingCheckbox.addEventListener("change", showBusinessReviewForm);
  deathDoulaSlugInput.addEventListener("change", showBusinessReviewForm);
  deathDoulaPendingCheckbox.addEventListener("change", showBusinessReviewForm);

  funeralHomeReviewSubmitBtn.addEventListener("click", () => {
    submitBusinessReview(
      funeralHomeReviewSubmitBtn,
      props.funeralHomeSearch.businessTypeName,
    );
  });

  cemeteryReviewSubmitBtn.addEventListener("click", () => {
    submitBusinessReview(
      cemeteryReviewSubmitBtn,
      props.cemeterySearch.businessTypeName,
    );
  });

  hospiceReviewSubmitBtn.addEventListener("click", () => {
    submitBusinessReview(hospiceReviewSubmitBtn, props.hospiceSearch.businessTypeName);
  });

  donationOrgReviewSubmitBtn.addEventListener("click", () => {
    submitBusinessReview(
      donationOrgReviewSubmitBtn,
      props.donationOrgSearch.businessTypeName,
    );
  });

  deathDoulaReviewSubmitBtn.addEventListener("click", () => {
    submitBusinessReview(
      deathDoulaReviewSubmitBtn,
      props.deathDoulaSearch.businessTypeName,
    );
  });

  showBusinessReviewForm();
  initExpandableSubmitButton();
}

/**
 * Clear all the inputs for business association.
 *
 * @param {object} props - the page props
 */
function clearAllBusinessAssociationInputs(props) {
  updateBusinessAutocomplete(
    props.cemeterySearch,
    false,
    props.cemeterySearch.businessPlaceholder,
  );
  updateBusinessAutocomplete(
    props.donationOrgSearch,
    false,
    props.donationOrgSearch.businessPlaceholder,
  );
  updateBusinessAutocomplete(
    props.seaBurialProviderSearch,
    false,
    props.seaBurialProviderSearch.businessPlaceholder,
  );

  const businessSelectInputs = [
    document.getElementById(props.cemeterySearch.businessIdentifierInputId),
    document.getElementById(props.donationOrgSearch.businessIdentifierInputId),
    document.getElementById(props.seaBurialProviderSearch.businessIdentifierInputId),
  ];

  businessSelectInputs.forEach((item) => {
    if (item && item.tomselect) {
      item.tomselect.clear();
      item.tomselect.clearOptions();
      item.tomselect.enable();
    }
  });

  const locationInputs = [
    document.getElementById("id_business_association-remains_location_type"),
    document.getElementById("id_business_association-remains_location"),
  ];

  locationInputs.forEach((item) => {
    item.removeAttribute("disabled");
    item.classList.remove("disabled");
  });

  const stringInputs = [
    document.getElementById(props.funeralHomeSearch.businessStrInputId),
    document.getElementById(props.cemeterySearch.businessStrInputId),
    document.getElementById(props.donationOrgSearch.businessStrInputId),
    document.getElementById(props.seaBurialProviderSearch.businessStrInputId),
    document.getElementById("id_business_association-remains_location"),
  ];

  stringInputs.forEach((item) => {
    if (item && item.tomselect) {
      item.tomselect.clear();
    } else {
      item.value = "";
    }
  });

  document.getElementById("id_business_association-remains_location").value = "";

  const pendingInputs = [
    document.getElementById(props.funeralHomeSearch.businessPending),
    document.getElementById(props.cemeterySearch.businessPending),
    document.getElementById(props.donationOrgSearch.businessPending),
    document.getElementById(props.seaBurialProviderSearch.businessPending),
    document.getElementById("id_business_association-remains_location_type_pending"),
    document.getElementById("id_business_association-remains_location_pending"),
  ];

  pendingInputs.forEach((item) => {
    item.checked = false;
  });

  const locationMetadataInput = document.getElementById(
    "id_business_association-remains_location_metadata",
  );
  locationMetadataInput.value = "{}";
  document.querySelector(".location-input-group .dark-gray-label").innerText =
    "Location";
  document
    .getElementById("id_business_association-remains_location")
    .setAttribute("placeholder", "Ex: 123 Green street, San Francisco, CA");

  const locationTypeSelect = document.getElementById(
    "id_business_association-remains_location_type",
  );
  locationTypeSelect.value = "";

  document
    .querySelector(".business-association-review-section")
    .classList.add("d-none");
  clearReviewInputs(document.querySelectorAll(".review-input-wrapper"));
}

/**
 * Show or hide the funeral home selection input group.
 *
 * @param {Element} inputGroup - the <div> that is the FH selection input group.
 * @param {boolean} isOnboarding - true if we're in onboarding mode.
 * @param {boolean} shouldShow - true if we're meant to show; false if we're meant to hide.
 */
function toggleFuneralHome(inputGroup, isOnboarding, shouldShow) {
  inputGroup.classList.toggle("d-none", !isOnboarding && !shouldShow);
}

/**
 * Initialize the business association form. Lots of autocomplete, lots of conditional
 * show/hide of subforms.
 *
 * @param {object} props - the page props
 */
export default function initBusinessAssociationForm(props) {
  const { isOnboarding } = props;

  const editDispositionMethodBtns = document.querySelectorAll(
    ".edit-disposition-method",
  );
  const businessAssociationInputGroups = document.querySelectorAll(
    ".business-association-input-group",
  );

  const funeralHomeInputGroup = document.querySelector(".funeral-home-input-group");
  const cemeteryInputGroup = document.querySelector(".cemetery-input-group");
  const donationOrgInputGroup = document.querySelector(".donation-org-input-group");
  const seaBurialProviderInputGroup = document.querySelector(
    ".sea-burial-provider-input-group",
  );
  const locationTypeInputGroup = document.querySelector(".location-type-input-group");
  const locationInputGroup = document.querySelector(".location-input-group");

  const locationTypeSelect = document.querySelector(
    "#id_business_association-remains_location_type",
  );
  const remainsLocationField = document.querySelector(
    "#id_business_association-remains_location",
  );

  const funeralHomeInputLabel = funeralHomeInputGroup.querySelector(".dark-gray-label");
  const funeralHomeInputPending = funeralHomeInputGroup.querySelector("label");
  const locationTypeNoRemains = locationTypeInputGroup.querySelector(
    `option[value="${props.remainsLocationTypeNoRemains}"]`,
  );

  /**
   * Set the text of the funeral home input group.
   *
   * @param {boolean} isFuneralHome - if true, we're specifically a Funeral Home
   * @param {boolean} isInitialLoad - if true, this is executing on the initial load of
   *     the page
   * @param {boolean} isGenericProvider - if true, set the label to copy about a
   *     "provider" rather than a "funeral home"
   */
  const setTextOfFuneralHomeGroup = (
    isFuneralHome = true,
    isInitialLoad = false,
    isGenericProvider = false,
  ) => {
    if (isInitialLoad) {
      funeralHomeInputLabel.innerText = "Funeral home or cremation provider";
      funeralHomeInputPending.innerText =
        "I have not yet chosen a funeral home or cremation provider";
    } else if (isFuneralHome) {
      funeralHomeInputLabel.innerText = "Funeral home";
      funeralHomeInputPending.innerText = "I have not yet chosen a funeral home";
    } else if (isGenericProvider) {
      funeralHomeInputLabel.innerText = "Provider";
      funeralHomeInputPending.innerText = "I have not yet chosen a provider";
    } else {
      funeralHomeInputLabel.innerText = "Cremation provider";
      funeralHomeInputPending.innerText = "I have not yet chosen a cremation provider";
    }
  };

  /**
   * Update disposition method form field visibilities.
   */
  const setDispositionMethodState = () => {
    const nonSelectedDispositionRadios = document.querySelectorAll(
      '[name="business_association-disposition_method"]:not(:checked)',
    );
    const selectedDispositionRadio = document.querySelector(
      '[name="business_association-disposition_method"]:checked',
    );

    if (selectedDispositionRadio) {
      const selectedDispositionRadioWrapper =
        selectedDispositionRadio.closest(".el-radio-btn");
      nonSelectedDispositionRadios.forEach((nonSelectedDispositionRadio) => {
        const nonSelectedDispositionRadioWrapper =
          nonSelectedDispositionRadio.closest(".el-radio-btn");
        nonSelectedDispositionRadioWrapper.classList.add("d-none");
      });

      selectedDispositionRadioWrapper.classList.remove("d-none");
      selectedDispositionRadioWrapper
        .querySelector(".edit-disposition-method")
        .classList.remove("d-none");
    } else {
      nonSelectedDispositionRadios.forEach((nonSelectedDispositionRadio) => {
        const nonSelectedDispositionRadioWrapper =
          nonSelectedDispositionRadio.closest(".el-radio-btn");
        nonSelectedDispositionRadioWrapper.classList.remove("d-none");
      });

      editDispositionMethodBtns.forEach((editDispositionMethodBtn) => {
        editDispositionMethodBtn.classList.add("d-none");
      });

      // if there's no disposition method, check if user has data for funeral home or
      // cemetery associations (the only field groups that existed before disposition
      // method was added), so we can show it to users who have that data
      updateBusinessAutocomplete(
        props.funeralHomeSearch,
        true,
        props.funeralHomeSearch.businessPlaceholder,
      );

      if (
        document.getElementById(props.funeralHomeSearch.businessIdentifierInputId)
          .value ||
        document.getElementById(props.funeralHomeSearch.businessStrInputId).value ||
        document.getElementById(props.funeralHomeSearch.businessPending).checked
      ) {
        toggleFuneralHome(funeralHomeInputGroup, isOnboarding, true);
      }

      updateBusinessAutocomplete(
        props.cemeterySearch,
        true,
        props.cemeterySearch.businessPlaceholder,
      );

      if (
        document.getElementById(props.cemeterySearch.businessIdentifierInputId).value ||
        document.getElementById(props.cemeterySearch.businessStrInputId).value ||
        document.getElementById(props.cemeterySearch.businessPending).checked
      ) {
        cemeteryInputGroup.classList.remove("d-none");
      }
    }
  };

  /**
   * Show the burial-disposition-relevant form fields.
   */
  const showBurialFields = () => {
    toggleFuneralHome(funeralHomeInputGroup, isOnboarding, true);
    cemeteryInputGroup.classList.remove("d-none");
    setTextOfFuneralHomeGroup();
  };

  /**
   * Show the cremation-disposition-relevant form fields.
   *
   * @param {boolean} isGenericProvider - if true, the funeral home input group is
   *     slightly different to "Provider" instead of "Funeral Home"
   */
  const showCremationFields = (isGenericProvider = false) => {
    toggleFuneralHome(funeralHomeInputGroup, isOnboarding, true);
    locationTypeInputGroup.classList.remove("d-none");
    setTextOfFuneralHomeGroup(false, false, isGenericProvider);
    locationTypeNoRemains.classList.add("d-none");
  };

  /**
   * Show the body-donation-disposition-relevant form fields.
   */
  const showDonationFields = () => {
    donationOrgInputGroup.classList.remove("d-none");
    locationTypeInputGroup.classList.remove("d-none");
    locationTypeNoRemains.classList.remove("d-none");
  };

  /**
   * Show the sea-burial-disposition-relevant form fields.
   */
  const showSeaBurialFields = () => {
    toggleFuneralHome(funeralHomeInputGroup, isOnboarding, true);
    seaBurialProviderInputGroup.classList.remove("d-none");
    locationInputGroup.classList.remove("d-none");
    setTextOfFuneralHomeGroup();
    locationInputGroup.querySelector(".dark-gray-label").innerText = "Ocean (Location)";
    document
      .getElementById("id_business_association-remains_location")
      .setAttribute("placeholder", "Ex: Pacific ocean");
  };

  /**
   * Show the form fields relevant to the "other" disposition method.
   */
  const showOtherFields = () => {
    toggleFuneralHome(funeralHomeInputGroup, isOnboarding, true);
    locationInputGroup.classList.remove("d-none");
  };

  // show the funeral home field on initial load & update label
  toggleFuneralHome(funeralHomeInputGroup, isOnboarding, true);
  setTextOfFuneralHomeGroup(null, true);

  // click handlers for disposition method radio buttons and edit buttons
  editDispositionMethodBtns.forEach((editDispositionMethodBtn) => {
    editDispositionMethodBtn.addEventListener("click", () => {
      document
        .querySelectorAll(
          '[name="business_association-disposition_method"]:not(:checked)',
        )
        .forEach((nonSelectedDispositionRadio) => {
          nonSelectedDispositionRadio
            .closest(".el-radio-btn")
            .classList.remove("d-none");
        });
      editDispositionMethodBtns.forEach((innerEditDispositionMethodBtn) => {
        innerEditDispositionMethodBtn.classList.add("d-none");
      });
    });
  });

  document
    .querySelectorAll('[name="business_association-disposition_method"]')
    .forEach((dispositionRadio) => {
      dispositionRadio.addEventListener("change", () => {
        if (dispositionRadio.checked) {
          clearAllBusinessAssociationInputs(props);
        }
      });
    });

  // show next fields depending on radio button selection, hide other fields and clear their inputs
  const burialRadio = document.getElementById(
    "id_business_association-disposition_method_0",
  );
  burialRadio.addEventListener("change", () => {
    if (burialRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (![funeralHomeInputGroup, cemeteryInputGroup].includes(group)) {
          group.classList.add("d-none");
        }
      });

      showBurialFields();
      updateBusinessAutocomplete(
        props.funeralHomeSearch,
        false,
        props.funeralHomeSearch.businessPlaceholder,
      );
      setDispositionMethodState();
    }
  });

  const cremationRadio = document.getElementById(
    "id_business_association-disposition_method_1",
  );
  cremationRadio.addEventListener("change", () => {
    if (cremationRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (![funeralHomeInputGroup, locationTypeInputGroup].includes(group)) {
          group.classList.add("d-none");
        }
      });

      showCremationFields();
      setDispositionMethodState();
    }
  });

  const donationRadio = document.getElementById(
    "id_business_association-disposition_method_2",
  );
  donationRadio.addEventListener("change", () => {
    if (donationRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (![donationOrgInputGroup, locationTypeInputGroup].includes(group)) {
          group.classList.add("d-none");
        }
      });

      showDonationFields();
      setDispositionMethodState();
    }
  });

  const seaBurialRadio = document.getElementById(
    "id_business_association-disposition_method_3",
  );
  seaBurialRadio.addEventListener("change", () => {
    if (seaBurialRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (
          ![
            funeralHomeInputGroup,
            seaBurialProviderInputGroup,
            locationTypeInputGroup,
          ].includes(group)
        ) {
          group.classList.add("d-none");
        }
      });

      showSeaBurialFields();
      updateBusinessAutocomplete(
        props.funeralHomeSearch,
        false,
        props.funeralHomeSearch.businessPlaceholder,
      );
      setDispositionMethodState();
    }
  });

  const naturalOrganicReductionRadio = document.getElementById(
    "id_business_association-disposition_method_4",
  );
  naturalOrganicReductionRadio.addEventListener("change", () => {
    if (naturalOrganicReductionRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (![funeralHomeInputGroup, locationTypeInputGroup].includes(group)) {
          group.classList.add("d-none");
        }
      });

      showCremationFields(true);
      setDispositionMethodState();
    }
  });

  const liquidCremationRadio = document.getElementById(
    "id_business_association-disposition_method_5",
  );
  liquidCremationRadio.addEventListener("change", () => {
    if (liquidCremationRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (![funeralHomeInputGroup, locationTypeInputGroup].includes(group)) {
          group.classList.add("d-none");
        }
      });

      showCremationFields();
      setDispositionMethodState();
    }
  });

  const otherRadio = document.getElementById(
    "id_business_association-disposition_method_6",
  );
  otherRadio.addEventListener("change", () => {
    if (otherRadio.checked) {
      if (isOnboarding) {
        businessAssociationInputGroups.forEach((group) => {
          if (![funeralHomeInputGroup, locationTypeInputGroup].includes(group)) {
            group.classList.add("d-none");
          }
        });
      } else {
        businessAssociationInputGroups.forEach((group) => {
          if (group !== locationInputGroup) {
            group.classList.add("d-none");
          }
        });
      }

      showOtherFields();
      setDispositionMethodState();
      setTextOfFuneralHomeGroup();
    }
  });

  const pendingRadio = document.getElementById(
    "id_business_association-disposition_method_7",
  );
  pendingRadio.addEventListener("change", () => {
    if (pendingRadio.checked) {
      businessAssociationInputGroups.forEach((group) => {
        if (group !== funeralHomeInputGroup) {
          group.classList.add("d-none");
        }
      });

      setDispositionMethodState();
    }
  });

  /**
   * Clear the remains-location-relevant inputs.
   */
  const clearLocationInputs = () => {
    remainsLocationField.removeAttribute("disabled");
    remainsLocationField.classList.remove("disabled");
    remainsLocationField.value = "";

    const locationMetadataInput = document.getElementById(
      "id_business_association-remains_location_metadata",
    );
    locationMetadataInput.value = "{}";

    const locationInputGroupLabel = document.querySelector(
      ".location-input-group .dark-gray-label",
    );
    locationInputGroupLabel.innerText = "Location";

    remainsLocationField.setAttribute(
      "placeholder",
      "Ex: 123 Green street, San Francisco, CA",
    );

    const pendingCheckbox = document.getElementById(
      "id_business_association-remains_location_pending",
    );
    pendingCheckbox.checked = false;
  };

  /**
   * Clear the cemetery-search-related inputs.
   */
  const clearCemeteryInputs = () => {
    const cemeterySearchInput = document.getElementById(
      props.cemeterySearch.businessIdentifierInputId,
    );

    if (cemeterySearchInput.tomselect) {
      cemeterySearchInput.tomselect.clear();
      cemeterySearchInput.tomselect.clearOptions();
      cemeterySearchInput.tomselect.enable();
    }

    document.getElementById(props.cemeterySearch.businessPending).checked = false;
  };

  /**
   * Show next field depending on location type dropdown selection
   *
   * @param {boolean} showInitialValue - if true, show the initial value from props.
   */
  const showLocationTypeFollowupField = (showInitialValue) => {
    if (locationTypeSelect.value === props.remainsLocationTypeCemetery.toString()) {
      cemeteryInputGroup.classList.remove("d-none");
      locationInputGroup.classList.add("d-none");

      if (showInitialValue) {
        updateBusinessAutocomplete(
          props.cemeterySearch,
          true,
          props.cemeterySearch.businessPlaceholder,
        );
      } else {
        updateBusinessAutocomplete(
          props.cemeterySearch,
          false,
          props.cemeterySearch.businessPlaceholder,
        );
      }
    } else if (
      locationTypeSelect.value === props.remainsLocationTypeNoRemains.toString() ||
      !locationTypeSelect.value
    ) {
      locationInputGroup.classList.add("d-none");
      cemeteryInputGroup.classList.add("d-none");
    } else if (locationTypeSelect.value) {
      locationInputGroup.classList.remove("d-none");
      cemeteryInputGroup.classList.add("d-none");
    }
  };

  locationTypeSelect.addEventListener("change", () => {
    clearLocationInputs();
    clearCemeteryInputs();
    showLocationTypeFollowupField(false);
  });

  const remainsLocationTypeCheckbox = document.getElementById(
    "id_business_association-remains_location_type_pending",
  );
  const remainsLocationCheckbox = document.getElementById(
    "id_business_association-remains_location_pending",
  );

  /**
   * Update the remains location form fields' visibility based on other form state.
   */
  const setRemainsLocationTypeState = () => {
    const remainsLocationTypeField = document.getElementById(
      "id_business_association-remains_location_type",
    );

    if (remainsLocationTypeCheckbox.checked) {
      remainsLocationTypeField.setAttribute("disabled", "disabled");
      remainsLocationTypeField.classList.add("disabled");
      locationTypeSelect.value = "";
    } else {
      remainsLocationTypeField.removeAttribute("disabled");
      remainsLocationTypeField.classList.remove("disabled");
      showLocationTypeFollowupField(true);
    }
  };

  /**
   * Update the remains location state based on the checkbox state.
   */
  const setRemainsLocationState = () => {
    if (remainsLocationCheckbox.checked) {
      remainsLocationField.setAttribute("disabled", "disabled");
      remainsLocationField.classList.add("disabled");
      remainsLocationField.value = "";
    } else {
      remainsLocationField.removeAttribute("disabled");
      remainsLocationField.classList.remove("disabled");
    }
  };

  remainsLocationTypeCheckbox.addEventListener("click", () => {
    setRemainsLocationTypeState();
    clearLocationInputs();
    clearCemeteryInputs();
    showLocationTypeFollowupField(false);
  });

  remainsLocationCheckbox.addEventListener("click", () => {
    setRemainsLocationState();
  });

  // initial state for display
  setDispositionMethodState();

  // Set addSubmitListener to true here as we only want to add the submit event listener
  // during initialization.
  updateBusinessAutocomplete(
    props.funeralHomeSearch,
    true,
    props.funeralHomeSearch.businessPlaceholder,
    true,
  );
  updateBusinessAutocomplete(
    props.cemeterySearch,
    true,
    props.cemeterySearch.businessPlaceholder,
    true,
  );
  updateBusinessAutocomplete(
    props.hospiceSearch,
    true,
    props.hospiceSearch.businessPlaceholder,
    true,
  );
  updateBusinessAutocomplete(
    props.donationOrgSearch,
    true,
    props.donationOrgSearch.businessPlaceholder,
    true,
  );
  updateBusinessAutocomplete(
    props.deathDoulaSearch,
    true,
    props.deathDoulaSearch.businessPlaceholder,
    true,
  );
  updateBusinessAutocomplete(
    props.seaBurialProviderSearch,
    true,
    props.seaBurialProviderSearch.businessPlaceholder,
    true,
  );

  setRemainsLocationState();

  const selectedDispositionRadio = document.querySelector(
    '[name="business_association-disposition_method"]:checked',
  );

  if (selectedDispositionRadio === burialRadio) {
    showBurialFields();
  } else if (selectedDispositionRadio === cremationRadio) {
    showCremationFields();
    setRemainsLocationTypeState();
  } else if (selectedDispositionRadio === donationRadio) {
    showDonationFields();
    setRemainsLocationTypeState();
  } else if (selectedDispositionRadio === seaBurialRadio) {
    showSeaBurialFields();
  } else if (selectedDispositionRadio === naturalOrganicReductionRadio) {
    showCremationFields();
    setRemainsLocationTypeState();
  } else if (selectedDispositionRadio === liquidCremationRadio) {
    showCremationFields();
    setRemainsLocationTypeState();
  } else if (selectedDispositionRadio === otherRadio) {
    showOtherFields();
  }

  if (!props.disableReviews) {
    initBusinessReviewSection(props);
  }
}

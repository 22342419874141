export function initAttendeeFormset(eventUUID, formsetProps) {
  if (formsetProps) {
    const totalFormsInput = document.getElementById(
      formsetProps.managementForm.fields.TOTALFORMS,
    );
    const numberOfAttendeesInput = document.getElementById(
      formsetProps.numberOfAttendeesInput,
    );

    if (!numberOfAttendeesInput) {
      return;
    }

    const attendeesListGroup = numberOfAttendeesInput
      .closest(".add-rsvp-form,.rsvp-form")
      .querySelector(".rsvp-attendees");
    let { totalFormCount } = formsetProps;
    const { emptyForm } = formsetProps;

    const removeForm = (specificForm = null) => {
      let child;

      if (!specificForm) {
        child = [
          ...attendeesListGroup.querySelectorAll(
            ".rsvp-attendee-formset-form:not(.hidden)",
          ),
        ].pop();
      } else {
        child = specificForm;
      }

      if (child) {
        const deleteField = child.querySelector('input[type="hidden"]');
        deleteField.value = "1";

        child.classList.add("hidden");

        const visibleFormCount = attendeesListGroup.querySelectorAll(
          ".rsvp-attendee-formset-form:not(.hidden)",
        ).length;
        if (visibleFormCount === 0) {
          attendeesListGroup.classList.add("d-none");
        }
      }
    };

    const addForm = () => {
      const formWrapper = document.createElement("div");
      formWrapper.innerHTML = emptyForm.replace(/__prefix__/g, totalFormCount);
      const newForm = formWrapper.querySelector(".rsvp-attendee-formset-form");

      const removeButton = newForm.querySelector(".remove-attendee");

      if (removeButton) {
        removeButton.addEventListener("click", () => {
          numberOfAttendeesInput.value = parseInt(numberOfAttendeesInput.value, 10) - 1;
          removeForm(newForm);
        });
      }

      attendeesListGroup.appendChild(newForm);
      totalFormCount += 1;
      totalFormsInput.value = totalFormCount;

      attendeesListGroup.classList.remove("d-none");
    };

    attendeesListGroup.querySelectorAll(".remove-attendee").forEach((removeButton) => {
      removeButton.addEventListener("click", () => {
        numberOfAttendeesInput.value = parseInt(numberOfAttendeesInput.value, 10) - 1;
        removeForm(removeButton.closest(".rsvp-attendee-formset-form"));
      });
    });

    numberOfAttendeesInput.addEventListener("change", () => {
      const desiredNumberOfForms = parseInt(numberOfAttendeesInput.value, 10) - 1;
      const visibleFormCount = attendeesListGroup.querySelectorAll(
        ".rsvp-attendee-formset-form:not(.hidden)",
      ).length;

      if (desiredNumberOfForms > visibleFormCount) {
        for (let i = 0; i < desiredNumberOfForms - visibleFormCount; i++) {
          addForm();
        }
      } else if (desiredNumberOfForms < visibleFormCount) {
        for (let i = 0; i < visibleFormCount - desiredNumberOfForms; i++) {
          // No adjustment of numberOfAttendeesInput here (it has already changed by
          // user interaction)
          removeForm();
        }
      }
    });
  }
}

import initBusinessAssociationForm from "../../components/BusinessAssociationForm";
import initCauseOfDeathAutocomplete from "../../components/CauseOfDeathAutocomplete";
import initGoogleAutocomplete from "../../components/GoogleAutocomplete";

export default function initMemorialOnboardingFinalRestingPlace(props) {
  initBusinessAssociationForm(props);
  initCauseOfDeathAutocomplete(props.causeOfDeathSearch);

  initGoogleAutocomplete({
    element: document.getElementById("id_business_association-remains_location"),
    metadataElement: document.getElementById(
      "id_business_association-remains_location_metadata",
    ),
    options: {
      types: ["establishment", "geocode"],
    },
  });
}

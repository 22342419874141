import Modal from "bootstrap/js/dist/modal";
import { sendBrowserAgnosticEvent } from "../core/utils";

export default class StockPhotoModal {
  constructor(root, onSelected) {
    this.root = root;
    this.rootModal = Modal.getOrCreateInstance(root);

    this.selectedStockPhotos = [];
    this.desiredCount = null;

    this.confirmSelection = this.root.querySelector(".confirm-selection");
    this.cancelSelection = this.root.querySelector(".cancel-selection");

    document.body.addEventListener("htmx:afterSettle", (evt) => {
      if (evt.detail.elt.classList.contains("stock-photo-browser")) {
        evt.detail.elt.querySelectorAll(".stock-photo").forEach((photo) => {
          photo.addEventListener("click", () => {
            this.togglePhoto(photo);
          });
        });

        this.updateSelected();
      }
    });

    this.confirmSelection.addEventListener("click", () => {
      onSelected(this.selectedStockPhotos);
      this.rootModal.hide();
    });

    this.cancelSelection.addEventListener("click", () => {
      onSelected([]);
      this.rootModal.hide();
    });
  }

  show(alreadySelectedStockPhotos = null, desiredCount = null) {
    if (desiredCount) {
      this.desiredCount = alreadySelectedStockPhotos
        ? alreadySelectedStockPhotos.length + desiredCount
        : null;
    } else {
      this.desiredCount = null;
    }

    this.selectedStockPhotos = alreadySelectedStockPhotos || [];

    const browserContainer = this.root.querySelector(".stock-photo-browser");
    if (browserContainer) {
      sendBrowserAgnosticEvent(browserContainer, "loadStockPhotos");
    }

    this.rootModal.show();
  }

  togglePhoto(photoElement) {
    const { hashid, thumbnailUrl } = photoElement.dataset;

    const filteredPhotos = this.selectedStockPhotos.filter(
      (sp) => sp.hashid !== hashid,
    );

    if (filteredPhotos.length === this.selectedStockPhotos.length) {
      this.selectedStockPhotos.push({ hashid, thumbnailUrl });
    } else {
      this.selectedStockPhotos = filteredPhotos;
    }

    this.updateSelected();
  }

  updateSelected() {
    const selectedPhotosContainer = this.root.querySelector(".selected-photos");

    if (selectedPhotosContainer) {
      // Clear.
      while (selectedPhotosContainer.firstChild) {
        selectedPhotosContainer.removeChild(selectedPhotosContainer.lastChild);
      }

      selectedPhotosContainer.append(
        ...this.selectedStockPhotos.map((photoData) => {
          const photo = document.createElement("img");
          photo.setAttribute("src", photoData.thumbnailUrl);

          const div = document.createElement("div");
          div.classList.add("ratio", "ratio-4x3");
          div.dataset.hashid = photoData.hashid;
          div.dataset.thumbnailUrl = photoData.thumbnailUrl;
          div.append(photo);

          const remove = document.createElement("a");
          remove.setAttribute("href", "javascript:;");
          remove.innerText = "-";
          div.append(remove);

          const container = document.createElement("div");
          container.append(div);

          return container;
        }),
      );

      const selectedHashids = this.selectedStockPhotos.map(
        (photoData) => photoData.hashid,
      );

      this.root.querySelectorAll(".stock-photo").forEach((stockPhoto) => {
        stockPhoto.classList.toggle(
          "selected",
          selectedHashids.includes(stockPhoto.dataset.hashid),
        );
      });

      const browserContainer = this.root.querySelector(".stock-photo-browser");
      browserContainer.classList.remove("full-selection");

      if (this.selectedStockPhotos.length > 0) {
        this.confirmSelection.innerText = "Confirm Selection";
        this.confirmSelection.removeAttribute("disabled");
        this.confirmSelection.classList.remove("disabled");
      } else {
        this.confirmSelection.innerText = "Select Photos";
        this.confirmSelection.setAttribute("disabled", "disabled");
        this.confirmSelection.classList.add("disabled");
      }
    }

    if (this.desiredCount) {
      const desiredCountLabel = this.root.querySelector(".desired-count-label");
      if (desiredCountLabel) {
        const remaining = this.desiredCount - this.selectedStockPhotos.length;
        desiredCountLabel.classList.toggle("d-none", !remaining);
        if (remaining > 0) {
          desiredCountLabel.innerText = `You can select ${remaining} more stock photo${remaining === 1 ? "" : "s"}.`;
        } else {
          desiredCountLabel.innerText = `You have selected ${Math.abs(remaining)} too many stock photos.`;
        }
      }
    }
  }
}

import ClipboardJS from "clipboard";

import config from "../../core/config";
import requests, { ResponseError } from "../../core/requests";

const updateSharingChecklist = async (props) => {
  try {
    const response = await requests.post(props.updateSharingChecklistEndpoint, {
      "sharing-checklist-item": "share_to_facebook",
    });
    if (!response.ok) {
      throw new ResponseError(response);
    }
  } catch (error) {
    window.Rollbar.error(
      "Error updating the sharing checklist from onboarding",
      error.statusText,
    );
  }
};

export default function initMemorialOnboardingShare(props) {
  const clipboard = new ClipboardJS(".btn-copy");
  clipboard.on("success", (event) => {
    const button = event.trigger;
    button.innerText = "Copied!";
  });

  // NOTE: this is the callback function for when the FaceBook SDK loads
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: config.facebookAppId,
      autoLogAppEvents: true,
      xfbml: true,
      version: config.facebookApiVersion,
    });
    document.querySelectorAll(".btn-facebook-share").forEach((btnFacebookShare) => {
      btnFacebookShare.addEventListener("click", () => {
        if (config.debug) {
          console.log(
            `Would share the following link to Facebook: ${props.facebookShare.href}`,
          );
        }
        window.FB.ui(props.facebookShare, (facebookResponse) => {
          if (
            facebookResponse &&
            !facebookResponse.error_code &&
            typeof facebookResponse !== "undefined"
          ) {
            if (
              props.memorialHasPersonalFundraiser &&
              !props.shareToFacebookChecklistStatus
            ) {
              updateSharingChecklist(props);
              window.location.href = props.shareComplete;
            } else {
              window.location.href = props.shareComplete;
            }
          }
        });
      });
    });
  };
}

import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_remove_button from "tom-select/dist/js/plugins/remove_button";

TomSelect.define("remove_button", TomSelect_remove_button);

/**
 * Initialize the organ donation search form.
 *
 * @param {object} organDonationSearchProps - the props for the organ donation
 *     tomselect search widget.
 * @returns {TomSelect} - the TomSelect instance created on the form input.
 */
export default function initOrganDonationForm(organDonationSearchProps) {
  const { checkboxId, initialAutocompleteData, inputId } = organDonationSearchProps;
  const hasDonatedOrgans = document.querySelector(`#${checkboxId}`);
  const organChoicesInput = document.querySelector(`#${inputId}`);
  const organDonationDetailsContainer = organChoicesInput.closest(
    ".donated-organs-details-container",
  );

  const tomSelectConfig = {
    hidePlaceholder: true,
    sortField: [{ field: "$order" }, { field: "$score" }],
    items: initialAutocompleteData.choices
      .filter((choice) => choice.selected)
      .map((choice) => choice.id),
    options: initialAutocompleteData.choices,
    placeholder: "Ex. Heart",
    plugins: {
      remove_button: {
        title: "Remove this item",
      },
    },
    valueField: "id",
  };

  const tomSelect = new TomSelect(organChoicesInput, tomSelectConfig);

  function toggleShowOrganDonationDetails() {
    if (hasDonatedOrgans.checked) {
      organDonationDetailsContainer.classList.remove("d-none");
    } else {
      organDonationDetailsContainer.classList.add("d-none");
    }
  }

  if (hasDonatedOrgans && organDonationDetailsContainer) {
    hasDonatedOrgans.addEventListener("change", () => {
      toggleShowOrganDonationDetails();
    });
  }

  // determine if donated organs search box should be shown on initial load
  toggleShowOrganDonationDetails();

  return tomSelect;
}

import Modal from "bootstrap/js/dist/modal";

import { sendBrowserAgnosticEvent } from "../core/utils";

const EMPTY_VALUES = [undefined, null, "", " ", "<p></p>", "<p><br></p>"];

export default function initUGCTemplates(
  container,
  input,
  templatesData,
  shouldShowTemplatesOnInit = null,
  skipWarningModal = false,
  onTemplateSelected = null,
  inputIsHTMLEditor = true,
  alwaysShowTemplates = false,
) {
  // UGC template selection
  if (container && input) {
    const form = input.closest("form");

    // Add "has-warning-modal" class to the main submit button of any form that is using
    // initUGCTemplates! This button is clicked in JS to submit form if a user decides
    // to ignore the warning that they are submitting an unedited template.
    const submitBtnWithWarning = form.querySelector(".has-warning-modal");
    const templateSelector = container.querySelector("select");
    const templateDefaultOption = container.querySelector('option[value=""]');

    // optional warning modal elements
    const editModalButton = document.querySelector(".unedited-template-modal-close");
    const saveModalButton = document.querySelector(".unedited-template-modal-save");
    const warningModal = document.querySelector(".modal-unedited-template");
    let showWarningModalOnSubmit = false;

    if (
      (EMPTY_VALUES.includes(input.value) &&
        (!shouldShowTemplatesOnInit || shouldShowTemplatesOnInit())) ||
      alwaysShowTemplates
    ) {
      // Show template selector on init if we should.
      container.classList.remove("d-none");
    }

    input.addEventListener("input", () => {
      if (parseInt(input.dataset.selectedTemplate, 10) === 1) {
        // Template selected & we fired an "input" event so passwordless signup form can show.
        // We reset selectedTemplate so can process the next "input" event.
        input.dataset.selectedTemplate = 0;
      } else if (EMPTY_VALUES.includes(input.value)) {
        // input is empty - show template selector.
        showWarningModalOnSubmit = false;
        container.classList.remove("d-none");
        templateDefaultOption.selected = true;
      } else {
        // Wrap template in <p> (if HTML mode) and remove all whitespace to compare.
        const templateBody = templatesData[templateSelector.value] || "";

        let templateIsEdited;
        if (inputIsHTMLEditor) {
          const templateBodyInP = `<p>${templateBody.replace("\n", "</p><p>")}</p>`;
          templateIsEdited = !(
            input.value.replace(/\s/g, "") === templateBodyInP.replace(/\s/g, "")
          );
        } else {
          templateIsEdited = !(
            input.value.replace(/\s/g, "") === templateBody.replace(/\s/g, "")
          );
        }

        if (templateIsEdited) {
          // input is not empty & has been edited from original template - hide template selector.
          if (!alwaysShowTemplates) {
            container.classList.add("d-none");
          }
          showWarningModalOnSubmit = false;
        }
      }
    });

    let previousSelection = templateSelector.value;

    templateSelector.addEventListener("change", () => {
      const prevValue = input.value;

      if (templateSelector.value) {
        // changed template selector to a value, insert template into input.
        const templateBody = templatesData[templateSelector.value];
        if (inputIsHTMLEditor) {
          input.value = `<p>${templateBody.replace("\n", "</p><p>")}</p>`;
        } else {
          input.value = templateBody;
        }

        input.dataset.selectedTemplate = 1;
        showWarningModalOnSubmit = true;
      } else {
        // changed template selector to no value, clear input.
        input.value = "";
        input.dataset.selectedTemplate = 0;
        showWarningModalOnSubmit = false;
      }

      // Capture previous to a diff var so we don't have a race.
      const previous = previousSelection;
      if (onTemplateSelected) {
        onTemplateSelected(input.value, () => {
          input.value = prevValue;
          templateSelector.value = previous;
          sendBrowserAgnosticEvent(input, "input");
        });
      }

      sendBrowserAgnosticEvent(input, "input");
      previousSelection = templateSelector.value;
    });

    if (saveModalButton) {
      saveModalButton.addEventListener("click", () => {
        Modal.getOrCreateInstance(warningModal).hide();
        saveModalButton.classList.add("disabled");

        // clicking submitBtn in JS to allow reCAPTCHA.js to run checks
        sendBrowserAgnosticEvent(form, "cancel-submit-protect");
        submitBtnWithWarning.dataset.acknowledgedWarning = true;
        submitBtnWithWarning.click();
      });
    }

    if (warningModal && editModalButton) {
      editModalButton.addEventListener("click", () => {
        Modal.getOrCreateInstance(warningModal).hide();
        sendBrowserAgnosticEvent(form, "cancel-submit-protect");
      });
    }
    form.addEventListener("submit", (event) => {
      if (submitBtnWithWarning) {
        const hasSeenWarning = submitBtnWithWarning.dataset.acknowledgedWarning;
        if (
          !skipWarningModal &&
          warningModal &&
          showWarningModalOnSubmit &&
          !hasSeenWarning
        ) {
          event.preventDefault();
          event.stopImmediatePropagation();
          Modal.getOrCreateInstance(warningModal).show();
        }
      }
    });
  }
}

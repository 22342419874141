import initGoogleAutocomplete from "../../components/GoogleAutocomplete";
import initExpandableCollapses from "../../components/ExpandableCollapse";
import {
  initAnimatedPlaceholderFormField,
  registerSubmitButton,
} from "../../core/utils";

export default function initMemorialPrivateHowToHelp(props) {
  let { totalOtherFormCount } = props;

  const initNeedForm = (needFormContainer) => {
    const checkbox = needFormContainer.querySelector(
      '.need-title input[type="checkbox"]',
    );
    const needTypeOtherInput = checkbox
      .closest(".need-title")
      .querySelector(".need-type-other-input");

    const activate = () => {
      const detail = checkbox
        .closest(".need-form-container")
        .querySelector(".need-details");
      if (detail) {
        detail.classList.toggle("show", checkbox.checked);
      }

      const needTypeLabel = checkbox
        .closest(".need-title")
        .querySelector(".need-title-label");
      const deleteInput = checkbox
        .closest(".need-title")
        .querySelector(`#${checkbox.id.replace("need_enabled", "DELETE")}`);
      const idInput = checkbox
        .closest(".need-form-container")
        .querySelector(`#${checkbox.id.replace("need_enabled", "id")}`);
      if (needTypeLabel && needTypeOtherInput && deleteInput) {
        needTypeOtherInput.classList.toggle("d-none", !checkbox.checked);
        needTypeLabel.classList.toggle("d-none", checkbox.checked);

        if (checkbox.checked) {
          needTypeOtherInput.focus();
        }

        if (idInput && idInput.value) {
          deleteInput.value = !checkbox.checked;
        } else if (!checkbox.checked) {
          totalOtherFormCount -= 1;
          checkbox.closest(".need-form-container").remove();
          document.querySelector("#id_need-other-TOTAL_FORMS").value =
            totalOtherFormCount;
        }
      }

      const titleSubmitButton = checkbox
        .closest(".need-title")
        .querySelector('button[type="submit"]');
      if (titleSubmitButton) {
        titleSubmitButton.classList.add("d-md-block");

        if (idInput && idInput.value && !checkbox.checked) {
          titleSubmitButton.innerText = "Remove";
        }
      }
    };

    checkbox.addEventListener("change", activate);

    if (needTypeOtherInput) {
      needTypeOtherInput.addEventListener("input", activate);
    }

    const editNeedDetailsButtons =
      needFormContainer.querySelectorAll(".edit-need-details");
    editNeedDetailsButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const readonlyContainer = needFormContainer.querySelector(
          ".need-details-readonly",
        );
        const editableContainer = needFormContainer.querySelector(
          ".need-details-editable",
        );

        readonlyContainer.classList.add("d-none");
        editableContainer.classList.remove("d-none");
      });
    });

    const locationField = needFormContainer.querySelector(".need-location-field");
    const locationMetadataField = needFormContainer.querySelector(
      ".need-location-metadata-field",
    );

    if (locationField && locationMetadataField) {
      initGoogleAutocomplete({
        element: locationField,
        metadataElement: locationMetadataField,
        options: {
          types: ["establishment", "geocode"],
        },
      });
    }
  };

  document.querySelectorAll(".need-form-container").forEach(initNeedForm);

  const otherNeedFormsetContainer = document.querySelector(".other-need-formset");
  const addOtherButton = document.querySelector(".add-other-need");
  if (otherNeedFormsetContainer && addOtherButton) {
    addOtherButton.addEventListener("click", () => {
      totalOtherFormCount += 1;
      const formSource = props.emptyOtherForm.replace(
        /__prefix__/g,
        totalOtherFormCount - 1,
      );
      const wrapper = document.createElement("div");
      wrapper.innerHTML = formSource;
      const form = wrapper.firstElementChild;
      otherNeedFormsetContainer.appendChild(form);

      initNeedForm(form);

      form.querySelectorAll('[type="submit"]').forEach(registerSubmitButton);
      form.querySelector('input[type="checkbox"]').click();
      form
        .querySelectorAll(".el-animated-placeholder-label-input input")
        .forEach(initAnimatedPlaceholderFormField);

      document.querySelector("#id_need-other-TOTAL_FORMS").value = totalOtherFormCount;
    });
  }

  initExpandableCollapses();
}
